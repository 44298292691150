import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, NavLink, Modal } from "react-bootstrap";
import axios from "axios";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, Autocomplete, useLoadScript } from '@react-google-maps/api';
import { useNavigate, Link, Await } from "react-router-dom";
import Footer from '../../components/footer/Footer';
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategory } from '../../redux/slice/category';
import toast from "react-hot-toast";
// import Slider, { SliderTooltip } from 'rc-slider';
// import 'rc-slider/assets/index.css';
import RegistrantCard from "../../components/registrantDashboard/RegistrantCard";
import { fetchRegistrants } from "../../redux/slice/registrantSlice";
import RegistrantMap from "../../components/registrantDashboard/RegistrantMap";
import CustomPagination from "../../components/pagination/CustomPagination";
import RegistrantShow from "../../components/globalDashboard/RegistrantShow";
import { clearAddress } from "../../redux/slice/registrantNearmeLocation";
import 'rc-slider/assets/index.css';
import Slider, { SliderTooltip } from 'rc-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useCallback } from "react";

const RegistrantSearch = ({ setRegistrantPage, registrantPage, search, setSearch }) => {

    let localUserId;
    let token;
    let userName;
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState(window.history.state?.searchTerm || search || '');
    const [noticeDatas, setNoticeData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [provinceData, setProvinceData] = useState(window.history.state?.provinceData || []);
    const [notificationName, setNotificationName] = useState('');
    const [notificationDate, setNotificationDate] = useState('');
    const [notificationmessage, setNotificationmessage] = useState('');
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const [registrantData, setRegistrantData] = useState(window.history.state?.registrantData || []);
    const [cityData, setCityData] = useState(window.history.state?.cityData || []);
    const [categoriesData, setCategoriesData] = useState(window.history.state?.categoriesData || []);
    const [province, setProvince] = useState(window.history.state?.province || '');
    const [category, setCategory] = useState(window.history.state?.category || '');
    const [registrant, setRegistrant] = useState(window.history.state?.registrant || '');
    const [city, setCity] = useState(window.history.state?.city || '');
    const [registrantBookmarkData, setRegistrantBookmarkData] = useState([]);
    const [disable, setDisable] = useState(true);
    const [disableSecond, setDisableSecond] = useState(true);
    const dispatch = useDispatch();
    const [gotopag, setGotopag] = useState(false);
    const render = useRef(0);
    const data = useSelector((state) => state?.registrants);
    const [disableDownloadCsv, setDisableDownloadCsv] = useState(false);

    useEffect(() => {
        // Retrieve state from history on page load
        const state = window.history.state;
        if (state) {
            setNewCategory(state.newCategory || '');
            setFormatedAddress(state.formatedAddress || '');
        }
    }, []);

    const [selectedAddress, setSelectedAddress] = useState(window.history.state?.selectedAddress || "Province");
    const dropdownOptions = ["Province", "Head Office", "Near Location"];

    const [formatedAddress, setFormatedAddress] = useState(window.history.state?.formatedAddress || '')


    const [sliderValue, setSliderValue] = useState(window.history.state?.sliderValue || 0);

    const handleChange = (value) => {

        setSliderValue(value);
        window.history.replaceState(
            { ...window.history.state, sliderValue: value },
            ''
        );
        setRegistrantPage(1)
    };

    const center = {
        lat: null,
        lng: null,
    };
    // new Filter
    // const [city,setCity]=useState('');
    // const [province,setProvince]=useState('');
    const [country, setCountry] = useState('');
    const [marker, setMarker] = useState(center);
    const [latLng, setLatLng] = useState(window.history.state?.latLng || center);
    const [address, setAddress] = useState({
        location: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
    });
    const mapRef = useRef(null);

    const [newCategory, setNewCategory] = useState(window.history.state?.newCategory || '');
    const dropdownRef = useRef(null);

    const [selectedOptionFilter, setSelectedOptionFilter] = useState(window.history.state?.selectedOptionFilter || 1);

    const handleOptionChange = (event) => {
        setSelectedOptionFilter(event.target.value);
    };


    const handleAddressVal = (val) => {
        setSelectedAddress(val);
        if (val !== 'Near Location') {
            setSliderValue(0)
        } else {
            setSliderValue(50)
        }
        setRegistrantPage(1)

    }

    const onMapClick = useCallback((event) => {
        const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };
        setMarker(newLatLng);
        setLatLng(newLatLng);
        // geocodeLatLng(newLatLng.lat, newLatLng.lng);
    }, []);

    // const geocodeLatLng = (lat, lng) => {
    //     const geocoder = new window.google.maps.Geocoder();
    //     const latlng = { lat, lng };

    //     geocoder.geocode({ location: latlng }, (results, status) => {
    //         if (status === 'OK') {

    //             if (results[0]) {
    //                 const addressComponents = results[0].address_components;
    //                 const location = results[0].formatted_address;
    //                 let city = '';
    //                 let state = '';
    //                 let country = '';
    //                 let postalCode = '';

    //                 for (let component of addressComponents) {
    //                     const types = component.types;
    //                     if (types.includes('locality')) {
    //                         city = component.long_name;
    //                     }
    //                     if (types.includes('administrative_area_level_1')) {
    //                         state = component.long_name;
    //                     }
    //                     if (types.includes('country')) {
    //                         country = component.long_name;
    //                     }
    //                     if (types.includes('postal_code')) {
    //                         postalCode = component.long_name;
    //                     }
    //                 }

    //                 setAddress({ location, city, state, country, postalCode });
    //             } else {
    //                 window.alert('No results found');
    //             }
    //         } else {
    //             window.alert('Geocoder failed due to: ' + status);
    //         }
    //     });

    // };

    const handleCategory = (data) => {
        setNewCategory(data)

        if (dropdownRef.current) {
            const dropdown = new window.bootstrap.Dropdown(dropdownRef.current);
            dropdown.hide();
        }

        window.history.replaceState({ ...window.history.state, newCategory: category }, '');
    }

    const marks = {
        50: '50',
        100: '100',
        150: '150',
        200: '200',
        250: '250',
        300: '300',
    };

    // new Filter



    // const [map, setMap] = useState(null);
    const [goToPage, setGoTOPage] = useState(registrantPage);
    const logindata = JSON.parse(window.localStorage.getItem("login"));
    const capitalizeFirstLetter = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
    if (logindata?.user_data?._id) { localUserId = logindata?.user_data?._id; }
    if (logindata) { token = logindata.token; }
    if (logindata?.user_data?.name) { userName = logindata?.user_data?.name; }
    const [onfocas, placeholderDataHide] = useState(false);
    // const handleInputChange = (e) => {
    //     setSearchTerm(e.target.value);
    // };
    useEffect(() => {
        document.title = "PCMA || Registered Individuals Dashboard";
    }, []);


    dispatch(clearAddress());
    useEffect(() => {
        console.log('1');
        if (render.current !== 0) {
            const debounceTimer = setTimeout(() => {
                if (registrantPage === 1) {
                    searchApi();
                } else if (searchTerm.length > 1) {
                    setRegistrantPage(1);
                    setGoTOPage(1);
                }
            }, 1500);
            return () => clearTimeout(debounceTimer);
        } else {
            render.current++;
        }
    }, [searchTerm, address?.country, address?.city, address?.state, address?.postalCode, newCategory, sliderValue, selectedOptionFilter]);



    useEffect(() => {

        if (formatedAddress?.length > 0 || selectedOptionFilter == 0 || newCategory?.length > 0) {
            if (selectedAddress == 'Near Location' && sliderValue !== 50) {
                setDisableSecond(false)
                // setDisable(false);

            } else if (selectedAddress !== 'Near Location' && sliderValue !== 0) {
                setDisableSecond(false)
                // setDisable(false);
            } else {
                setDisableSecond(false)
                // setDisable(false);
            }
            // setDisableSecond(false)
            // setDisable(false);
        } else {
            // setDisable(true);
            setDisableSecond(true)
        }
        if (searchTerm?.length > 0) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [formatedAddress, selectedOptionFilter, newCategory, sliderValue, searchTerm])

    const { lat, lng } = latLng || {};

    const searchApi = () => {
        dispatch(fetchRegistrants({
            "page": registrantPage,
            "province": registrant?.label,
            "category": newCategory,
            "country": province?.label,
            "city": city?.label,
            "is_only_category": selectedOptionFilter,
            "is_address": selectedAddress === 'Head Office' ? 1 : 0,
            "country": address?.country,
            "province": address?.state,
            "city": address?.city,
            "location": address?.location,
            "latitude": lat || null,
            "longitude":lng || null,
            "radius": sliderValue,
            // "registrant": registrant?.value,
            "search": searchTerm,
            "token": token,

        }));

    };


    const handlePageChange = (page) => {
        setRegistrantPage(page);
        setGoTOPage(page);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
       
            dispatch(fetchRegistrants({
                "page": registrantPage,
                "province": registrant?.label,
                "category": newCategory,
                "country": province?.label,
                "city": city?.label,
                "is_only_category": selectedOptionFilter,
                "is_address": selectedAddress === 'Head Office' ? 1 : 0,
                "country": address?.country,
                "province": address?.state,
                "city": address?.city,
                "location": address?.location,
               "latitude": lat || null,
               "longitude":lng || null,
                "radius": sliderValue,
                // "registrant": registrant?.value,
                "search": searchTerm,
                "token": token

            }));
         
}, [registrantPage])

    const handlePageSearch = (val) => {
        if (gotopag === true) {
            const inputPage = parseInt(val);

            if ((registrantPage == val) || val == null) {
                if (registrantPage >= data?.registrants?.totalPages) {
                    toast.error(`Page ${registrantPage + 1} does not exist`)
                }
                else {
                    setRegistrantPage(registrantPage + 1);
                    setGoTOPage(registrantPage + 1);
                    window.scrollTo(0, 0);
                }
            }
            else {
                if (!isNaN(inputPage) && inputPage > 0 && inputPage <= data?.registrants?.totalPages) {
                    setRegistrantPage(inputPage);
                } else if (inputPage >= data?.registrants?.totalPages) {
                    toast.error(`Page ${inputPage} does not exist`)
                }
                placeholderDataHide(false)
            }
        }
        setGotopag(false);
    };


    useEffect(() => {
        if (logindata?.token) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
                headers: {
                    Authorization: `Bearer ${logindata?.token}`,
                },
            }).then((res) => {
                const options = res?.data?.map(province => ({
                    value: province._id,
                    label: province.name
                }));
                setProvinceData(options);
            }).catch((error) => {
                toast.error('Error fetching province data');
            });
        }
    }, [])

    const filterApi = (value, value2, value3) => {
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/registrant/category-registrant-filter`, {
            headers: {
                Authorization: `Bearer ${logindata?.token}`,
            },
            params: {
                "province": value,
                "category": value2,
                "city": value3,
                // "search": searchTerm,
            }
        })
            .then((res) => {
                const options = res?.data?.data?.map(province => ({
                    value: province,
                    label: province
                }));
                setCategoriesData(options);
                // const options1 = res?.data?.registrant_names?.map(province => ({
                //     value: province,
                //     label: province
                // }));
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                // Handle error appropriately here
            });
    };


    useEffect(() => {
        const debounceTime = setTimeout(() => {
            filterApi()
        }, 1000);
        return () => clearTimeout(debounceTime);
    }, [searchTerm])



    const stateApi = (value) => {
        if (logindata?.token) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
                headers: {
                    Authorization: `Bearer ${logindata?.token}`,
                    'X-Id': value.value,
                },
            }).then((res) => {
                const options1 = res?.data?.map(name => ({
                    value: name._id,
                    label: name.name
                }));
                setRegistrantData(options1);
            }).catch((error) => {
                toast.error('Error fetching province data');
            });
        }
    }
    const CityApi = (value) => {
        if (logindata?.token) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/city`, {
                headers: {
                    Authorization: `Bearer ${logindata?.token}`,
                    'X-Id': value.value,
                },
            }).then((res) => {
                const options1 = res?.data?.map(name => ({
                    value: name._id,
                    label: name.name
                }));
                setCityData(options1);
            }).catch((error) => {
                toast.error('Error fetching province data');
            });
        }
    }


    const handleReset = () => {
        setProvince('');
        setRegistrant('');
        setCity('');
        setCategory('');
        // setSearchTerm('');
        setSearch('');

        setFormatedAddress('');
        if (autocompleteRef.current) {
            autocompleteRef.current = null;
        }
        setSelectedOptionFilter(1);


        // close Select Option
        if (dropdownRef.current) {
            const dropdown = new window.bootstrap.Dropdown(dropdownRef.current);
            dropdown.hide();
        }
        // clear selected options 
        setNewCategory('')

        // distance 
        if (selectedAddress !== 'Near Location') {
            setSliderValue(0)
        } else {
            setSliderValue(50)
        }


        window.history.replaceState(
            {
                ...window.history.state,
                newCategory: '',
                formatedAddress: '',
                selectedAddress: '',

            },
            ''
        );
        if (registrantPage === 1) {
            dispatch(fetchRegistrants({
                "page": 1,
                "province": '',
                "category": '',
                "country": '',
                "city": '',
                "search": '',
                "is_only_category": '',
                "token": token
            }));
        }
        setRegistrantPage((prev) => {
            if (prev > 1) { return 1; } return prev
        });
        setGoTOPage((prev) => {
            if (prev > 1) { return 1; } return prev
        });

    };

    const handleBack = () => {
        if (registrantPage == 1) {
            navigate('/')
        } else {
            setRegistrantPage(1);
        }

    };

    const [userAddress, setUserAddress] = useState('');
    const [profileImage, setProfileImage] = useState('');

    useEffect(() => {
        const newHistoryState = {
            searchTerm,
            provinceData,
            registrantData,
            cityData,
            categoriesData,
            province,
            category,
            registrant,
            city,
            newCategory,
            formatedAddress,
            sliderValue,
            selectedAddress,
            selectedOptionFilter,
            latLng

        };
        window.history.replaceState(newHistoryState, '');
    }, [searchTerm, provinceData, registrantData, cityData, categoriesData, province, category, registrant, city, selectedOptionFilter, newCategory, formatedAddress, sliderValue, selectedAddress,latLng]);


    const fetchData = async () => {
        try {
            if (token) {
                const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/logged-user-data`, {
                    params: { id: localUserId },
                    headers: { Authorization: `Bearer ${token}` },
                });
                const bookmarkRegistrant = response?.data?.registrant_bookmark?.map(firm => firm.registrant_id);
                setRegistrantBookmarkData(bookmarkRegistrant);
                setUserAddress(response?.data?.address);
                setProfileImage(response?.data?.profile_picture);
            } else {
                navigate("/");
                toast.error(`Please login again`);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('login');
                navigate("/");
                toast.error(`Unauthorized user !`);
            } else {
                toast.error('Error fetching user data');
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [token, localUserId]);


    const downloadData = async () => {
        setDisableDownloadCsv(true);

        try {
            toast("Please Wait Data Is Loading...")
            const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/export-registrants`, {
                headers: {
                    Authorization: `Bearer ${logindata?.token}`,
                },
                params: {
                    "page": registrantPage,
                    "province": registrant?.label,
                    "category": newCategory,
                    "country": province?.label,
                    "city": city?.label,
                    "is_only_category": selectedOptionFilter,
                    "is_address": selectedAddress === 'Head Office' ? 1 : 0,
                    "country": address?.country,
                    "province": address?.state,
                    "city": address?.city,
                    "latitude": latLng?.Lat || null,
                    "longitude": latLng?.Lng || null,
                    "radius": sliderValue,
                    "search": searchTerm
                }
            });

            if (response?.data?.message === "Unauthozied user") {
                localStorage.removeItem('login');
                navigate("/");
                toast.error(`Unauthozied user !`);
            }
            let csvResponseData = response?.data;
            const blob = new Blob([csvResponseData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Registered Individuals Details.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDisableDownloadCsv(false);

        } catch (error) {
            if (error?.message === 'Request failed with status code 401') { localStorage.removeItem('login'); navigate('/login'); }
            setDisableDownloadCsv(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('login');
        toast.success('You have logged out successfully.');
        navigate("/");
    };

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: isFocused ? "pointer" : null,
            };
        }
    };

    const blockInvalidChar = e => ['e', 'E', '+', '-']?.includes(e.key) && e.preventDefault();

    // const handleNearMe = () => {
    //     navigate('/registrant/nearby')
    // }


    const handleResetSearch = () => {
        setSearchTerm('');
        setSearch('');
        if (registrantPage === 1) {
            dispatch(fetchRegistrants({
                "page": 1,
                "province": '',
                "category": '',
                "country": '',
                "city": '',
                "search": '',
                "token": token
            }));
        }
        setRegistrantPage((prev) => {
            if (prev > 1) {
                return 1;
            }
            return prev
        });
        setGoTOPage((prev) => {
            if (prev > 1) {
                return 1;
            }
            return prev
        });
    };

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY_AUTOCOMPLETE;
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
        libraries: ['places'],
    });


    const autocompleteRef = useRef(null);

    // const onPlaceChanged = () => {
    //     const place = autocompleteRef.current.getPlace();
    //     if (place.geometry) {
    //         const newLatLng = {
    //             lat: place.geometry.location.lat(),
    //             lng: place.geometry.location.lng(),
    //         };
    //         // setMarker(newLatLng);
    //         // setLatLng(newLatLng);
    //         // mapRef?.current?.panTo(newLatLng);
    //         // geocodeLatLng(newLatLng.lat, newLatLng.lng);
    //     }
    //     setSearch(place?.formatted_address)



    //     // dispatch(setAddress({
    //     //     address: place?.formatted_address,
    //     // }));
    // };


    const handleInputChange = (e) => {
        const value = e.target.value;
        onPlaceChanged()
        setFormatedAddress(value);

        window.history.replaceState(
            { ...window.history.state, formatedAddress: value },
            ''
        );
    };

    const onPlaceChanged = () => {
        // if (autocompleteRef.current) {

        //     const place = autocompleteRef?.current?.getPlace();

        //     // Reset country, province, and city
        //     setCountry('');
        //     setProvince('');
        //     setCity('');
        //     // Process address components
        //     if (place?.address_components) {
        //         for (let element of place?.address_components) {
        //             switch (element?.types[0]) {
        //                 case 'country':
        //                     setCountry(element?.long_name?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, ""));
        //                     break;
        //                 case 'administrative_area_level_1':
        //                     setProvince(element?.long_name.normalize("NFD")?.replace(/[\u0300-\u036f]/g, ""));
        //                     break;
        //                 case 'locality':
        //                     setCity(element?.long_name.normalize("NFD")?.replace(/[\u0300-\u036f]/g, ""));
        //                     break;
        //                 default:
        //                     break;
        //             }
        //         }
        //     }

        //     // Update the formatted address and history state
        //     const selectedAddress = place?.formatted_address || '';
        //     setFormatedAddress(selectedAddress);
        //     window.history.replaceState(
        //         { ...window.history.state, formatedAddress: selectedAddress },
        //         ''
        //     );

        //     // Update marker and map if available
        //     if (place?.geometry) {
        //         const newLatLng = {
        //             lat: place?.geometry?.location?.lat(),
        //             lng: place?.geometry?.location?.lng(),
        //         };
        //         setMarker(newLatLng);
        //         setLatLng(newLatLng);
        //         window.history.replaceState(
        //             { ...window.history.state, latLng: newLatLng },
        //             ''
        //         );
        //         if (mapRef.current) {
        //             mapRef.current.panTo(newLatLng);
        //         }
        //         // geocodeLatLng(newLatLng.lat, newLatLng.lng);
        //     }

        // }

        const place = autocompleteRef?.current?.getPlace();
        console.error('Geocoder ', place);
        let country = '', state = '', city = '', location = '';
        if (place?.address_components != undefined) {
          for (let element of place?.address_components) {
    
            if (element?.types[0] == 'country') {
              country=element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              break;
            } else if (element?.types[0] == 'administrative_area_level_1') {
              state = element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            } else if (element?.types[0] == 'locality') {
              city = element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            } else if (element?.types[0] == 'sublocality_level_1') {
              location=element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            }
          }
        }
        setAddress({ location, city, state, country});
        console.error("Address : ", country, " ", city, " ", province);
        setFormatedAddress(place?.formatted_address);
        if (place?.geometry) {
          const newLatLng = {
            lat: place?.geometry?.location.lat(),
            lng: place?.geometry?.location.lng(),
          };
          setMarker(newLatLng);
          setLatLng(newLatLng);
          console.error("latlong : ", newLatLng);
          mapRef?.current?.panTo(newLatLng);
          // geocodeLatLng(newLatLng?.lat, newLatLng?.lng);
        }
    };


    const formatNoticeData = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        } else {
            return date.getDate() + (date.getDate() % 10 === 1 && date.getDate() !== 11 ? 'st' : date.getDate() % 10 === 2 && date.getDate() !== 12 ? 'nd' : date.getDate() % 10 === 3 && date.getDate() !== 13 ? 'rd' : 'th') + ' ' + date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
        }
    };

    const handleNoticeModal = (data) => {
        setShowModal(true);
        const noticeDate = formatNoticeData(data?.updated_at);
        setNotificationDate(noticeDate)
        setNotificationName(data?.name);
        setNotificationmessage(data?.message)
    };

    useEffect(() => {
        const fetchNoticeData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/all-notice-data-user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response?.data?.message === "Unauthorized User") {
                    localStorage.removeItem("login");
                    navigate("/");
                    toast.error("Unauthorized user !");
                } else {
                    setNoticeData(response?.data?.notice_data?.data || []);
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    localStorage.removeItem('login');
                    navigate("/");
                    // toast.error(`Unauthorized user !`);
                } else if (error?.response?.status === 500) {
                    toast.error('Server error occurred. Please try again later.');
                } else {
                    console.error('Error :', error);
                    // toast.error('Error fetching notice data');
                }
            }
        };

        if (token) {
            fetchNoticeData();
        } else {
            toast.error("Please login again");
        }
    }, [token, navigate]);




    if (!isLoaded) return <Row style={{ height: "100vh" }}>
        <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center align-content-center"

            style={{ height: "100vh" }}
        >
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClassclassName={{}}
                wrapperStyle=""
                visible={true}
            />
        </Col>
    </Row>;

    return (
        <>
            <div div className="d-flex align-items-center justify-content-between flex-column" style={{ height: '100vh' }}>
                <div className="slider_img_div_box" >
                    <Container fluid className=" mb-3">
                        <Row className="g-2" style={{ paddingBottom: "10px" }}>
                            <Col className="col-2  order-1  d-flex align-items-center justify-content-start py-3">
                                <Link to="/">
                                    <img
                                        src="./asset/logo.png"
                                        className="header_img_logo"
                                        alt=""
                                    />
                                </Link>
                            </Col>
                            <Col className="col-12 col-md-6 col-xl-5  order-3 order-md-2 d-flex align-items-center justify-content-start">
                                <div
                                    className="search_box d-flex align-items-center  justify-content-start"
                                    style={{
                                        border: "2px solid rgba(159, 194, 225, 0.80)",
                                        borderRadius: "6px",
                                        width: "100%",
                                        padding: '8px',
                                        backgroundColor: "#FFFFFF",
                                    }}

                                >

                                    <div className="dropdown search_dropdown_menu">
                                        <a className="btn dealing_reponsive dropdown-toggle" href="#" style={{ backgroundColor: 'rgba(15, 102, 180, 0.10)', color: '#0F66B4' }} role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            Registered Individuals
                                        </a>

                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {/* <li><Link className="dropdown-item" to="/members-search"> Members </Link></li> */}
                                            <li><Link className="dropdown-item" to="/firm"> Registered Firms</Link></li>
                                        </ul>
                                    </div>
                                    <form className="d-flex w-100">
                                        <input
                                            type="search"
                                            className="top-search"
                                            style={{ width: "100%" }}
                                            placeholder="Search By Region, Registered Individuals..."
                                            value={searchTerm}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0 && registrant?.value?.length === 0) {

                                                    setRegistrantPage(1);
                                                    setGoTOPage(1);
                                                };
                                                setSearchTerm(e.target.value)
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    if (searchTerm?.length > 0) {
                                                        searchApi();
                                                        e.target.blur();
                                                    }
                                                }
                                            }}
                                        />

                                        <button type="button" className="reset-search-button me-2" disabled={disable} onClick={handleResetSearch}><i className="fa-solid fa-arrow-rotate-right"></i></button>
                                        <button
                                            type="button"
                                            className="top-search-button"
                                            style={{ cursor: 'context-menu' }}
                                        >
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </button>
                                    </form>
                                </div>
                            </Col>

                            <Col className=" col-10  col-md-4 col-xl-5   order-2 order-md-3 d-flex align-items-center justify-content-end">
                                <div className="icons-top-bar icons_top_bar d-flex align-items-center justify-content-around"></div>

                                <div className="dropdown align-items-center mt-3">
                                    <div
                                        data={noticeDatas?.length}
                                        className="notice_svg me-3"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            style={{ cursor: "pointer" }}
                                            viewBox="0 0 30 30"
                                            fill="none"
                                        >
                                            <path
                                                d="M23.4375 12.1375V11.2563C23.4375 6.42 19.6575 2.5 15 2.5C10.3425 2.5 6.5625 6.42 6.5625 11.2563V12.1375C6.56401 13.1894 6.26381 14.2198 5.6975 15.1062L4.3125 17.2625C3.04875 19.2313 4.01375 21.9075 6.2125 22.53C11.9576 24.1587 18.0424 24.1587 23.7875 22.53C25.9863 21.9075 26.9513 19.2313 25.6875 17.2638L24.3025 15.1075C23.7358 14.2211 23.4351 13.1908 23.4363 12.1388L23.4375 12.1375Z"
                                                fill="#A7ADB4"
                                            />
                                            <path
                                                d="M9.375 22.75C10.1937 24.935 12.4025 27.5 15 27.5C17.5975 27.5 19.8062 24.935 20.625 22.75"
                                                fill="#A7ADB4"
                                            />
                                        </svg>
                                    </div>
                                    <ul
                                        className="dropdown-menu notice_data py-0"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <li className="p-3 border-bottom">
                                            <p className="mb-0 d-flex align-items-center justify-content-between">
                                                Notifications
                                                <span>
                                                    <i className="fa-solid fa-bars-staggered"></i>
                                                </span>
                                            </p>
                                        </li>

                                        <li>
                                            <ul className=" list-unstyled p-0 notice_data_item_box">
                                                {noticeDatas?.map((noticeData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link
                                                                className="dropdown-item"
                                                                onClick={() => {
                                                                    handleNoticeModal(noticeData);
                                                                }}
                                                            >
                                                                <p className="notice_data_item mb-0">
                                                                    {noticeData?.name}
                                                                </p>
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="d-flex flex-row top-bar-dashboard">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic"
                                            className="p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center mt-2"
                                            style={{
                                                backgroundColor: "transparent",
                                                border: "transparent",
                                            }}
                                        >
                                            <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
                                                <h5 className="text-end mb-0">
                                                    {capitalizeFirstLetter(userName)}
                                                </h5>
                                                <h6 className="mb-0 text-end">{userAddress}</h6>
                                            </div>

                                            {profileImage?.length > 0 ? (
                                                <span
                                                    className='j_header_profile_img'
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "50%",
                                                        transition: '0.3s'
                                                    }}
                                                >
                                                    <img
                                                        className="fluid"
                                                        src={`${profileImage}`}
                                                        alt="logo "
                                                        style={{
                                                            borderRadius: "8px",
                                                            height: "3rem",
                                                            width: "3rem",
                                                            borderRadius: "100px",
                                                        }}
                                                    />{" "}
                                                </span>
                                            ) : (
                                                <span
                                                    className='j_header_profile_img'
                                                    style={{
                                                        transition: '0.3s',
                                                        border: "1px solid black",
                                                        borderRadius: "50%",
                                                    }}
                                                >
                                                    <img
                                                        src="./asset/users.png"
                                                        className="fluid"
                                                        alt=""
                                                        style={{
                                                            borderRadius: "8px",
                                                            height: "3rem",
                                                            width: "3rem",
                                                            borderRadius: "100px",
                                                        }}
                                                    />
                                                </span>
                                            )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                className="d-flex justify-content-between align-items-center"
                                                href="/bookmarks"
                                            >
                                                Bookmark{" "}
                                                <span>
                                                    <i
                                                        style={{ color: "#898b8d" }}
                                                        className="fa-solid fa-bookmark"
                                                    ></i>
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="d-flex justify-content-between align-items-center"
                                                href="/settings"
                                            >
                                                Manage Settings{" "}
                                                <span>
                                                    <i
                                                        style={{ color: "#898b8d" }}
                                                        className="fa-solid fa-gear"
                                                    ></i>
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item href="/">
                                                {" "}
                                                <button
                                                    className="logout w-100"
                                                    onClick={() => logout()}
                                                >
                                                    Logout
                                                </button>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}

                                <div className="d-flex flex-row top-bar-dashboard">
                                    <div className="dropdown">
                                        <button
                                            className="btn dropdown-toggle p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center mt-2"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{
                                                backgroundColor: "transparent",
                                                border: "transparent",
                                            }}
                                        >
                                            <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
                                                <h5 className="text-end mb-0">
                                                    {capitalizeFirstLetter(userName)}
                                                </h5>
                                                <h6 className="mb-0 text-end">{userAddress}</h6>
                                            </div>
                                            {profileImage?.length > 0 ? (
                                                <span
                                                    className="j_header_profile_img"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "50%",
                                                        transition: "0.3s"
                                                    }}
                                                >
                                                    <img
                                                        className="fluid"
                                                        src={`${profileImage}`}
                                                        alt="Profile"
                                                        style={{
                                                            borderRadius: "8px",
                                                            height: "3rem",
                                                            width: "3rem",
                                                            borderRadius: "100px",
                                                        }}
                                                    />
                                                </span>
                                            ) : (
                                                <span
                                                    className="j_header_profile_img"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "50%",
                                                        transition: "0.3s"
                                                    }}
                                                >
                                                    <img
                                                        src="./asset/users.png"
                                                        className="fluid"
                                                        alt="Default Profile"
                                                        style={{
                                                            borderRadius: "8px",
                                                            height: "3rem",
                                                            width: "3rem",
                                                            borderRadius: "100px",
                                                        }}
                                                    />
                                                </span>
                                            )}
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <li>
                                                <a
                                                    className="dropdown-item d-flex justify-content-between align-items-center"
                                                    href="/bookmarks"
                                                >
                                                    Bookmark
                                                    <span>
                                                        <i
                                                            style={{ fontSize: '14px', color: '#898b8d' }}
                                                            className="fa-solid fa-bookmark"
                                                        ></i>
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="dropdown-item d-flex justify-content-between align-items-center"
                                                    href="/settings"
                                                >
                                                    Manage Profile
                                                    <span>
                                                        <i
                                                            style={{ fontSize: '14px', color: '#898b8d' }}
                                                            className="fa-solid fa-gear"
                                                        ></i>
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <span className="dropdown-item" onClick={() => logout()}>
                                                    <button className="logout w-100">
                                                        Logout
                                                    </button>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="d-flex flex-row top-bar-dashboard">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-success dropdown-toggle p-0 ms-2 d-flex justify-content-between align-items-center mt-2"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{
                                                backgroundColor: "transparent",
                                                border: "none",
                                                boxShadow: "none",
                                            }}
                                        >
                                            <div className="data-user text-dark d-flex flex-column me-3">
                                                <h5 className="text-end mb-0">
                                                    {capitalizeFirstLetter(userName)}
                                                </h5>
                                                <h6 className="mb-0 text-end">{userAddress}</h6>
                                            </div>

                                            <span
                                                className="j_header_profile_img"
                                                style={{
                                                    border: "1px solid black",
                                                    borderRadius: "50%",
                                                    transition: '0.3s',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <img
                                                    className="img-fluid"
                                                    src={profileImage || "./asset/users.png"}
                                                    alt="Profile"
                                                    style={{
                                                        borderRadius: "50%",
                                                        height: "3rem",
                                                        width: "3rem",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </span>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <li>
                                                <a className="dropdown-item d-flex justify-content-between align-items-center" href="/bookmarks">
                                                    Bookmark
                                                    <i
                                                        style={{ fontSize: '14px', color: "#898b8d" }}
                                                        className="fa-solid fa-bookmark"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item d-flex justify-content-between align-items-center" href="/settings">
                                                    Manage Profile
                                                    <i
                                                        style={{ fontSize: '14px', color: "#898b8d" }}
                                                        className="fa-solid fa-gear"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item w-100 text-center"
                                                    onClick={logout}
                                                >
                                                    Logout
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}


                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className=" mb-4">
                        <div className="bg-white shadow-sm mt-3 areaBox filer_opt_holds_main mb-4" style={{ borderRadius: '10px' }}>
                            <Row>
                                <div className="col-xl-9">
                                    <Row>
                                        <div className="col-xxl-5 col-lg-6">
                                            <div className="firm-filer_opt">
                                                <p>Address</p>
                                                <div className="search_box d-flex align-items-center  justify-content-start"
                                                    style={{
                                                        borderRadius: "6px",
                                                        width: "100%",
                                                        padding: '8px',
                                                        backgroundColor: "#FFFFFF",
                                                    }}>
                                                    <div className="dropdown search_dropdown_menu">
                                                        <a className="btn  dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {selectedAddress}
                                                        </a>

                                                        <div className="dropdown-menu select-address">
                                                            <ul className="" >
                                                                {dropdownOptions.map((dropdownOptions, index) => (
                                                                    <li key={index} style={{ display: selectedAddress === dropdownOptions ? "none" : "block" }}>
                                                                        <button
                                                                            className=""
                                                                            onClick={() => handleAddressVal(dropdownOptions)}
                                                                        >
                                                                            {dropdownOptions}
                                                                        </button>
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <span className="d-flex w-100">
                                                        <Autocomplete
                                                            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                                            onPlaceChanged={onPlaceChanged}
                                                            // value={formatedAddress}
                                                            className="w-100"
                                                        >
                                                            <input
                                                                type="search"
                                                                className="top-search w-100"
                                                                placeholder={`Search ${selectedAddress}`}
                                                                value={formatedAddress}
                                                                onChange={handleInputChange}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault();
                                                                        onPlaceChanged();
                                                                        if (formatedAddress?.length > 0) {
                                                                            searchApi();
                                                                            e.target.blur();
                                                                        }
                                                                    }
                                                                }}


                                                            />
                                                        </Autocomplete>
                                                    </span>
                                                    {/* <span className="d-flex w-100">
                                                    <Autocomplete
                                                        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                                        onPlaceChanged={() => {
                                                            if (autocompleteRef.current) {
                                                                const place = autocompleteRef.current.getPlace();
                                                                const selectedAddress = place.formatted_address || '';

                                                                setFormatedAddress(selectedAddress);

                                                                if (selectedAddress.length > 0) {
                                                                    searchApi(selectedAddress);
                                                                }
                                                            }
                                                        }}
                                                        value={formatedAddress}
                                                        className="w-100"
                                                    >
                                                        <input
                                                            type="search"
                                                            className="top-search w-100"
                                                            placeholder={`Search ${selectedAddress}`}
                                                            onKeyPress={(e) => {
                                                                if (e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    if (formatedAddress?.length > 0) {
                                                                        searchApi(formatedAddress);
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Autocomplete>
                                                </span> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-2 col-lg-3 mt-lg-0 mt-3">
                                            <div className="firm-filer_opt">
                                                <p>Category</p>
                                                <div>
                                                    <div className="dropdown cat_dropdown">
                                                        <button
                                                            className={` btn btn-secondary dropdown-toggle ${newCategory?.length > 0 ? 'active' : ''}`}
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-auto-close="false"
                                                            aria-expanded="false"
                                                            ref={dropdownRef}
                                                        >
                                                            <span>{newCategory?.length > 0 ? newCategory : 'Category'}</span>
                                                        </button>
                                                        <div className="dropdown-menu cat_dropdown_list">
                                                            <div className="cat_dropdown_list_header">
                                                                <div className="cat_dropdown_menu">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            name="registered_in_single_category"
                                                                            value={1}
                                                                            checked={selectedOptionFilter == 1}
                                                                            onChange={handleOptionChange}
                                                                        />
                                                                        Registered in single category
                                                                    </label>
                                                                </div>
                                                                <div className="cat_dropdown_menu">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            name="registered_in_multiple_category"
                                                                            value={0}
                                                                            checked={selectedOptionFilter == 0}
                                                                            onChange={handleOptionChange}
                                                                        />
                                                                        Registered in multiple categories
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ul className="cat_dropdown_list_show" Address >
                                                                {categoriesData?.length > 0 ? (
                                                                    categoriesData?.map((data, i) => (
                                                                        <li key={i} >
                                                                            <span
                                                                                className=""
                                                                                // style={{ cursor: 'pointer' }}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    fontWeight: newCategory === data?.value?.category ? 'bold' : 'normal',
                                                                                    color: newCategory === data?.value?.category ? '#0f66b4' : 'black',
                                                                                }}
                                                                                onClick={() => handleCategory(data?.value?.category)}
                                                                            >
                                                                                {data?.value?.category}
                                                                            </span>
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <li>
                                                                        <span className="mb-0 text-center">No Data</span>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            {selectedAddress === 'Near Location' ?
                                                <div className="firm-filer_opt mt-lg-0 mt-3" style={{ paddingLeft: '8px' }}>
                                                    <div>
                                                        <p>Distance Range</p>
                                                        <div style={{ position: 'relative' }} className="pt-4 mt-3 range-box">
                                                            <Slider
                                                                className="custom-distance-slider"
                                                                disabled={formatedAddress?.length > 0 ? false : true}
                                                                // disabled={handleDisableDistance}
                                                                min={50}
                                                                max={300}
                                                                step={50}
                                                                marks={{}} // Use marks without labels
                                                                defaultValue={50}
                                                                value={sliderValue}
                                                                onChange={handleChange}
                                                                trackStyle={{ backgroundColor: '#0F66B4', height: '10px' }}
                                                                railStyle={{ backgroundColor: '#ddd', height: '10px' }}
                                                                handleStyle={{
                                                                    borderColor: '#0F66B4',
                                                                    borderWidth: '3px',
                                                                    height: '20px',
                                                                    width: '20px',
                                                                }}
                                                                dotStyle={{
                                                                    height: '10px', // Adjust the size of the inactive dots
                                                                    width: '10px',  // Adjust the size of the inactive dots
                                                                    backgroundColor: '#0F66B4', // Color for inactive dots
                                                                    borderRadius: '50%',
                                                                    top: '0%',
                                                                }}
                                                                activeDotStyle={{
                                                                    height: '10px', // Adjust the size of the active dot
                                                                    width: '10px',  // Adjust the size of the active dot
                                                                    backgroundColor: '#0F66B4', // Color for active dot
                                                                    borderRadius: '50%',
                                                                    top: '0%',
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: `${sliderValue === 50 ? ((sliderValue - 20) / 250) * 100 : ((sliderValue - 40) / 250) * 100}%`,
                                                                    transform: 'translateX(-50%)',
                                                                    backgroundColor: '#0F66B4',
                                                                    color: '#fff',
                                                                    padding: '5px 12px',
                                                                    borderRadius: '20px',
                                                                    whiteSpace: 'nowrap',
                                                                    fontSize: '12px'
                                                                }}

                                                            >
                                                                {sliderValue} KM
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                        </div>
                                    </Row>
                                </div> 
                                <div className="col-xl-3 d-flex align-items-center justify-content-xl-end justify-content-center mt-4 mb-2 mt-xl-0 mb-xl-0" >
                                    <div className="">
                                        <button
                                            className="reset-filter "
                                            onClick={() => {
                                                if (!disableSecond) {
                                                    handleReset();
                                                }
                                            }}
                                            style={{
                                                color: '#FF4740',
                                                backgroundColor: '#FFF0EB',
                                                whiteSpace: "nowrap",
                                                cursor: disableSecond ? "not-allowed" : "pointer",
                                            }}
                                        // disabled={disable}
                                        >
                                            <i className=" svg_size fa-solid fa-rotate-right" style={{
                                                color: '#FF4740',
                                            }}></i>

                                            Reset
                                        </button>
                                    </div>
                                    <div className="" style={{ paddingLeft: "10px" }}>

                                        <div className="" style={{ paddingLeft: "10px" }}>
                                            {data?.error == null && disableDownloadCsv === false ? (
                                                <button
                                                    // disabled={disableDownloadCsv}
                                                    onClick={downloadData}
                                                    className="export-data"
                                                    style={{
                                                        color: '#fff',
                                                        backgroundColor: '#0F66B4',
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                                                    </svg>
                                                    Export
                                                </button>
                                            ) : (
                                                <button
                                                    disabled={disableDownloadCsv}
                                                    className="export-data"
                                                    style={{
                                                        color: '#fff',
                                                        backgroundColor: '#0F66B4',
                                                        opacity: (data?.error !== null && disableDownloadCsv == true) ? 1 : 0.9,
                                                        cursor: (data?.error !== null && disableDownloadCsv == true) ? 'not-allowed' : 'not-allowed',
                                                    }} > {" "}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                                                    </svg>{" "}
                                                    {data?.error == null && disableDownloadCsv ? 'Loading' : 'Export'}
                                                </button>
                                            )}
                                        </div>


                                    </div>
                                </div>
                            </Row>


                            {/* <div className="row"> */}
                            {/* <div className="col-12">
                                    <div className=" d-flex align-items-end justify-content-start justify-content-sm-start flex-wrap" style={{ gap: '8px' }}> */}


                            {/* <div className={` filter_div ${province?.value ? 'active' : ''}`}>
                                            <label htmlFor="">
                                                Country
                                            </label>
                                            <Select
                                                value={province}
                                                onChange={handleChange2}
                                                options={provinceData}
                                                styles={colourStyles}
                                            />
                                        </div>
                                        <div className={` filter_div ${registrant?.value ? 'active' : ''}`}>
                                            <label htmlFor="">
                                                Province
                                            </label>
                                            <Select
                                                isDisabled={province?.value?.length > 0 ? false : true}
                                                value={registrant}
                                                onChange={handleChange3}
                                                options={registrantData}
                                                styles={colourStyles}
                                            />
                                        </div>

                                        <div className={` filter_div ${city?.value ? 'active' : ''}`}>
                                            <label htmlFor="">
                                                City
                                            </label>
                                            <Select
                                                isDisabled={registrant?.value?.length > 0 ? false : true}
                                                value={city}
                                                onChange={handleChange4}
                                                options={cityData}
                                                styles={colourStyles}
                                            />
                                        </div>

                                        <div className={` filter_div ${category?.value ? 'active' : ''}`}>
                                            <label htmlFor="">Category</label>
                                            <Select
                                                isDisabled={province?.value?.length > 0 ? false : true}
                                                value={category}
                                                onChange={handleChange1}
                                                options={categoriesData}
                                                styles={colourStyles}
                                            // style={{
                                            //     borderRadius: "20px",
                                            //     border: "8px solid #E1DEDE",
                                            //     background: "#FFF",
                                            //     cursor: "pointer"
                                            // }}
                                            />
                                        </div> */}






                            {/* <div className="firm-filer_opt">
                                                <div>
                                                    <p>Distance Range</p>
                                                    <Slider min={50}
                                                        max={300}
                                                        step={null} // Ensures it only stops at the specified marks
                                                        marks={marks}
                                                        defaultValue={50} />
                                                </div>
                                            </div> */}


                            {/* Hasan start from here */}

                            {/* <div className="">




                                        </div>
                                    </div>
                                </div> */}
                            {/* <div className="col-12 col-lg-3 d-flex align-items-end justify-content-md-end justify-content-end">
                                    <div>
                                        <label htmlFor="">

                                        </label>
                                        <button
                                            className="reset-filter "
                                            onClick={() => {
                                                if (!disable) {
                                                    handleReset();
                                                }
                                            }}
                                            style={{
                                                color: '#FF4740',
                                                backgroundColor: '#FFF0EB',
                                                whiteSpace: "nowrap",
                                                cursor: disable ? "not-allowed" : "pointer",
                                            }}
                                        // disabled={disable}
                                        >
                                            <i className=" svg_size fa-solid fa-rotate-right" style={{
                                                color: '#FF4740',
                                            }}></i>

                                            Reset
                                        </button>
                                    </div>
                                    <div>
                                        <label htmlFor="">

                                        </label>

                                        <div className="" style={{ paddingLeft: "10px" }}>
                           
                      <div className="" style={{ paddingLeft: "10px" }}>
                        {data?.error == null && disableDownloadCsv === false ? (
                          <button
                            // disabled={disableDownloadCsv}
                            onClick={downloadData}
                            className="export-data"
                            style={{
                              color: '#fff',
                              backgroundColor: '#0F66B4',
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                            </svg>
                            Export
                          </button>
                        ) : (
                          <button
                          disabled={disableDownloadCsv}
                          className="export-data"
                          style={{
                            color: '#fff',
                            backgroundColor: '#0F66B4',
                            opacity: (data?.error !== null &&  disableDownloadCsv == true) ? 1 : 0.9, 
                            cursor: (data?.error !== null &&  disableDownloadCsv == true) ? 'not-allowed' : 'not-allowed',
                          }} > {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                            </svg>{" "}
                          {data?.error == null && disableDownloadCsv ? 'Loading' : 'Export'}  
                          </button>
                        )}
                      </div>
                   

                                        </div>

                                    </div>
                                </div> */}
                            {/* </div> */}

                        </div>

                        {data?.error === "No Data found" || data?.status === "loading" ? '' :
                            <div className="account-information mb-3">
                                <div>
                                    <div className="  d-flex align-items-center px-4 justify-content-between">

                                        <span className="p-1"> Total {data?.registrants?.totalCount > 0 ? "Registered Individuals" : "Registered Individual"} : {data?.registrants?.totalCount}</span>
                                        {/* <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => handleNearMe()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                                <path d="M0 6.45283C0 2.88903 2.88903 0 6.45283 0H33.3396C36.9034 0 39.7925 2.88903 39.7925 6.45283V33.3396C39.7925 36.9034 36.9034 39.7925 33.3396 39.7925H6.45283C2.88903 39.7925 0 36.9034 0 33.3396V6.45283Z" fill="white" fillOpacity="0.15" />
                                                <path d="M20.8 28L18.2667 21.7333L12 19.2V17.9556L28 12L22.0444 28H20.8ZM21.3778 24.7111L24.9778 15.0222L15.2889 18.6222L19.6444 20.3556L21.3778 24.7111Z" fill="white" />
                                            </svg>
                                            <span className="near_me_feature">Registered Individuals Near Me</span>
                                        </div> */}

                                    </div>

                                </div>

                            </div>}

                        <Row>

                            {data?.status === "loading" ?
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="d-flex justify-content-center align-content-center"

                                    style={{ height: "79vh" }}
                                >
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#0f66b4"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperClassclassName={{}}
                                        wrapperStyle=""
                                        visible={true}
                                    />
                                </Col>
                                :
                                <>
                                    <span onClick={handleBack} className="d-inline-block mb-3 text-decoration-none" style={{ color: '#0F67B6', fontSize: '18px', fontWeight: '600', fontFamily: `'lato',sans-serif`, cursor: 'pointer' }}><i className="fa-solid fa-arrow-left-long me-2"></i> Go Back</span>
                                    {data?.error === "No Data found" ?

                                        <div><p className=" text-center fw-bolder fs-3">No Data Found</p></div> :
                                        <>
                                            <Col

                                                xl={8}
                                                lg={9}
                                                className=" pt-2 pt-lg-0  dashboard_list_scroll "
                                            >
                                                <Row className="g-3 pb-3  ">
                                                    {data?.registrants?.registrants?.map((elem, i) => (
                                                        <Col xs={12} sm={6} md={4} lg={6} key={elem.linkedin_link}>
                                                            {/* <div key={i}> */}
                                                            <RegistrantCard data={elem} index={i} fetchData={fetchData} registrantBookmarkData={registrantBookmarkData} />
                                                            {/* </div> */}
                                                            {/* <RegistrantShow elem={elem} index={i} fetchData={fetchData} registrantBookmarkData={registrantBookmarkData}/> */}
                                                        </Col>
                                                    ))}
                                                </Row>



                                            </Col>


                                            <Col xl={4} lg={3}>
                                                <div className="map-container mt-4  mt-lg-0 h-100">
                                                    <RegistrantMap data={data?.registrants?.registrants} />
                                                </div>
                                            </Col>
                                            <Row className="py-4">


                                                {data?.status === "loading" || data?.error === "No Data found" ? " " :
                                                    <Col xxl={8}
                                                        lg={8}
                                                        md={12}
                                                        sm={12} className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between py-2" style={{ gap: '10px' }}>
                                                        <div className="pagination_section">

                                                            <CustomPagination
                                                                maxWidth={5}
                                                                total={data?.registrants?.totalPages}
                                                                current={registrantPage}
                                                                onPageChange={(page) => handlePageChange(page)}
                                                            />

                                                        </div>

                                                        <div>
                                                            <ul className=" list-unstyled new_side_pagination ">
                                                                <li style={{ whiteSpace: 'nowrap' }}>Go to page</li>
                                                                <li>
                                                                    <label htmlFor="pageNEmber">
                                                                        <input type="number" name="" id="pageNEmber"
                                                                            placeholder={onfocas ? '' : registrantPage}
                                                                            value={goToPage ? goToPage : " "}
                                                                            onFocus={() => placeholderDataHide(true)}
                                                                            onBlur={() => { placeholderDataHide(false); setGotopag(true) }}
                                                                            onChange={(e) => {
                                                                                // setGoTOPage(e.target.value);
                                                                                if (e.target.value > data?.registrants?.totalPages) {
                                                                                    toast.error(`Page ${e.target.value} does not exist`);
                                                                                    setGoTOPage("")
                                                                                } else {
                                                                                    // setRegistrantPage(e.target.value);
                                                                                    setGoTOPage(e.target.value)
                                                                                }
                                                                            }}
                                                                            onKeyDown={blockInvalidChar}
                                                                        />

                                                                    </label>

                                                                </li>
                                                                <li>
                                                                    <div onClick={() => { handlePageSearch(goToPage) }}>
                                                                        <i className="fa-solid fa-arrow-right"></i>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Col>}





                                            </Row>
                                        </>
                                    }

                                </>
                            }

                        </Row>


                    </Container>
                </div >
                <Footer />
            </div >
            <div className="d-flex justify-content-center align-items-center">
                <Modal show={showModal} onHide={handleClose} centered size="lg" id="notificaion-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span style={{ color: "#0F66B4" }}>Notifications</span>{" "}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="model-content">
                        <div className="notification-img">
                            <div
                                className="img_container "

                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 39 39"
                                    fill="none"
                                    className=""
                                >
                                    <path
                                        d="M14.6091 26.6064L19.5 23.6384L24.3909 26.6064L23.095 21.0467L27.4425 17.2844L21.7155 16.8246L19.5 11.5575L17.2844 16.8246L11.5575 17.2844L15.905 21.0467L14.6091 26.6064ZM19.5 38.3947L13.8984 32.8768H6.12316V25.1015L0.605225 19.5L6.12316 13.8984V6.12316H13.8984L19.5 0.605225L25.1015 6.12316H32.8768V13.8984L38.3947 19.5L32.8768 25.1015V32.8768H25.1015L19.5 38.3947ZM19.5 33.7128L23.6802 29.5326H29.5326V23.6802L33.7128 19.5L29.5326 15.3197V9.46736H23.6802L19.5 5.28711L15.3197 9.46736H9.46736V15.3197L5.28711 19.5L9.46736 23.6802V29.5326H15.3197L19.5 33.7128Z"
                                        fill="#0F66B4"
                                    />
                                </svg>
                            </div>
                            <span>{notificationDate}</span>
                        </div>

                        <div className="modal_body">
                            <h4>{notificationName}</h4>
                            <div className="notification_message overflow-auto">
                                <p>
                                    {notificationmessage}
                                </p>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    );
};

export default RegistrantSearch;
