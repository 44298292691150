import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import axios from "axios";
import ResponsivePagination from "react-responsive-pagination";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate, Link } from "react-router-dom";
import Firmdetail from "./Firmdetail";
import { useDispatch, useSelector } from 'react-redux';
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import Select from "react-select";
// import { Country, State } from "country-state-city";
import { setPageNumber } from "../../redux/slice/firmdNumber";
import CustomPagination from "../../components/pagination/CustomPagination";

const AdminFirms = () => {
  let token;
  const navigate = useNavigate();
  const [editId, setEditId] = useState();
  const [nrd, setNrd] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [totalUsers, setTotalUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modelshow, setModelShow] = useState(false);
  const userData = JSON.parse(window.localStorage.getItem("loginAdmin"));
  const [count, setCount] = useState([0]);
  const [incriment, setIncriment] = useState(1);
  const [file, setFile] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState(window.history.state?.search ||"");
  const [NrdVlue, setNrdVlue] = useState();
  const [Nrderror, setNrderror] = useState('');
  const [sort, setSort] = useState(null);
  const [orderName, setOrderName] = useState("");
  const [shortstatus, setshortstatus] = useState(null);
  const [shortName, setshortName] = useState(null);
  const render = useRef(0);
  const [regioncatgoricesValue, setRegioncatgoricesValue] = useState('');
  const [otherName, setotherName] = useState('');
  const [specialitiesVlaue, setSpecialitiesVlaue] = useState('');
  const [isdisabled, setIsDisabled] = useState(true);
  const [reginIsdisabled, reginSetIsDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const pageNumberStore = useSelector(state => state.pageNumber);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState();
  const [province, setProvince] = useState();
  const [city, setCity] = useState();
  const [currentPage, setCurrentPage] = useState(pageNumberStore.value);
  const [goToPage, setGoTOPage] = useState(0);
  const [iscitydisabled, setIsCityDisabled] = useState(true);

  const fileInputRef = useRef(null);

  const handlePageSearch = (val) => {
    const inputPage = parseInt(val);
    if (!isNaN(inputPage) && inputPage > 0 && inputPage <= totalPages) {
      setCurrentPage(inputPage);
    } else if (inputPage >= totalPages) {
      toast.error(`Page ${inputPage} does not exist`)
    }
  };


  const [addressIndex, setAddressIndex] = useState();
  const [address, setAddress] = useState({
    country: null,
    province: null,
    city: null,
  });
  const [resionAddress, setResionAddress] = useState({
    country: null,
    province: null,
    city: null,
  });
  // const countries = Country.getAllCountries();
  // const [countryCode, setCountryCode] = useState("IN");
  // const [resionCountryCode, setResionCountryCode] = useState("IN");

  const [regionData, setRegionData] = useState([]);
  
  let countryName = "";
  let stateName = "";
  let cityName = "";
  let resionCountryName = "";
  let resionStateName = "";


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
      headers: {
        'X-Find': 'Canada',
      },

    }).then((res) => {
      const options = res?.data?.map(province => ({
        value: province._id,
        label: province.name,
        phoneCode: province.phone_code
      }));
      setCountries(options);
    }).catch((error) => {
      toast.error('Error fetching province data');
    });

  }, []);

  const provinceApi = (value) => {
    axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
      headers: {
        'X-Id': value.value,
      },
    }).then((res) => {
      const options1 = res?.data?.map(name => ({
        value: name._id,
        label: name.name
      }));
      setProvince(options1);
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });

  };

  const CityApi = (value) => {
    axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/city`, {
      headers: {
        'X-Id': value.value,
      },
    }).then((res) => {
      const options1 = res?.data?.map(name => ({
        value: name._id,
        label: name.name
      }));
      setCity(options1);
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });

  };

  const [newRegion, setNewRegion] = useState({
    region_name: '',
    region_country: '',
    categories: []
  });



  const selectInputRef = useRef();
  const countryInputRef = useRef();



  const handlereset = () => {
    setOrderName("");
    setSort(null);
    // shortName
    setSearch('');
    setshortName(null);
    // setSortNameStatus(null);
    // setSearchQuery("");
    const newHistoryState = {
      search: ''
    };
    window.history.replaceState(newHistoryState, '');
  };


  const [catagorisDatanew, setcatagorisDatanew] = useState([]);
  const [arrayOtherName, setArrayOtherName] = useState([]);
  const [arrayspecialities, setArrayspecialities] = useState([]);
  const reginCategoresAdd = () => {
    if (regioncatgoricesValue) {
      setcatagorisDatanew(newRegion.categories.push(regioncatgoricesValue))
      setRegioncatgoricesValue('')
    }

  }


  const deleteItemCategore = (index) => {
    let data = newRegion.categories.splice(index, 1);
    setcatagorisDatanew(data)

  }


  useEffect(() => {
    const newHistoryState = {
      search
    };
    window.history.replaceState(newHistoryState, '');
  }, [search]);

  


  const [formData, setFormData] = useState({
    category: '',
    country: '',
    city: '',
    description: '',
    firm: '',
    FirmType: '',
    geographicArea: '',
    headOfficeStreetName: '',
    headOfficeBuildingName: '',
    headOfficeCity: '',
    headOfficePostalCode: '',
    headOfficePhone: '',
    numberOfPeople: '',
    lastFundingRaised: '',
    lastFundingCurrency: '',
    lastFundingDate: '',
    lastFundingType: '',
    linkedinUrl: '',
    firmResourceUrl:'',
    location: '',
    nrd: '',
    otherName: [],
    postalCode: '',
    phoneNumber: '',
    province: '',
    // longitude: '',
    latitude: '',
    specialities: [],
    tagline: '',
    website: '',
    yearFounded: '',
    region: []
  });





  const [errors, setErrors] = useState({
    firm: '',
    nrd: '',
    location: '',
    city: '',
    postalCode: '',
    headOfficeStreetName: '',
    headOfficeCity: '',
    headOfficePostalCode: '',
    headOfficePhone: '',
    geographicArea: '',
    category: '',
    website: '',
    linkedinUrl: '',
    lastFundingCurrency: '',
    country: '',
    province: '',
    tagline: '',
    firmResourceUrl: '',
    // longitude: '',
    latitude: '',
    yearFounded: '',
    phoneNumber: '',
  });


  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;


  const otherNameAdd = () => {
    if (otherName) {
      setArrayOtherName(formData.otherName.push(otherName))
      setotherName('')
    }

  }
  const specialitiesAdd = () => {
    if (specialitiesVlaue) {
      setArrayspecialities(formData.specialities.push(specialitiesVlaue))
      setSpecialitiesVlaue('')
    }

  }

  const deleteOtherName = (index) => {
    let data = formData.otherName.splice(index, 1);
    setArrayOtherName(data)
  }
  const deleteSpecialities = (index) => {
    let data = formData.specialities.splice(index, 1);
    setArrayspecialities(data)
  }

  let newErrors = { ...errors };


  const reginCategoresData = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    setRegioncatgoricesValue(trimmedValue)
    reginValidateField(name, value);
  }
  const otherNameValue = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    setotherName(trimmedValue)
    reginValidateField(name, value);
  }
  const specialitiesChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    setSpecialitiesVlaue(trimmedValue)
    reginValidateField(name, value);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const filteredValue = value.replace(emojiRegex, '');
    const trimmedValue = filteredValue?.trimStart();

    setFormData({
      ...formData,
      [name]: trimmedValue,
    });

    if (name === 'lastFundingRaised') {
      const numericValue = trimmedValue.replace(/[^\d]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    }

    if (name === 'nrd') {
      checkNrdNumber(value);
      setNrdVlue(value)
    }
    validateField(name, value);
  };


  const reginValidateField = (fieldName, value) => {
    if (fieldName === 'reginCatagores') {
      newErrors.reginCatagores = value === '' ? '' : '';
    }
    else if (fieldName === 'otherName') {
      newErrors.otherName = value === '' ? '' : '';
    }
    else if (fieldName === 'specialities') {
      newErrors.specialities = value === '' ? '' : '';
    }
    setErrors(newErrors);
  }

  const validateField = (fieldName, value) => {

    if (fieldName === 'firm') {
      newErrors.firm = value === '' ? 'Firm name is required.' : '';
    }
    else if (fieldName === 'nrd') {
      let pattern = /^[0-9]+$/;
      // newErrors.nrd = value === '' ? 'NRD number is required.' : (value.length > 0 && value.length < 5 ? 'NRD number must be at  5 digits.' : '');
      if (value.length == '') {
        newErrors.nrd = 'NRD number is required.'
      }
      else if (value.length > 0 && value.length < 4) {
        newErrors.nrd = 'NRD number must be at  4 - 5 digits.';
      }
      else if (!pattern.test(value)) {
        newErrors.nrd = 'Only numbers are allowed.';
      } else {
        newErrors.nrd = ''
      }

    }

    // else if (fieldName === 'location') {
    //   newErrors.location = value === '' ? 'Location  is required.' : '';
    // }
    // else if (fieldName === 'city') {
    //   newErrors.city = value === '' ? 'City name is required.' : '';
    // }
    else if (fieldName === 'postalCode') {
      let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{3,10}(?<!-)$/;
      // if (value.length == '') {
      //   newErrors.postalCode = 'Postal code is required.'
      // }
      // else 
      if (value?.length !== 0) {
        if (!pattern.test(value)) {
          newErrors.postalCode = 'Invalid  postal code';
        } else {
          newErrors.postalCode = ''
        }
      }
    } else if (fieldName === 'firmResourceUrl') {
      let pattern = /^https:\/\/info\.securities-administrators\.ca\/nrsmobile\/NRSFirmRegistrationRecord\.aspx\?firmId=\d+$/;

      if (value && !pattern.test(value)) {
        newErrors.firmResourceUrl = 'Please enter a valid link! Example: https://info.securities-administrators.ca/nrsmobile/NRSFirmRegistrationRecord.aspx?firmId=id';
      } else {
        newErrors.firmResourceUrl = '';
      }
    }  

    
    // else if (fieldName === 'headOfficeStreetName') {
    //   newErrors.headOfficeStreetName = value === '' ? 'Head office street name  is required.' : '';
    // }

    // else if (fieldName === 'headOfficeCity') {
    //   newErrors.headOfficeCity = value === '' ? 'Head office city name  is required.' : '';
    // }
    // else if (fieldName === 'headOfficePostalCode') {

    //   let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{5,10}(?<!-)$/ ;
    //   if (value.length == '') {
    //     newErrors.headOfficePostalCode = 'Head office postal Code is required.'
    //   }
    //   else if (!pattern.test(value)) {
    //     newErrors.headOfficePostalCode = 'Invalid  postal code';
    //   } else {
    //     newErrors.headOfficePostalCode = ''
    //   }

    // }
    // else if (fieldName === 'headOfficePhone') {
    //   let pattern = /^[0-9+]+$/
    //   if (value === '') {

    //     if (!pattern.test(value)) {
    //       if (value === '') {
    //         newErrors.headOfficePhone = ''
    //         // newErrors.headOfficePhone = 'Head office phone number is required.'
    //       }
    //       else {
    //         newErrors.headOfficePhone = 'Only numbers are allowed.';
    //       }
    //     } else {
    //       newErrors.headOfficePhone = ''
    //       // newErrors.headOfficePhone = 'Head office phone number is required.'
    //     }
    //   }
    //   else if (value.length < 10 || value.length > 10) {
    //     if (!pattern.test(value)) {
    //       newErrors.headOfficePhone = 'Only numbers are allowed.';
    //     } else {
    //       newErrors.headOfficePhone = 'Phone number must be at least 10 digits.'
    //     }
    //   }
    //   else if (!pattern.test(value)) {
    //     newErrors.headOfficePhone = 'Only numbers are allowed.';
    //   }
    //   else {
    //     newErrors.headOfficePhone = ''
    //   }

    // }

    // if (fieldName === 'geographicArea') {
    //   newErrors.geographicArea = value === '' ? 'Geographical area  is required.' : '';
    // }
    // if (fieldName === 'category') {
    //   newErrors.category = value === '' ? 'Category  is required.' : '';
    // }
    if (fieldName === 'type') {
      newErrors.type = value === '' ? 'Type  is required.' : '';
    }
    if (fieldName === 'website') {
      let pattern = /^(http:\/\/www.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      if (value.trim() === '') {
        newErrors.website = ' Website is required.';
      } else if (!pattern.test(value)) {
        newErrors.website = 'Please enter a valid URL! Example: http://www.example.com or www.example.com';
      } else {
        newErrors.website = '';
      }
    }
    if (fieldName === 'linkedinUrl') {
      let pattern = /^(https?:\/\/)((www|\w\w)\.)?linkedin\.com\/(([\w]{2,3})?|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
      if (value.trim() === '') {
        newErrors.linkedinUrl = ' Linkedin URL is required.';
      } else if (!pattern.test(value)) {
        newErrors.linkedinUrl = 'Please enter a valid URL! Example: http://nl.linkedin.com/in/name/';
      } else {
        newErrors.linkedinUrl = '';
      }
    }
    


    // else if (fieldName === 'lastFundingCurrency') {
    //   newErrors.lastFundingCurrency = value === '' ? 'Last funding currency  is required.' : '';
    // }



    // else if (fieldName === 'tagline') {
    //   newErrors.tagline = value === '' ? 'Tag line  is required.' : '';
    // }
    // if (fieldName === 'longitude') {
    //   let pattern = /^-?(90(\.0+)?|[1-8]?\d(\.\d{1,6})?)$/;
    //   if (value.trim() === '') {
    //     newErrors.longitude = 'Longitude is required.';
    //   } else if (!pattern.test(value)) {
    //     newErrors.longitude = 'Please enter a valid longitude! Example: 45, -123.45, 180, -180';
    //   } else {
    //     newErrors.longitude = '';
    //   }
    // }
    
    // if (fieldName === 'latitude') {
    //   // Regular expression for latitude: -90 to 90 with optional decimal places (up to 6)
    //   let pattern = /^-?(90(\.0+)?|[1-8]?\d(\.\d{1,6})?)$/;
    //   if (value.trim() === '') {
    //     newErrors.latitude = 'Latitude is required.';
    //   } else if (!pattern.test(value)) {
    //     newErrors.latitude = 'Please enter a valid latitude! Example: 90, -23.45, 45.678';
    //   } else {
    //     newErrors.latitude = '';
    //   }
    // }
    
    // if (fieldName === 'yearFounded') {
    //   newErrors.yearFounded = value === '' ? 'Year founded  is required.' : '';
    // }
    if (fieldName === 'phoneNumber') {
      let pattern = /^[0-9+]+$/
      if (value === '') {

        if (!pattern.test(value)) {
          if (value === '') {
            newErrors.phoneNumber = 'Phone number is required.'
          }
          else {
            newErrors.phoneNumber = 'Only numbers are allowed.';
          }
        } else {
          newErrors.phoneNumber = 'Phone number is required.'
        }
      }
      else if (value.length < 10) {
        if (!pattern.test(value)) {
          newErrors.phoneNumber = 'Only numbers are allowed.';
        } else {
          newErrors.phoneNumber = 'Phone number must be at least 10 digits.'
        }
      }
      else if (!pattern.test(value)) {
        newErrors.phoneNumber = 'Only numbers are allowed.';
      }
      else {
        newErrors.phoneNumber = ''
      }
    }
    setErrors(newErrors);

  }

  const checkNrdNumber = async (nrd) => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/test_firm_unique?NRD=${nrd}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }

      );
      setNrderror('')

    } catch (error) {
      newErrors.nrd = error?.response?.data?.data;
      errors.nrd = error?.response?.data?.data;
      setNrderror(errors.nrd)
      setErrors(newErrors);
      setErrors({
        ...errors
      })

    }
    setErrors(newErrors);


  };


  const handleCountryChange = (value) => {
    provinceApi(value)
    countryName = value?.label;
    formData.country = countryName
    countryValidateField('country', formData?.country);
    setFormData({
      ...formData,
      country: countryName,
      province: '',
      city: '',
    });
    setIsDisabled(false);
    setIsCityDisabled(true);
    stateValidateField('province', stateName);
    // cityValidateField('city', cityName);
    handleAddressChange({ country: value, province: null, city: null });
  };


  const RegionCountryValidateField = (country, value) => {
    if (country === 'region_country') {

      newErrors.regionCountry = value === '' ? 'Country field is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
    
  };

  const handleStateChange = (value) => {
    CityApi(value);
    setFormData({
      ...formData,
      province: value?.label,
      city: '',
    });
    stateName = value?.label;
    setIsDisabled(false);
    setIsCityDisabled(false);
    stateValidateField('province', stateName);
    handleAddressChange({ ...address, province: value, city: null });
  };

  const handleCityChange = (value) => {
    setFormData({
      ...formData,
      city: value?.label,
    });
    cityName = value?.label;
    // cityValidateField('city', value?.label)
    handleAddressChange({ ...address, city: value });
  };

  const countryValidateField = (country, value) => {
    if (country === 'country') {
      newErrors.country = value === '' ? 'Country is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
  };


  const stateValidateField = (fieldName, value) => {
    if (fieldName === 'province') {
      newErrors.province = value === '' ? 'Province is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
  };

  const cityValidateField = (fieldName, value) => {
    if (fieldName === 'city') {
      newErrors.city = value === '' ? 'City is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
  };

  const RegionStateValidateField = (fieldName, value) => {
    if (fieldName === 'region_name') {
      newErrors.reginState = value === '' ? 'Province field is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
  }

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };


  const RegionHandleCountryChange = (value, index) => {
    if (value) {
      setAddressIndex(index);
      resionCountryName = value.name;
      newRegion.region_country = resionCountryName
      RegionCountryValidateField('region_country', newRegion.region_country);
      // setResionCountryCode(value.isoCode);
      setNewRegion({
        ...newRegion,
        region_country: resionCountryName,
        region_name: null,
      });
      reginSetIsDisabled(false);
      RegionStateValidateField('region_name', stateName);
      RegionHandleAddressChange({ country: value, state: null });
    }
  };

  const RegionHandleStateChange = (label) => {
    if (label) {
      setNewRegion({
        ...newRegion,
        region_name: label.name,
      });
      resionStateName = label.name;

      RegionStateValidateField('region_name', resionStateName);
      RegionHandleAddressChange({ ...resionAddress, state: label });
    }
  }

  const RegionHandleAddressChange = (newAddress) => {
    setResionAddress(newAddress);
  };

  // what is the use of this function

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[0-9+]+$/.test(char)) {
      e.preventDefault();
    }
  };

  // what is the use of this function
  const handleKeyOnlyLatter = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[a-zA-Z\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(char)) {
      e.preventDefault();
    }
  };

  // what is the use of this function
  const handleKeyPressLatter = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[a-zA-Z\s]+$/.test(char)) {
      e.preventDefault();
    }
  };


  const listItemRemove = (index) => {
    const newArray = [...regionData];
    newArray.splice(index, 1);
    setRegionData(newArray);
  };




  const validateForm = () => {
    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        validateField(fieldName, formData[fieldName]);
        reginValidateField(fieldName, regioncatgoricesValue);
        countryValidateField(fieldName, formData[fieldName]);
        stateValidateField(fieldName, formData[fieldName]);
        // cityValidateField(fieldName, formData[fieldName]);

      }
    }
    setErrors(newErrors);
  };
  const RegionvalidateForm = () => {
    for (const fieldName in newRegion) {
      if (newRegion.hasOwnProperty(fieldName)) {
        RegionCountryValidateField(fieldName, newRegion[fieldName]);
        RegionStateValidateField(fieldName, newRegion[fieldName]);
      }
    }
    setErrors(newErrors);

  };
  const listAdd = () => {
    RegionvalidateForm();
    newErrors.reginCatagores = newRegion.categories.length === 0 ? 'Categories  is required.' : '';
    if (newRegion.region_country && newRegion.region_name && (newRegion.categories.length > 0)) {
      let arr = [];
      arr.push(newRegion);

      setRegionData([...regionData, ...arr]);
      setNewRegion({
        region_name: '',
        region_country: '',
        categories: []
      })
      selectInputRef.current.clearValue();
      countryInputRef.current.clearValue();
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    checkNrdNumber(NrdVlue);
    newErrors.reginCatagores = '';
    newErrors.reginState = '';
    newErrors.regionCountry = '';
    formData.region = regionData;
    if (!Object.values(newErrors).some((error) => error !== '')) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/create-firm`,
          {
            firm: formData.firm,
            NRD: formData.nrd,
            location: formData.location,
            city: formData.city,
            country: formData.country,
            province: formData.province,
            postal_code: formData.postalCode,
            // head_office_street_name: formData.headOfficeStreetName,
            // head_office_building_name: formData.headOfficeBuildingName,
            // head_office_city: formData.headOfficeCity,
            // head_office_postal_code: formData.headOfficePostalCode,
            // head_office_phone: formData.headOfficePhone,
            number_of_people: parseInt(formData.numberOfPeople),
            // geographic_area: formData.geographicArea,
            // category: formData.category,
            type: formData.FirmType,
            website: formData.website,
            linkedin_url: formData.linkedinUrl,
            firm_resource_url:formData.firmResourceUrl,
            description: formData.description,
            specialities: formData.specialities,
            last_funding_raised: formData.lastFundingRaised,
            last_funding_currency: formData.lastFundingCurrency,
            last_funding_date: formData.lastFundingDate,
            last_funding_type: formData.lastFundingType,
            other_names: formData.otherName,
            tagline: formData.tagline,
            // longitude: formData.longitude,
            latitude: formData.latitude,
            year_founded: parseInt(formData.yearFounded),
            phone_number: formData.phoneNumber,
            regions: formData.region,
            firm_image: file
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response) {
          setRegionData([]);
          const clearedFormData = {};
          for (const key in formData) {
            if (Array.isArray(formData[key])) {
              clearedFormData[key] = [];
            } else {
              clearedFormData[key] = '';
            }
          }
          setFile(null);
          setCurrentPage(totalPages)
          setFormData(clearedFormData);
          toast.success(response?.data?.message);
          setModelShow(false);
        }

        userDatas();
      } catch (error) {
        toast.error(error?.response?.data?.message)
      }
    } else {
      toast.error(`Fill all the required field `);
    }
  };

  const editUser = (e) => {
    setEditOpen(!editOpen);
    setEditId(e);
  };

  useEffect(() => {
    document.title = "PCMA ||  Registered Firms Management";
  }, []);

  useEffect(() => {
    redirectToLogin();
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(setPageNumber(page));
  };


  useEffect(() => {
  }, [currentPage, totalPages]);

  const handleSorting = (event) => {
    if (event === "name") {
      setshortstatus(null)
      setOrderName("name");
      if (sort === null) {
        setSort(0);
        setshortName(0);
        setCurrentPage(1);
      }
      else if (sort === 1) {
        setSort(0);
        setshortName(0);
        setCurrentPage(1);
      }
      else if (sort === 0) {
        setSort(1);
        setshortName(1);
        setCurrentPage(1);
      }
    }
    else if (event === "status") {
      setshortName(null)
      setOrderName("status");
      if (sort === null) {
        setSort(0);
        setshortstatus(0);
        setCurrentPage(1);
      }
      else if (sort === 1) {
        setSort(0);
        setshortstatus(0);
        setCurrentPage(1);
      }
      else if (sort === 0) {
        setSort(1);
        setshortstatus(1);
        setCurrentPage(1);
      }
    }
  };

  const redirectToLogin = () => userData ? (token = userData.Token) : ((token = null), token === null && navigate("/admin/login"));

  useEffect(() => {
    userDatas();
  }, [currentPage, sort]);

  useEffect(() => {
    let timerOut = setTimeout(() => {
      if (render.current !== 0) {
        if (currentPage === 1) {
          userDatas();
        } else {
          setCurrentPage(1);
        }
      }
      render.current++;
    }, 1000);

    return () => clearTimeout(timerOut)
  }, [search])

  const userDatas = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firms/search`, {
        params: {
          page: currentPage,
          search: search,
          order_name: orderName,
          order_type: sort,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response?.data?.message === "Unauthozied User") {
          localStorage.removeItem('loginAdmin');
          navigate("/admin");
          toast.error(`Unauthozied user !`);
        }
        if (response?.data?.message === "No data found") {
          setTotalUsers([]);
          setTotalPages(null);
        } else {
          setTotalUsers(response.data?.data);
          setTotalPages(response.data?.last_page);
        }


        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.message === "No data found") {
          setTotalUsers([]);
          setTotalPages(null);
        }
        if (error?.message === "Request failed with status code 401") {
          localStorage.removeItem("loginAdmin");
          navigate("/admin/login");
        }
      });
  };

  const exportFirmsCSV = async () => {
    setSpinner(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firms/export`,  {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          search: search
        },
      })
      if (response?.data?.message === "Unauthozied user") {
        localStorage.removeItem('login');
        navigate("/");
        toast.error(`Unauthozied user !`);
      }
      let csvResponseData = response?.data;
      const blob = new Blob([csvResponseData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'PCMA Registered Firms Details.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSpinner(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      if (error?.message === 'Request failed with status code 401') {
        localStorage.removeItem("loginAdmin");
        navigate("/admin/login");
        toast.error(`Unauthozied user !`);
      }
      setSpinner(false);
    }

  };


  useEffect(() => {
    if (modelshow === true) {
      setRegionData([]);
      const clearedFormData = {};
      for (const key in formData) {
        if (Array.isArray(formData[key])) {
          clearedFormData[key] = [];
        } else {
          clearedFormData[key] = '';
        }
      }

      const clearedErrors = {};
      for (const key in errors) {
        if (Array.isArray(errors[key])) {
          clearedErrors[key] = [];
        } else {
          clearedErrors[key] = '';
        }
      }
      const clearedRegion = {};
      for (const key in newRegion) {
        if (Array.isArray(newRegion[key])) {
          clearedRegion[key] = [];
        } else {
          clearedRegion[key] = '';
        }
      }
      setotherName('');
      setSpecialitiesVlaue('');
      setFile(null);
      setNewRegion(clearedRegion);
      address.country = null;
      setErrors(clearedErrors);
      setFormData(clearedFormData);
    }
  }, [modelshow])

  const handleClose = () => {
    setModelShow(false);
  };
  const handleShow = () => {
    setModelShow(true);
  };
  const handleClone = (e) => {
    e.preventDefault();
    const newCount = count.slice();
    setIncriment(incriment + 1);
    newCount.push(incriment);
    setCount(newCount);
  };
  const remove = (index) => {
    if (count.length > 1) {
      const newCount = count.slice();
      newCount.splice(index, 1);
      setCount(newCount);
    }
  };

  const deleteFirm = async (id) => {
    try {
      const confirmDeletion = await Swal.fire({
        title: "Delete Firm",
        text: "Do you want to delete this firm? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#D0D5DD",
        confirmButtonColor: "#D92D20",
        confirmButtonText: "Delete",
        iconHtml: '<i className="fas fa-trash-alt"></i>',
        iconColor: "red",
      });
      if (!confirmDeletion.isConfirmed) return;
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firm-delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user !`);
      }
      userDatas();
      if (response.data.message === "Firm deleted successfully") {
        toast.success(response.data.message);
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting Firm:", error);
      toast.error("Something went wrong please try again later.");
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCurrentPage(1);
  };

  // useEffect(()=>{
  //   setCurrentPage(1);
  // },[search])

  const toggleStatus = async (id, currentStatus) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firm-status/${id}`,
        {
          status: !currentStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedStats = totalUsers?.map((user) =>
        user?._id === id ? { ...user, status: !currentStatus } : user
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user !`);
      }
      userDatas();
      toast.success(response?.data?.message);
    } catch (error) {
      console.log("error", error);
    }
  };


  const featuredToggleStatus = async (nrd) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/feature/firm/${nrd}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user !`);
      }
      userDatas();
      toast.success(response?.data?.message);
      setCurrentPage(1);
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message)
      setIsLoading(false);
    }
  };

  // const handleupload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (file.size > 2 * 1024 * 1024) {
  //       toast.error("File size should be under 2MB");
  //       return;
  //     }

  //     const reader = new FileReader();
  //     reader.onload = function (event) {
  //       setFile(event.target.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };



  const handleupload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size
      if (file.size > 2 * 1024 * 1024) { 
        toast.error("File size should be under 2MB");
        fileInputRef.current.value = '';
        return;
      }
  
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        
        img.onload = function () {
          // Check image dimensions
          if (img.width < 150 || img.width > 250 || img.height < 150 || img.height > 250) {
            toast.error("Please upload an image with dimensions between 150px and 220px.");
            fileInputRef.current.value = '';
            return;
          }
  
          // Image meets all criteria
          setFile(event.target.result);
        };
        
        img.src = event.target.result;
      };
      
      reader.readAsDataURL(file);
    }
  };

  

  
  const handleCancel = () => {
    if (file) {
      URL.revokeObjectURL(file);
    }
    setFile(null);
  };

  // const handleSubmit = () => {
  //   setModelShow(false);
  // };


  // const firmDetail = (data) => {
  // setEditOpen(true);
  // if (data) {
  //   setEditId(data?.id);
  //   setNrd(data?.NRD)
  // }

  // navigate(`/admin/firms/${data?.id}/${data?.NRD}`)
  // };

  const adminFirmUpdate = (firmId) => {
    navigate(`/admin/firmupdate/${firmId}`);
  };


  return (
    <>

        <>
          {editOpen ? (
            <>
              <Firmdetail
                editUserId={editId}
                sendEditOpenData={editOpen}
                sendeditOpen={editUser}
                nrdID={nrd}
              />
            </>
          ) : (
            <>
              <Container fluid>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ background: "#137CD9", color: "#fff", borderRadius: "5px", margin: "37px 0 0 0", }}
                  >
                    <h2 className="mb-0 py-2 firmManagement"> Registered Firms Management</h2>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    className="d-flex  justify-content-center justify-content-sm-start py-2 align-items-center ps-0 pe-0 areaFlex"
                  >
                    <div className="admin_user_search searchOutline ps-0 wrapppp adminSearchBarp">
                      <input
                        type="search"
                        className="py-2 adminSearchBar"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearch}

                        onKeyPress={(e) => {
                          if(e.key === 'Enter'){
                            e.preventDefault(); 
                            if (search?.length > 0) {
                              userDatas();
                              e.target.blur();
                            }
                           
                          } 
                         }}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    {/* <div className=" px-3">
                        <button
                          className="reset_button btn"
                          onClick={handlereset}
                        >
                          <i
                            className="fa-solid fa-arrow-rotate-right"
                            style={{ color: "#0F66B4" }}
                          ></i>
                        </button>
                      </div> */}

                    <div className="tooltip-container resetTooltip ms-3 mt-3 mt-md-0">
                      <button
                        className="reset_button btn"
                        onClick={handlereset}
                        style={{ cursor: 'pointer' }}
                      >
                        <i
                          className="fa-solid fa-arrow-rotate-right"
                          style={{ color: "#0F66B4" }}
                        ></i>
                      </button>
                      <div className="tooltip-text">Reset</div>
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    className="p-2 d-flex justify-content-sm-end justify-content-center align-items-center"
                  >                  
                    <button className="p-2 adminadd_btn me-3" onClick={handleShow} ><i className="fa-solid fa-circle-plus my-1"></i> Add Reg. Firm</button>
                    {spinner ? (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'not-allowed', opacity: "0.9" }} disabled={spinner} >Loading<i className="fa-solid fa-spinner fa-spin ms-1"></i></button>) :
                      totalUsers?.length === 0 ? (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'not-allowed', opacity: "0.9" }} disabled ><i className="fa-solid fa-download my-1" style={{ color: '#fff', }}></i>Export</button>):
                      (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'pointer' }} onClick={() => { exportFirmsCSV() }}><i className="fa-solid fa-download my-1" style={{ color: '#fff', }}></i>Export</button>)}

                  </Col>
                </Row>
                {isLoading ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
                centered
              />
            </Col>
          </Row>
        </Container>
      ) : (
                <Row>
                  <Col
                    xs={12}
                    style={{
                      background: "#fff",
                      padding: "0",
                      borderRadius: "5px",
                    }}
                    className="py-0 mt-3"
                  >
                    <div className="table-responsive firms_card_box">
                      <Table className="notice_table_body">
                        <thead>
                          <tr className="firm_table_heading">
                            <th
                              style={{
                                backgroundColor: "rgba(221, 235, 242, 1)",
                                borderRadius: "5px 0 0px 0",
                                color: "#137CD9", fontWeight: "700", fontSize: "17px", borderBottom:"0"
                              }}
                              className="ps-5"
                            >
                              FIRM NAME
                              <span
                                style={{ cursor: 'pointer' }}
                                className="ms-1"
                                onClick={() => handleSorting("name")}
                              >
                                {shortName === 0 ? (
                                  <i className="fa-solid fa-arrow-up-a-z"></i>
                                ) : shortName === 1 ? (
                                  <i className="fa-solid fa-arrow-down-z-a"></i>
                                ) : (
                                  <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90"></i>
                                )}
                              </span>
                            </th>
                            <th
                              style={{
                                backgroundColor: "rgba(221, 235, 242, 1)",
                                color: "#137CD9", fontWeight: "700", fontSize: "17px", borderBottom:"0"
                              }}
                              className="text-center"
                            >
                              <p
                                style={{ whiteSpace: "nowrap" }}
                                className="mb-0"
                              >
                                {" "}
                                NRD
                              </p>
                            </th>

                            <th
                              style={{
                                backgroundColor: "rgba(221, 235, 242, 1)",
                                color: "#137CD9",
                              }}
                              className=""
                            >
                              <p
                                style={{ whiteSpace: "nowrap", textAlign:"center"}}
                                className="mb-0"
                              >
                                {" "}
                                FEATURED
                              </p>
                            </th>
                            <th
                              style={{
                                backgroundColor: "rgba(221, 235, 242, 1)",
                                color: "#137CD9", fontWeight: "700", fontSize: "17px", borderBottom:"0"
                              }}
                              className="text-center"
                            >
                              STATUS
                              <span
                                style={{ cursor: 'pointer' }}
                                className="ms-1"
                                onClick={() => handleSorting("status")}
                              >
                                {shortstatus === 1 ? (
                                  <i className="fa-solid fa-arrow-up"></i>
                                ) : shortstatus === 0 ? (
                                  <i className="fa-solid fa-arrow-down"></i>
                                ) : (
                                  <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90"></i>
                                )}
                              </span>
                            </th>
                            <th
                              style={{
                                backgroundColor: "rgba(221, 235, 242, 1)", borderRadius: "0px 5px 0px 0", color: "#137CD9", fontWeight: "700", fontSize: "17px", borderBottom:"0"
                              }}
                              className="text-center"
                            >
                              ACTIONS
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {totalUsers?.length === 0 ? (
                            <tr>
                              <td colSpan="5" className="text-center">
                                <div className="d-flex align-items-center justify-content-center w-100">
                                  No Data Found
                                </div>
                              </td>
                            </tr>
                          ) : (
                            totalUsers?.map((totaluser, index) => {
                              return (
                                <tr key={index}>
                                  <td className="ps-sm-5 text-center text-sm-start">
                                    {/* <Link
                                      className="me-3"
                                      onClick={() => firmDetail(totaluser._id)}
                                    > */}
                                    {totaluser?.firm}
                                    {/* </Link> */}
                                  </td>
                                  <td className="text-center text-secondary">
                                    {totaluser?.NRD}
                                  </td>

                                  <td className="text-center">
                                    <div className="form-check form-switch d-flex justify-content-center ps-0   font-family: 'Raleway';">
                                      <input
                                        className="form-check-input mx-1"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={totaluser?.featured}
                                        onChange={() =>
                                          featuredToggleStatus(
                                            totaluser.NRD,
                                          )
                                        }
                                      />
                                      {totaluser?.featured ? "Enabled" : "Disabled"}

                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <div className="form-check form-switch d-flex justify-content-center ps-0   font-family: 'Raleway';">
                                      <input
                                        className="form-check-input mx-1"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={totaluser?.status}
                                        onChange={() =>
                                          toggleStatus(
                                            totaluser._id,
                                            totaluser?.status
                                          )
                                        }
                                      />
                                      {totaluser?.status ? "Enabled" : "Disabled"}

                                    </div>
                                  </td>
                                  <td className="text-center ">
                                    <div className="d-flex justify-content-center">
                                      <div>
                                      
                                        <Link
                                          className="me-2"
                                          // onClick={() => firmDetail(totaluser)}
                                          to={`/admin/firms/${totaluser.NRD}`}
                                        >
                                          <i
                                            className="fa-solid fa-eye"
                                            style={{ color: "#0F66B4" }}
                                          ></i>
                                        </Link>
                                      </div>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => adminFirmUpdate(totaluser.NRD) }
                                      >
                                        <i className="fa-solid fa-edit px-2" style={{ cursor: 'pointer', color: '#0F66B4' }} ></i>
                                      </div>

                                      <div className="px-2">
                                        <Link
                                          href=""
                                          style={{ cursor: "not-allowed" }}
                                        // onClick={() => deleteFirm(totaluser.NRD)}
                                        >
                                          <i
                                            className="fa-solid fa-trash-can"
                                            style={{ color: "#FE6464" }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

)}

              </Container>
              <Container fluid className="py-2">
                {totalUsers?.length === 0 ? (
                  <h3> </h3>
                ) : (
                  <Row>

                    <Col
                      sm={12}
                      className="d-flex justify-content-between pagenation_bar mt-3"
                      style={{ padding: 0 }}
                    >
                      <div>
                        <CustomPagination
                          maxWidth={7}
                          total={totalPages}
                          current={currentPage}
                          onPageChange={(page) => handlePageChange(page)}
                        />

                      </div>

                      <div>
                        {totalPages > 3 ?
                          <ul className=" list-unstyled new_side_pagination  justify-content-center mt-3">
                            <li style={{ whiteSpace: 'nowrap' }}>Go to page</li>
                            <li>
                              <label htmlFor="pageNumber">
                                <input
                                  type="number"
                                  name=""
                                  id="pageNumber"
                                  placeholder={currentPage}
                                  onChange={(e) => {
                                    setGoTOPage(e.target.value);
                                  }} />
                              </label>
                            </li>
                            <li>
                              <div onClick={() => { handlePageSearch(goToPage) }}>
                                <i className="fa-solid fa-arrow-right"></i>
                              </div>
                            </li>
                          </ul>
                          : ""}
                      </div>


                    </Col>
                  </Row>
                )}
              </Container>
            </>
          )}
          <Modal size="lg" centeremodald show={modelshow} onHide={handleClose} >
          <Modal.Header className="p-0 m-0" >
        <div className="modal_title text-center fw-bolder d-flex justify-content-between align-items-center w-100 p-3" style={{ backgroundColor: 'rgb(16 104 183)', borderRadius: '6px 6px 0px 0px' }}>
          <h4 className="title mb-0" style={{ color: "#fff" }}>
          Add Registered Firm
          </h4>
          <Button style={{ color: 'black', background: '#aed0f2' }} onClick={handleClose} className="my-2">
            X
          </Button>
        </div>
      </Modal.Header>

            <Modal.Body style={{ maxHeight: '600px', overflowY: 'scroll' }} >

              <Container fluid className="">
                <Row>
      


                  <Col Col lg={12} md={12}>
                    <Form className="row g-y-1 g-x-2">
                      <Col sm={12}>
                        <div className="d-flex mt-3">
                          <div
                            className="firm_box d-flex justify-content-center align-items-center"
                            style={{
                              width: "131px",
                              height: "131px",
                              border: " 2px solid rgba(237, 240, 241, 1)",
                              borderRadius: "8.037px",
                              backgroundColor: "rgba(237, 240, 241, 1)",
                            }}
                          >
                            {file ? (
                              <img
                                className="firm_picture img-fluid"
                                src={file}
                                alt="logo "
                                style={{
                                  width: "131px",
                                  height: "131px",
                                  borderRadius: "8.037px",
                                }}
                              />
                            ) : (
                              <img
                                className="firm_picture img-fluid"
                                src="../asset/photo_camera.png"
                                alt="logo "
                                style={{
                                  borderRadius: "8.037px",
                                  backgroundColor: "rgba(237, 240, 241, 1)",
                                }}
                              />
                            )}
                          </div>
                          <div className="ms-3">
                            <div className="profile mt-4">
                              <p className="text-start fw-bolder mb-0">
                                Profile Image
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-start  mt-2 ">
                              <Button className=" text-white btn_notice cursor-pointer">
                                <div className="uploadBtn position-relative">
                                  <input
                                    type="file"
                                    onChange={(e) => handleupload(e)}
                                    className="position-absolute w-100 h-100 opacity-0 cursor-pointer"
                                    ref={fileInputRef}
                                  />
                                  Upload
                                </div>
                              </Button>

                              <Button
                                className="btn_cancel mx-2"
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>


                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-3">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Firm Name <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                  ${((errors.firm) && (formData.firm === '') || (errors.firm)) && 'error_active'}
                                  ${(((errors.firm === '')) && (formData.firm)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="firm"
                              placeholder="Enter Firm Name"
                              value={formData.firm}
                              onChange={(e) => { handleChange(e) }}
                            />
                            <span style={{ color: "red" }}>
                              {errors.firm}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>

                      <Col xxl={6} md={6} sm={12}>
                        <Form.Group className="Name py-2 mt-3" ml-2>
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Alternate Name
                          </Form.Label>
                          <div className="input_field">
                            <div className=" position-relative">
                              <Form.Control

                                type="text"
                                style={{
                                  borderRadius: "5px",
                                  border: "1.5px solid #E1DEDE",
                                  paddingRight: '33px',
                                }}
                                onChange={otherNameValue}
                                name="otherName"
                                placeholder="Enter Alternate Name "
                                value={otherName}
                              />
                              <i className={`fa-solid fa-circle-plus catgorices_add ${otherName && 'categores_add_animation'}`} onClick={otherNameAdd}></i>
                            </div>
                          </div>
                        </Form.Group>
                        <ul className=" d-flex align-items-center flex-wrap" style={{ gap: '13px' }}>
                          {
                            formData?.otherName?.map((item, index) => {
                              return (
                                <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                  style={{ padding: '2px 38px 2px 15px', backgroundColor: ' #1069b9', color: '#fff', borderRadius: '5px' }}>
                                  {item}

                                  <i className="fa-solid fa-square-xmark fa_circle_xmark_delete" onClick={() => deleteOtherName(index)}></i>
                                </li>
                              )
                            })
                          }

                        </ul>
                      </Col>

                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Firm Type
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                ${((errors.FirmType) && (formData.FirmType === '') || (errors.FirmType)) && 'error_active'}
                                ${(((errors.FirmType === '')) && (formData.FirmType)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="FirmType"
                              placeholder="Firm Type"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.FirmType}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xxl={6} md={6} sm={12}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16px", letterSpacing:"0.16px"}}>
                            NRD No. <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              type="text"
                              maxLength={5}
                              className={`
                                  ${((errors.nrd) && (formData.nrd === '') || (errors.nrd)) && 'error_active'}
                                  ${(((errors.nrd === '')) && (formData.nrd)) && 'sucess_active'}`}
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="nrd"
                              placeholder="Enter NRD Number"
                              onKeyPress={handleKeyPress}
                              onChange={(e) => { handleChange(e) }}
                              value={formData.nrd}
                            />
                            {
                              Nrderror ? <span style={{ color: "red" }}>
                                {Nrderror}
                              </span> : <span style={{ color: "red" }}>
                                {errors?.nrd}
                              </span>

                            }


                          </div>
                        </Form.Group>
                      </Col>




                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Country <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Select
                              className={`
                                    ${(((errors.country) && (formData.country === ''))) && 'error_active'}
                                    ${((errors.country === '') && (formData.country)) && 'sucess_active'}`}
                              id="country"
                              name="country"
                              label="country"
                              options={countries}
                              value={address?.country}
                              placeholder="Select Country"
                              onChange={handleCountryChange}

                            />

                            <span style={{ color: "red" }}>
                              {errors.country}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Province <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Select
                              className={`
                                   ${(((errors.province) && (formData.province === '')) || (errors.province)) && 'error_active'}
                                   ${((errors.province === '') && (formData.province)) && 'sucess_active'}`}
                              id="province"
                              name="province"
                              options={province}
                              value={address?.province}
                              onChange={handleStateChange}
                              placeholder="Select Province"
                              isDisabled={isdisabled}
                            />
                            <span style={{ color: "red" }}>
                              {errors.province}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            City 
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                                <Select
                                  className={`
                                        ${(((errors.city) && (formData.city === '')) || (errors.city)) && 'error_active'}
                                        ${((errors.city === '') && (formData.city)) && 'sucess_active'}`}
                                  id="city"
                                  name="city"
                                  options={city}
                                  value={address?.city}
                                  onChange={handleCityChange}
                                  placeholder="Select city"
                                  isDisabled={iscitydisabled}
                                />
                            
                            <span style={{ color: "red" }}>
                              {errors.city}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16px", letterSpacing:"0.16px"}}>
                            Location 
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" > */}


                            {/* </i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                   ${((errors.location) && (formData.location === '') || (errors.location)) && 'error_active'}
                                   ${(((errors.location === '')) && (formData.location)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="location"
                              placeholder="Enter Location"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.location}
                            />
                            <span style={{ color: "red" }}>
                              {errors.location}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Postal Code
                             {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                  ${((errors.postalCode) && (formData.postalCode === '') || (errors.postalCode)) && 'error_active'}
                                  ${(((errors.postalCode === '')) && (formData.postalCode)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              maxLength={10}
                              name="postalCode"
                              placeholder="Enter Postal Code"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.postalCode}
                            />
                            <span style={{ color: "red" }}>
                              {errors.postalCode}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      {/* <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Head Office Street Name <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.headOfficeStreetName) && (formData.headOfficeStreetName === '') || (errors.headOfficeStreetName)) && 'error_active'}
                                 ${(((errors.headOfficeStreetName === '')) && (formData.headOfficeStreetName)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="headOfficeStreetName"
                              placeholder="Enter Head Office street Name"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.headOfficeStreetName}
                            />
                            <span style={{ color: "red" }}>
                              {errors.headOfficeStreetName}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Head Office Building Name
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.headOfficeBuildingName) && (formData.headOfficeBuildingName === '') || (errors.headOfficeBuildingName)) && 'error_active'}
                                 ${(((errors.headOfficeBuildingName === '')) && (formData.headOfficeBuildingName)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="headOfficeBuildingName"
                              placeholder="Enter Head Office Building Name"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.headOfficeBuildingName}
                            />

                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Head Office City <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.headOfficeCity) && (formData.headOfficeCity === '') || (errors.headOfficeCity)) && 'error_active'}
                                 ${(((errors.headOfficeCity === '')) && (formData.headOfficeCity)) && 'sucess_active'}`}
                              type="text"
                              onKeyPress={handleKeyOnlyLatter}
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="headOfficeCity"
                              placeholder="Enter Head Office City"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.headOfficeCity
                              }
                            />
                            <span style={{ color: "red" }}>
                              {errors.headOfficeCity}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Head Office Postal Code <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.headOfficePostalCode) && (formData.headOfficePostalCode === '') || (errors.headOfficePostalCode)) && 'error_active'}
                                 ${(((errors.headOfficePostalCode === '')) && (formData.headOfficePostalCode)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              maxLength={10}
                              name="headOfficePostalCode"
                              placeholder="Enter Head Office Postal Code"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.headOfficePostalCode}
                            />
                            <span style={{ color: "red" }}>
                              {errors.headOfficePostalCode}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Head Office Phone
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                  ${((errors.headOfficePhone) && (formData.headOfficePhone === '') || (errors.headOfficePhone)) && 'error_active'}
                                  ${(((errors.headOfficePhone === '')) && (formData.headOfficePhone)) && 'sucess_active'}`}
                              type="text"

                              onKeyPress={handleKeyPress}
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="headOfficePhone"
                              placeholder="Enter Head Office Phone"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.headOfficePhone}
                            />
                            <span style={{ color: "red" }}>
                              {errors.headOfficePhone}
                            </span>
                          </div>
                        </Form.Group>
                      </Col> */}
                      {/* <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Geographical Area <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.geographicArea) && (formData.geographicArea === '') || (errors.geographicArea)) && 'error_active'}
                                 ${(((errors.geographicArea === '')) && (formData.geographicArea)) && 'sucess_active'}`}
                              type="text"
                              onKeyPress={handleKeyOnlyLatter}
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="geographicArea"
                              placeholder="Enter Geographical Area"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.geographicArea}
                            />
                            <span style={{ color: "red" }}>
                              {errors.geographicArea}
                            </span>
                          </div>
                        </Form.Group>
                      </Col> */}
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label fw-bolder">
                            Website <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                  ${((errors.website) && (formData.website === '') || (errors.website)) && 'error_active'}
                                  ${(((errors.website === '')) && (formData.website)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="website"
                              placeholder="Enter Website"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.website}
                            />
                            <span style={{ color: "red" }}>
                              {errors.website}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Linkedin URL <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                ${((errors.linkedinUrl) && (formData.linkedinUrl === '') || (errors.linkedinUrl)) && 'error_active'}
                                ${(((errors.linkedinUrl === '')) && (formData.linkedinUrl)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="linkedinUrl"
                              placeholder="Enter Linkedin URL"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.linkedinUrl}
                            />
                            <span style={{ color: "red" }}>
                              {errors.linkedinUrl}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                    <Col lg={6} md={6}>
                      <Form.Group className="Name py-2 mt-2">
                        <Form.Label className="form_label" style={{ fontWeight: "bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing: "0.16px" }}>
                          Firm CSA Link 
                        </Form.Label>
                        <div className="input_field">
                          <Form.Control
                            className={`
                                 ${((errors.firmResourceUrl) && (formData.firmResourceUrl === '') || (errors.firmResourceUrl)) && 'error_active'}
                                 ${(((errors.firmResourceUrl === '')) && (formData.firmResourceUrl)) && 'sucess_active'}`}                           
                            type="text"
                            style={{
                              borderRadius: "5px",
                              border: "1.5px solid #E1DEDE",
                            }}
                            name="firmResourceUrl"
                            placeholder="Enter Firm CSA Link "
                            onChange={(e) => { handleChange(e) }}
                            value={formData?.firmResourceUrl}
                          />
                          <span style={{ color: "red" }}>
                            {errors.firmResourceUrl}
                          </span>
                        </div>
                      </Form.Group>
                    </Col>        
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Description
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.description) && (formData.description === '') || (errors.description)) && 'error_active'}
                                 ${(((errors.description === '')) && (formData.description)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="description"
                              placeholder="Enter Description"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.description}
                            />
                            <span style={{ color: "red" }}>
                              {errors.description}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Specialities
                          </Form.Label>
                          <div className="input_field">
                            <div className=" position-relative">
                              <Form.Control
                                type="text"
                                style={{
                                  borderRadius: "5px",
                                  border: "1.5px solid #E1DEDE",
                                }}
                                name="specialities"
                                placeholder="Enter Specialities"
                                onChange={specialitiesChange}
                                value={specialitiesVlaue}
                              />
                              <i className={`fa-solid fa-circle-plus catgorices_add ${specialitiesVlaue && 'categores_add_animation'}`} onClick={specialitiesAdd}></i>
                            </div>
                          </div>
                        </Form.Group>
                        <ul className=" d-flex align-items-center flex-wrap" style={{ gap: '13px' }}>
                          {
                            formData?.specialities?.map((item, index) => {
                              return (
                                <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                  style={{ padding: '2px 38px 2px 15px', backgroundColor: ' #1069b9', color: '#fff', borderRadius: '5px' }}>
                                  {item}

                                  <i className="fa-solid fa-square-xmark fa_circle_xmark_delete" onClick={() => deleteSpecialities(index)}></i>
                                </li>
                              )
                            })
                          }

                        </ul>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Last Funding Raised
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
    ${((errors.lastFundingRaised) && (formData.lastFundingRaised === '') || (errors.lastFundingRaised)) && 'error_active'}
    ${(((errors.lastFundingRaised === '')) && (formData.lastFundingRaised)) && 'success_active'}`}
                              type="number"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="lastFundingRaised"
                              placeholder="Enter Last Funding Raised"
                              onChange={(e) => handleChange(e)}
                              value={formData.lastFundingRaised}
                              onKeyDown={(e) => {
                                if ((e.key === 'e' || e.key === 'E') || (e.key === '-' && e.target.selectionStart !== 0)) {
                                  e.preventDefault();
                                }
                              }}
                              onInput={(e) => {
                                if (e.target.value.length > 10) {
                                  e.target.value = e.target.value.slice(0, 10);
                                }
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {errors.lastFundingRaised}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Last Funding Currency
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.lastFundingCurrency) && (formData.lastFundingCurrency === '') || (errors.lastFundingCurrency)) && 'error_active'}
                                 ${(((errors.lastFundingCurrency === '')) && (formData.lastFundingCurrency)) && 'sucess_active'}`}
                              type="text"
                              onKeyPress={handleKeyOnlyLatter}
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="lastFundingCurrency"
                              placeholder="Enter Last Funding Currency"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.lastFundingCurrency}
                            />
                            <span style={{ color: "red" }}>
                              {errors.lastFundingCurrency}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label " style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Last Funding Date
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                ${((errors.lastFundingDate) && (formData.lastFundingDate === '') || (errors.lastFundingDate)) && 'error_active'}
                                ${(((errors.lastFundingDate === '')) && (formData.lastFundingDate)) && 'sucess_active'}`}
                              type="date"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="lastFundingDate"
                              placeholder="Enter Last Funding Date"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.lastFundingDate}
                              max={new Date().toISOString().split("T")[0]}
                            />
                            <span style={{ color: "red" }}>
                              {errors.lastFundingDate}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Last Funding Type
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                ${((errors.lastFundingType) && (formData.lastFundingType === '') || (errors.lastFundingType)) && 'error_active'}
                                ${(((errors.lastFundingType === '')) && (formData.lastFundingType)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="lastFundingType"
                              placeholder="Enter Last Funding Type"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.lastFundingType}
                            />
                            <span style={{ color: "red" }}>
                              {errors.lastFundingType}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label "style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Tagline 
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.tagline) && (formData.tagline === '') || (errors.tagline)) && 'error_active'}
                                 ${(((errors.tagline === '')) && (formData.tagline)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="tagline"
                              placeholder="Enter Tagline"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.tagline}
                            />
                            <span style={{ color: "red" }}>
                              {errors.tagline}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      {/* <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Longitude <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.longitude) && (formData.longitude === '') || (errors.longitude)) && 'error_active'}
                                 ${(((errors.longitude === '')) && (formData.longitude)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="longitude"
                              placeholder="Enter Longitude"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.longitude}
                            />
                            <span style={{ color: "red" }}>
                              {errors.longitude}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Latitude <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.latitude) && (formData.latitude === '') || (errors.latitude)) && 'error_active'}
                                 ${(((errors.latitude === '')) && (formData.latitude)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="latitude"
                              placeholder="Enter Latitude"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.latitude}
                            />
                            <span style={{ color: "red" }}>
                              {errors.latitude}
                            </span>
                          </div>
                        </Form.Group>
                      </Col> */}
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label " style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Year Founded
                             {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                   ${((errors.yearFounded) && (formData.yearFounded === '') || (errors.yearFounded)) && 'error_active'}
                                   ${(((errors.yearFounded === '')) && (formData.yearFounded)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              onKeyPress={handleKeyPress}
                              maxLength={4}
                              name="yearFounded"
                              placeholder="Enter Year Founded"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.yearFounded}
                            />
                            <span style={{ color: "red" }}>
                              {errors.yearFounded}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Phone Number <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.phoneNumber) && (formData.phoneNumber === '') || (errors.phoneNumber)) && 'error_active'}
                                 ${(((errors.phoneNumber === '')) && (formData.phoneNumber)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}

                              onKeyPress={handleKeyPress}
                              name="phoneNumber"
                              placeholder="Enter Phone Number"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.phoneNumber}

                              onInput={(e) => {
                                if (e.target.value.length > 10) {
                                  e.target.value = e.target.value.slice(0, 10);
                                }
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {errors.phoneNumber}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      {/* <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Category <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                ${((errors.category) && (formData.category === '') || (errors.category)) && 'error_active'}
                                ${(((errors.category === '')) && (formData.category)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              onKeyPress={handleKeyPressLatter}
                              name="category"
                              placeholder="Enter Category"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.category}
                            />
                            <span style={{ color: "red" }}>
                              {errors.category}
                            </span>
                          </div>
                        </Form.Group>
                    </Col> */}
                    
                    


                      {/* <Col xxl={6} md={6} sm={12}>
                        <Form.Group className="Name py-2 mt-2">
                          <Form.Label className="form_label " style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px",}}>
                          Employee Count
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                 ${((errors.numberOfPeople) && (formData.numberOfPeople === '') || (errors.numberOfPeople)) && 'error_active'}
                                 ${(((errors.numberOfPeople === '')) && (formData.numberOfPeople)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="numberOfPeople"
                              onKeyPress={handleKeyPress}
                              placeholder="Enter Employee Count"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.numberOfPeople}
                            />
                          </div>
                        </Form.Group>

                      </Col> */}

                      {/* 
                          <Col sm={12}>
                           <div>
                             <h5 style={{color:'#1068b7'}} className=" mb-0 d-inline"> <span className=" d-inline-block pb-2 mt-4" style={{ borderBottom: "3px solid #1068b7" }}>Region</span> </h5>
                              <span style={{fontSize:'17px', marginLeft: '12px', color:'#9e9e9e'}}> (Optional) </span>
                           </div>
                         </Col>
                       
                          
                          <Col xxl={6} md={6} sm={12}>
                            <Form.Group className="Name py-2">
                              <Form.Label className="form_label fw-bolder">
                              Country <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                              </Form.Label>
                              <div className="input_field">
                              <Select
                                     className={`
                                    ${(((errors.regionCountry) && (newRegion.region_country == ''))) && 'error_active'}
                                    ${((errors.regionCountry == '') && (newRegion.region_country)) && 'sucess_active'}`}
                                     id="regioncountry"
                                     name="regioncountry"
                                     label="regioncountry"
                                     ref={countryInputRef}
                                     options={resionUpdatedCountries}
                                    //  value={resionAddress?.country}
                                     onChange={(value) => RegionHandleCountryChange(value)} 

                                   />
                                    <span style={{ color: "red" }}>
                                     {errors.regionCountry}
                                </span>
                              </div>
                            </Form.Group>

                          </Col>

                          <Col xxl={6} md={6} sm={12}>
                            <Form.Group className="Name py-2">
                              <Form.Label className="form_label fw-bolder">
                              Province <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                              </Form.Label>
                              <div className="input_field">
                             {
                               <Select
                               className={`
                               ${(((errors.reginState) && (newRegion.region_name == '')) || (errors.reginState)) && 'error_active'}
                               ${((errors.reginState == '') && (newRegion.region_name)) && 'sucess_active'}`}
                                id="reginState"
                                name="reginState"
                                ref={selectInputRef}
                                options={resionUpdatedStates}
                                onChange={RegionHandleStateChange}  
                                isDisabled={reginIsdisabled}   
                              />
                             }
                                   <span style={{ color: "red" }}>
                                     {errors.reginState}
                                </span>
                              </div>
                            </Form.Group>

                          </Col>

                          <Col xxl={6} md={6} sm={12}>
                            <Form.Group className="Name py-2">
                              <Form.Label className="form_label fw-bolder">
                              Categories  <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                              </Form.Label>
                              <div className="input_field">
                                <div className=" position-relative">
                                <Form.Control
                                 className={`
                                 ${(((errors.reginCatagores) && (newRegion.categories.length > 0)) || (errors.reginCatagores)) && 'error_active'}
                                 ${((errors.reginCatagores == '') && (newRegion.categories.length > 0)) && 'sucess_active'}`}
                                  type="text"
                                  style={{
                                    borderRadius: "5px",
                                    border: "1.5px solid #E1DEDE",
                                    paddingRight:'33px',
                                  }}
                                  onChange={reginCategoresData}
                                  name="reginCatagores"
                                  placeholder="Enter Categories"
                                  value={regioncatgoricesValue}
                                />
                                <i className={`fa-solid fa-circle-plus catgorices_add ${regioncatgoricesValue && 'categores_add_animation'}`} onClick={reginCategoresAdd}></i>
                                </div>
                                <span style={{ color: "red" }}>
                                     {errors.reginCatagores}
                                </span>
                              </div>
                            </Form.Group>
                            <ul className=" d-flex align-items-center flex-wrap" style={{ gap:'13px'}}>
                              {
                                newRegion?.categories?.map((item, index)=>{
                                  return(
                                   <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index} 
                                   style={{padding: '2px 38px 2px 15px',backgroundColor:' #1069b9',color: '#fff',borderRadius: '5px'}}>
                                    {item}
                                  
                                   <i className="fa-solid fa-square-xmark fa_circle_xmark_delete" onClick={()=>deleteItemCategore(index)}></i>
                                   </li>
                                  )
                                })
                              }
                              
                             </ul>
                          </Col>
                          <Col xxl={6} md={6} sm={12}></Col>
                           <Col  sm={12} className="mt-4">
                             <button type="button" className="region_add" onClick={listAdd}>Region Add</button>
                          </Col> 
                      
                       {
                        regionData?.length> 0 && <Col sm={12}>
                           <div className=" table-responsive mt-2">
                            <table className=" table table-striped w-100">
                              <thead>
                                <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Country </th>
                                <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Province </th>
                                <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Categories </th>
                                <th className="px-3 text-center" style={{minWidth:'100px', fontSize:'15px'}}>delete</th>
                              </thead>
                              <tbody>
                              {
                                regionData?.map((item, index)=>{
                                  return(
                                <tr  key={index}>
                                  <td className="px-3" style={{fontSize:'15px'}}> {item.region_country}</td>
                                  <td className="text-center px-3" style={{fontSize:'15px'}}> {item.region_name}</td>
                                  <td className="text-center px-3" style={{fontSize:'15px'}}>   {item.categories.join(', ')}</td>
                                  <td className="text-center px-3" style={{fontSize:'15px'}}><i className="fa-solid fa-trash" style={{cursor:'pointer', color:'red'}} onClick={(index)=>listItemRemove(index)}></i></td>
                                </tr>
                                )
                              })
                            }
                              </tbody>
                            </table>
                           </div>
                           </Col>
                      }  */}

                    </Form>
                  </Col>
                </Row>





              </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-start border-0">

              <Button
                // disabled={!modelshow}
                style={{ color: !modelshow ? 'red' : '#fff' }}
                className="btn-primary btn_notice"
                onClick={handleSubmit}
              >
                Save
              </Button>
              {/* <Button className="btn_cancel" onClick={handleClose}>
                Cancel
              </Button> */}

            </Modal.Footer>
          </Modal>
        </>
      
    </>
  );
};

export default AdminFirms;
