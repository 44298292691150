import React, { useState, useEffect } from "react";
import "../../styles/RegisterForm.css";
import Select from "react-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import {useDispatch ,useSelector } from 'react-redux';
import { fetchUserData } from '../../redux/slice/userData';

const UserAdd = () => {
  const addUrl = useParams();
  let token=null;
  const dispatch = useDispatch();
  const [org, setOrg] = useState('');
  const userDatas = useSelector((state) => state.user);
  const id =userDatas?._id; 
  let userData = JSON.parse(window.localStorage.getItem("login"));

  useEffect(() => {
    redirectToLogin();
  }, []);

useEffect(() => {
  if(userDatas?.organization){
    setOrg(userDatas?.organization)
 }
}, [userDatas]);

  const redirectToLogin = () => {
        token = userData?.token;
    if (token === null) {
      navigate("/");
    }
  };
  useEffect(() => {
    document.title = "PCMA || Sub Member Information";
  }, []);

  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [pwdType, setPwdType] = useState("password");
  const [confirmpwdType, setConfirmpwdType] = useState("password");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isdisabled, setIsDisabled] = useState(true);
  const [iscitydisabled, setIsCityDisabled] = useState(true);
  const [address, setAddress] = useState({
    country: null,
    state: null,
    city: null,
  });
  const [phonecode, setPhoneCode] = useState("");
  const [isEmailValid, setIsEmailValid] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState('');
  const [countries, setCountries] = useState();
  const [province, setProvince] = useState();
  const [city, setCity] = useState();
  let countryName = "";
  let stateName = "";
  let dase = []
  const capitalizeFirstLetter = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
  const [formData, setFormData] = useState({
    username:'',
    full_name: '',
    email: '',
    organization:org,
    password: '',
    website: '',
    address: '',
    city: '',
    phone_code: '',
    phone: '',
    postal_code: '',
    fax: '',
    company_description: '',
    confirmPassword: '',
    address_contact: '',
    confirmEmail: '',
    province: '',
    country: '',
    gender:''
  });

  const [errors, setErrors] = useState({
    username:'',
    full_name: '',
    email: '',
    organization:'',
    password: '',
    website: '',
    address: '',
    city: '',
    phone: '',
    phone_code: '',
    postal_code: '',
    fax: '',
    company_description: '',
    confirmPassword: '',
    address_contact: '',
    confirmEmail: '',
    Industry_Preferences: '',
    province: '',
    country: '',
  });

  let newErrors = { ...errors };
  let membership = JSON.parse(window.localStorage.getItem("registerOption"));
 


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
      headers: {
        'X-Find': 'Canada',
      },
      
    }).then((res) => {  
      const options = res?.data?.map(province => ({
        value: province._id,
        label: province.name,
        phoneCode: province.phone_code
      }));
      setCountries(options);
    }).catch((error) => {
       console.error(`Error: ${error}`);
    });
   
  }, [addUrl]);

  const provinceApi = (value) => {
    axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
      headers: {
        'X-Id': value.value,
      },
    }).then((res) => {
      const options1 = res?.data?.map(name => ({
        value: name._id,
        label: name.name
      }));
      setProvince(options1);
    }).catch((error) => {
       console.error(`Error: ${error}`);
    });
    
  };

  const CityApi = (value) => {
    axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/city`, {
      headers: {
        'X-Id': value.value,
      },
    }).then((res) => {
      const options1 = res?.data?.map(name => ({
        value: name._id,
        label: name.name
      }));
      setCity(options1);
    }).catch((error) => {
       console.error(`Error: ${error}`);
    });
   
  };
  
 
  
  const openEye = (value) => {
    if (value === 1) {
      setPwdType('text')
    } else if (value === 2) {
      setPwdType('password')
    }

    if (value === 3) {
      setConfirmpwdType('text')
    } else if (value === 4) {
      setConfirmpwdType('password')
    }
  }



  // let regsiter_data = JSON.parse(window.localStorage.getItem("register_data"));
  // let user = regsiter_data?.username;
  // let orgination = regsiter_data?.organizationName;

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    const regex = /^[0-9+]+$/;
    if (!regex.test(char)) {
      e.preventDefault();
    }
  };
  
  const handleKeyPress2 = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    const isValidChar = /^[a-zA-Z\s.]+$/.test(char);
    if (!isValidChar) {
      e.preventDefault();
    }
  };
  
   const handlePaste = (e) => {
     e.preventDefault(); 
    const paste = e.clipboardData.getData('text/plain')
      const name = paste.replace(/[0-9\-.+]/g, '');
    setFormData(prevState => ({
    ...prevState,
    full_name: name, 
  }));
  }

  const preventSpace = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };


  

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      organization: org
    }));
  }, [org]);





  // const updatedCountries = countries
  // .filter(country => !excludedCountryNames?.includes(country.name))
  // .map(country => ({
  //   label: country.name,
  //   value: country.id,
  //   ...country,
  // }));

  // const updatedStates = Object.values(
  //   State.getStatesOfCountry(countryCode)
  // ).map((state) => ({
  //   label: state.name,
  //   value: state.id,
  //   ...state,
  // }));

  const handleCountryChange = (value) => {
    provinceApi(value)
    countryName = value?.label;
    formData.country= countryName;
    setPhoneCode(String(value?.phoneCode)); 
    setFormData({
        ...formData,
        country: countryName,
        province: '',
        city: '',
    });
    countryValidateField('country', formData?.country);
    setIsDisabled(false);
    setIsCityDisabled(true);
    handleAddressChange({ country: value, province: null , city: null});
  };

  // const handleCountryChange = (value) => {
  //   provinceApi(value)
  //   countryName = value?.label;
  //   formData.country= countryName;
  //   setPhoneCode(value.phonecode); 
  //   setFormData({
  //       ...formData,
  //       country: countryName,
  //       province: null, 
  //   });
  //    stateValidateField('province', stateName);
  //    countryValidateField('country', formData.country);
  //   setIsDisabled(false);
  //   handleAddressChange({ country: value, province: null , city: null});
  // };

  const handleStateChange = (value) => {
    CityApi(value);
    setFormData({
      ...formData,
     province: value?.label,
    });
    stateName = value?.label;
    setIsCityDisabled(false);
    stateValidateField('province', stateName);
    handleAddressChange({ ...address, province: value , city: null });
  };

  const handleCityChnage = (value) => {
    setFormData({
      ...formData,
      city: value?.label,
    });
    cityValidateField('city', value?.label);
    handleAddressChange({ ...address, city: value });
  };

  const stateValidateField = (fieldName, value) => {
    if (fieldName === 'province') {
      newErrors.province = value === '' ? 'Province field is required.' : '';
        setErrors(newErrors);
    }
    setErrors(newErrors);
  };

  const cityValidateField = (fieldName, value) => {
    if (fieldName === 'city') {
      newErrors.city = value === '' ? 'City is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
  };

  const countryValidateField = (country, value) => {
    if (country === 'country') {
      newErrors.country = value === '' ? 'Country is required.' : '';
      setErrors(newErrors);
    }
   setErrors(newErrors);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
    passwordhandal(name, value)
    validateField(name, value);
    if (name === 'email') {
      checkEmail(value);
    }
    if (name === "phone") {
      checkPhone(value);
    }
  }
  
  const validateField = (fieldName, value) => {
    if (fieldName === 'name') {
      newErrors.name = value?.trim() === '' ? 'Name is required.' : '';
    }
    else if (fieldName === 'email') {
      newErrors.email =
      value?.trim() === ''? 'Email is required.': !isValidEmail(value)
      ? 'Invalid email address.': '';
    } else if (fieldName === 'confirmEmail') {
      newErrors.confirmEmail =
      value !== formData.email
      ? 'Emails do not match.'
      : value?.trim() === ''
      ? 'Confirm email is required.'
      : '';
    }
    
   else if (fieldName === 'full_name') {
      newErrors.full_name = value?.trim() === '' ? 'Full name is required.' : (value?.length < 4 ? 'Full name should be minimum 4 characters long': (value?.length > 30 ? 'Full name should be maximum 30 characters long' : ''));
    }
   else  if (fieldName === 'address') {
      newErrors.address = value?.trim() === '' ? 'Address is required.' : '';
    }
   else if (fieldName === 'city') {
      newErrors.city = value?.trim() === '' ? 'City is required.' : '';
    }
    else if (fieldName === 'postal_code') {
      let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{5,10}(?<!-)$/ ;
      if (value.length =='') {
        newErrors.postal_code = 'Postal code is required.'
      }
      else if (!pattern.test(value)) {
        newErrors.postal_code = 'Invalid  postal code';
      } else {
        newErrors.postal_code = ''
      }
    }
    else if (fieldName === 'company_description') {
      newErrors.company_description = value?.trim() === '' ? 'Company description is required.' : '';
    }
    else  if (fieldName === 'fax') {
        let pattern = /^[0-9+]+$/;
        if (value.trim() === '') {
          newErrors.fax = ''; 
        } else if (value.length < 10) {
          newErrors.fax = 'Minimum 10 digits';
        } else if (!pattern.test(value)) {
            newErrors.fax = 'Use only numbers or plus';
        } else {
            newErrors.fax = '';
        }
    }
    else  if (fieldName === 'website') { 
      let pattern = /^(http:\/\/www.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      if (value.trim() === '') {
        newErrors.website = ''; 
      } else if (!pattern.test(value)) {
        newErrors.website = 'Please enter a valid URL! Example: http://www.example.com or www.example.com';
      } else {
              newErrors.website = '';
            }
    }
    else if (fieldName === 'phone') {
    
      if(value===''){
        newErrors.phone = 'Phone number is required.';
      }
      else if (value.length < 14) {
        newErrors.phone =  'Phone number minimum 10 digit ';
      }
      else{
        newErrors.phone =  '';
      }
      
    }
    
    else  if (fieldName === 'Industry_Preferences') {
      newErrors.Industry_Preferences = dase.length === 0 ? 'The Industry preference field is required' : '';
    }
    setErrors(newErrors);
  };

  function phoneFormat(input) {
    input = input?.replace(/\D/g, "");
    input = input?.trim();
    input = input?.substring(0, 10);
    var size = input?.length;
    if (size === 0) {
      input = input;
    }
    else if ((size < 4) && (size > 1)) {
      input = "(" + input;
    }
    else if ((size < 7) && (size > 1)) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    }
    else if ((size <= 11) && (size > 1)) {
      input =
      "(" +
      input.substring(0, 3) +
      ") " +
      input.substring(3, 6) +
        "-" +
        input.substring(6, 11);
      }
      return input;
  }
    function phoneCodeFormat(input) {
      input = input?.replace(/\D/g, "");
      input = input.length > 0 && !input.startsWith("+") ? "+" + input : input;
      return input;
    }
    const isValidEmail = (email) => {
     const emailRegex = /^[\w.-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return emailRegex.test(email);
  };

  const checkEmail = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/users-check`,
        {
          email: email,
        }
      );
      if (response.data.message === "Already Exists") {
        setIsEmailValid(response.data.message);
      } else {
        setIsEmailValid("");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const checkPhone = async (phone) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/users-check`,
        {
          phone: phone,
        }
      );
      if (response.data.message === "Already Exists") {
        setIsPhoneValid(response.data.message);
      } else {
        setIsPhoneValid("");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const passwordhandal = (name, value) => {
    if (name === 'password') {
      formData.password = value;
      const isValidPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$_#^~!%*?&])[A-Za-z\d@$!%_#^~*?&]{8,}$/.test(value);
      if (value) {
        newErrors.password = '';
        if (!isValidPassword) {
          newErrors.password = 'Password must be 8 to 20 characters and one Number, one Special character and one Capital letter.';
        }
        else {
          newErrors.password = '';
        }
      } else {
        newErrors.password = 'Password is required';
      }

      if (value) {
        if (formData.confirmPassword === value) { newErrors.confirmPassword = '';}
        else {newErrors.confirmPassword = 'Password does not match'; }
      }
    }
    if (name === 'confirmPassword') {
      formData.confirmPassword = value;
      if (value) {
        if (formData?.password === value) { newErrors.confirmPassword = '';}
        else {newErrors.confirmPassword = 'Password does not match'; }
      }
      else { newErrors.confirmPassword = 'Confirm password is required.' }
    }
  }

const handleCheckboxChangeAll= (event)=>{
  if (event.target.checked===true) {
    setSelectedCheckboxes([
      "Private Placements Mining",
      "Private Placements Oil and Gas",
      "Private Placements - Other",
      "Portfolio Management with Exempt Clients",
      "Mergers and Acquisitions",
      "Private Placements - Real Estate",
      "Distributor of Exempt Investments",
      "Other"
    ]);
    errors.Industry_Preferences=''
  }
  else{
    setSelectedCheckboxes([]);
    errors.Industry_Preferences = 'Industry preference is required'
  }
}
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
       dase = selectedCheckboxes?.includes(value)
        ? selectedCheckboxes.filter((checkbox) => checkbox !== value)
        : [...selectedCheckboxes, value];
        validateField('Industry_Preferences', value);
      setSelectedCheckboxes(dase);
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure you want to cancel?",
      text: "You want to cancel !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0F66B4",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
       navigate("/settings/user-management");
      }

      // if (result.isConfirmed) {
      //   setFormData({
      //     username:'',
      //     full_name: '',
      //     email: '',
      //     organization: '',
      //     password: '',
      //     website: '',
      //     address: '',
      //     city: '',
      //     phone_code: '',
      //     phone: '',
      //     postal_code: '',
      //     fax: '',
      //     company_description: '',
      //     confirmPassword: '',
      //     address_contact: '',
      //     confirmEmail: '',
      //     province: '',
      //     country: '',
      //     gender:''
      //   });
      // }

    });

  };

  const validateForm = () => {
    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        validateField(fieldName, formData[fieldName]);
        passwordhandal(fieldName, formData[fieldName]);
        countryValidateField(fieldName, formData[fieldName]);
        stateValidateField(fieldName, formData[fieldName]);
      }
    }
    if (formData.province === null) {
      newErrors.province = 'Province field is required.' ;
        setErrors(newErrors);
  }
    setSpinner(false);
  };
  const [selectedValues, setSelectedValues] = useState({
    address: '',
    addressContact: '',
    email: '',
    phone: ''
  });

  const [showSelects, setShowSelects] = useState({
    address: false,
    addressContact: false,
    email: false,
    phone: false
  });

  const handleIconClick = (fieldName) => {
    setShowSelects(prevState => ({
      ...prevState,
      [fieldName]: !prevState[fieldName]
    }));
  };

  const handleChanges = (fieldName, event) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [fieldName]: event.target.value
    }));
  };

  const privateFields = [];


  const handleSubmit = async(e) => {
    e.preventDefault();
    setSpinner(true)
    validateForm();

    let country;
    let province;
    if (address.country) {
      country = address.country.name;
    }
    if (address.state) {
      province = address.state.name;
    }

    if (selectedCheckboxes.length === 0) {
      newErrors.Industry_Preferences = 'Industry preference is required'
    }
    else {
      newErrors.Industry_Preferences = ''
    }
    if (country === undefined) {
      country = ''
    }
    if (province === undefined) {
      province = ''
    }

    // if (selectedValues) {
    //   if (selectedValues?.phone && selectedValues.phone.length > 0) {
    //     privateFields.push(selectedValues.phone);
    //   }
    //   if (selectedValues?.address && selectedValues.address.length > 0) {
    //     privateFields.push(selectedValues.address);
    //   }
    //   if (selectedValues?.addressContact && selectedValues.addressContact.length > 0) {
    //     privateFields.push(selectedValues.addressContact);
    //   }
    //   if (selectedValues?.email && selectedValues.email.length > 0) {
    //     privateFields.push(selectedValues.email);
    //   }
    // }
    if(formData?.province == null){
     toast.error("Please select province");
         return false;
    }
    if (!Object.values(newErrors).some((error) => error !== '') && isEmailValid === '' && isPhoneValid === '') {
      setSpinner(true)
      if (formData) {
        try{
            const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/add-members`, {
            name: formData?.full_name,
            email: formData?.email,
            username: formData.username,
            password: formData?.password,
            organization: formData?.organization,
            website: formData?.website,
            address: formData?.address,
            city: formData?.city,
            country: formData?.country,
            province: formData?.province,
            postal_code: formData?.postal_code,
            phone_code: phonecode,
            phone: formData?.phone,
            gender: formData?.gender,
            fax: formData?.fax,
            // company_description: formData.company_description,
            industry_preferences: selectedCheckboxes,
            // membership_type: [{
            //   "price_id": membership.price_id,
            //   "subscription_name": membership.subscription_name,
            //   "price": membership.unit_amount
            // }],
            password_confirmation: formData.confirmPassword,
            address_cont: formData.address_contact,
            // private_fields:privateFields
          },{
            headers: {
              Authorization: `Bearer ${userData?.token}`,
            },
          })
          if (response?.data) {
            toast.success(`${response?.data?.message}`);
            if(userData.token && id){
              let token = userData.token;
              let localUserId = id;
              dispatch(fetchUserData({localUserId, token }));
            }
            
            if(response?.data?.message === "Member added successfully."){
              navigate(`/settings/user-management`);
            }
            setSpinner(false)
          } else {
            toast.success(`${response?.data?.message}`);
          }

        }catch(error){
          toast.error(error?.response?.data?.message)
          setSpinner(false)
        }
      }
    } else {
      setSpinner(false)
      setErrors(newErrors);
    }
  };



  const handleback=()=>{
    navigate(-1)

  }


  return (
    <>
      <Container fluid className="mb-5">
        {/* <Navbar /> */}

        <div className="member-information slider_img_div_box" style={{maxWidth:'1690px', padding:'0px 20px', marginTop: "40px" }}>
          <Row>
            <Col lg={12} md={12} sm={12}>
            <span
                          onClick={handleback}
                          style={{
                            cursor: "pointer",
                            background: "#0F66B4",
                            color: "white",
                            fontWeight: "500",
                            display:'inline-block'
                          }}
                          className=" user_firm_details_back_btn  position-static "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                            />
                          </svg>{" "}
                          Back
             </span>

              <h3 className="py-2">Add Secondary User</h3>
              <Row>
                <Col xxl={12} lg={12} md={12} sm={12}>
                  <p>
                  If you are a corporate member you can add two additional members (3 in total) with your corporate membership.
                   Please look down and under User Management section please click on Additional User section where you can
                    create sub-accounts for each additional corporate member. You can create a new sub-account by completing
                     the registration process by clicking "Add Sub Accounts". Please fill out the following information to create 
                     a Sub Account. Please Note: All information entered below will be visible to site administrators. Please be
                      aware that the process will automatically sign you out, please follow the instructions.
                  </p>
                </Col>
                <Col lg={5} md={12} sm={12}></Col>
              </Row>
              <div className="account-information d-flex align-items-center px-4 mb-3">
                General Information
              </div>
              <Row className="mb-3">
                <Col xxl={4} md={6} sm={12} className="mb-3 mb-md-0">
                  <label htmlFor="name" className="position-relative">
                    Full Name
                    <svg
                      className="ms-1 position-absolute "
                      style={{top:'2px'}}
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                        fill="#F92828"
                      />
                    </svg>
                  </label>
                  <input
                    className={`form-control registration_field
                                     ${(((errors.full_name) && (formData.full_name === '')) || (errors.full_name)) && 'error_active'}
                                     ${((errors.full_name === '') && (formData.full_name)) && 'sucess_active'}`}
                    id="full_name"
                    type="text"
                    name="full_name"
                    autoComplete="off"
                    onPaste={handlePaste}
                    value={capitalizeFirstLetter(formData?.full_name)}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress2}
                    placeholder="Enter Full Name"
                  />
                  <span style={{ color: "red" }}>{errors.full_name}</span>
                </Col>
                <Col xxl={4} md={6} sm={12}>
                  <div className="d-flex align-items-center">
                    <Form.Group className="Name py-2 ">
                      <label htmlFor="name" style={{
                        position: 'relative',
                        top: '-7px',
                      }}>Gender</label>
                      <div className="flex-wrap flex-sm-nowrap " >
                        <div className="form-check form-check-inline ">
                          <input className="form-check-input" type="radio" name="gender" id="inlineRadio1" onChange={handleChange}  value="male" checked={formData?.gender === 'male'} />
                          <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                        </div>
                        <div className="form-check form-check-inline ">
                          <input className="form-check-input" type="radio" name="gender" id="inlineRadio2"  onChange={handleChange} value="female" checked={formData?.gender === 'female'} />
                          <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="gender" id="inlineRadio3" onChange={handleChange} value="others" checked={formData?.gender === 'others'} />
                          <label className="form-check-label" htmlFor="inlineRadio3">Others</label>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <div className="account-information d-flex align-items-center px-4">
                Account Information
              </div>
            </Col>

            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <div
                      className="row g-3 register_memeber_form"
                      // className=" row g-3 account-informations"
                      style={{ padding: "2rem 0rem" }}
                    >
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Username
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="username"
                          className="form-control registration_field"
                          value={formData.username}
                          onChange={handleChange}
                          placeholder="Enter Username"
                          // disabled
                        />
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Password
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className=" position-relative password_eye">
                          <input
                            type={pwdType}
                            id="password"
                            name="password"
                            className={`form-control registration_field
                            ${(((errors.password) && (formData.password === '')) || (errors.password)) && 'error_active'}
                            ${((errors.password === '') && (formData.password)) && 'sucess_active'}`}
                            maxLength={20}
                            value={formData?.password}
                            onChange={handleChange}
                            placeholder="Enter Password"
                            onKeyDown={preventSpace}
                          />
                          {pwdType === "text" ? (
                            <i
                              className="fa-regular fa-eye"
                              onClick={() => openEye(2)}
                            ></i>
                          ) : (
                            <i
                              className=" fa-regular fa-eye-slash"
                              onClick={() => openEye(1)}
                            ></i>
                          )}
                        </div>
                        <span style={{ color: "red" }}>{errors.password}</span>
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Confirm Password
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className=" position-relative password_eye">
                          <input
                            type={confirmpwdType}
                            id="confirmPassword"
                            className={`form-control
                            ${(((errors.confirmPassword) && (formData.confirmPassword === '')) || (errors.confirmPassword)) && 'error_active'}
                            ${((errors.confirmPassword === '') && (formData.confirmPassword)) && 'sucess_active'}`}
                            name="confirmPassword"
                            placeholder="Enter Password Again"
                            maxLength={20}
                            value={formData?.confirmPassword}
                            onChange={handleChange}
                            onKeyDown={preventSpace}
                          />

                          {confirmpwdType === "text" ? (
                            <i
                              className="fa-regular fa-eye"
                              onClick={() => openEye(4)}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye-slash"
                              onClick={() => openEye(3)}
                            ></i>
                          )}
                        </div>
                        <span style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </span>
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Email Address
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className="d-flex align-items-center position-relative" style={{gap:'10px'}}>

                        <input
                          className={`
                          form-control registration_field
                          ${(((errors.email) && (formData.email === '')) || (isEmailValid === 'Already Exists') || (errors.email)) && 'error_active'}
                          ${((errors.email === '') && (formData.email) && (isEmailValid === '')) && 'sucess_active'}`}
                          id="email"
                          type="text"
                          name="email"
                          value={formData?.email}
                          onChange={handleChange}
                          placeholder="Enter Email"
                          />
                          {/* <div className="toggle_globe position-absolute">
                            <i className="fa fa-globe position-relative" aria-hidden="true" onClick={() => handleIconClick('email')}></i>
                            {showSelects.email && (
                              <select onChange={(e) => handleChanges('email', e)} value={selectedValues.email} className="selection">
                                <option value=""><i className="fa fa-globe" aria-hidden="true"></i> Set As Public</option>
                                <option value="email"><i className="fa fa-lock" aria-hidden="true"></i> Set As Private</option>
                              </select>
                          )}
                          </div>                      */}
                        </div>
                        {isEmailValid === "Already Exists" ? (
                          <span className=" mt-2" style={{ color: "red" }}>
                            Email is already exists.
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>{errors.email}</span>
                        )}
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Confirm Email Address
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          className={`
                         form-control registration_field
                         ${(((errors.confirmEmail) && (formData.confirmEmail === '')) || (errors.confirmEmail)) && 'error_active'}
                         ${((errors.confirmEmail === '') && (formData.confirmEmail)) && 'sucess_active'}`}
                          id="confirmEmail"
                          type="text"
                          name="confirmEmail"
                          value={formData?.confirmEmail}
                          onChange={handleChange}
                          placeholder="Enter Email Again"
                          onKeyDown={preventSpace}
                        />
                        <span style={{ color: "red" }}>
                          {errors.confirmEmail}
                        </span>
                      </Col>

                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>

            <Col lg={12} md={12} sm={12}>
              <div className="account-information d-flex align-items-center px-4">
                Professionals Information
              </div>

              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <div
                      className=" row g-3 register_memeber_form"
                      style={{ padding: "2rem 0rem" }}
                    >
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Organization
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          className="form-control registration_field"
                          id="organization"
                          type="text"
                          name="organization"
                          value={formData.organization}
                          onChange={handleChange}
                          placeholder="Enter Organization"
                          disabled
                        />
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name"> Website</label>
                        <input
                         className={`
                         form-control registration_field
                        ${((errors.website) && (formData.website === '') || (errors.website)) && 'error_active'}
                         ${(((errors.website === '')) && (formData.website)) && 'sucess_active'}`}
                          id="Website"
                          type="text"
                          name="website"
                          value={formData?.website}
                          onChange={handleChange}
                          placeholder="Enter Website"
                        />
                        <span style={{ color: "red" }}>{errors.website}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Address
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className="d-flex align-items-center position-relative" style={{gap:'10px'}}>
                        <input
                          className={`
                           form-control registration_field
                           ${(((errors.address) && (formData.address === ''))) && 'error_active'}
                           ${((errors.address === '') && (formData.address)) && 'sucess_active'}`}
                          id="address"
                          type="text"
                          name="address"
                          value={formData?.address}
                          maxLength={50}
                          onChange={handleChange}
                          placeholder="Enter Address"
                          />    
                        {/* <div className="toggle_globe position-absolute">
                            <i className="fa fa-globe position-relative" aria-hidden="true" onClick={() => handleIconClick('address')}></i>
                                {showSelects.address && (
                                  <select onChange={(e) => handleChanges('address', e)} value={selectedValues.address} className="selection">
                                    <option value=""><i className="fa fa-globe" aria-hidden="true"></i> Set As Public</option>
                                    <option value="address"><i className="fa fa-lock" aria-hidden="true"></i> Set As Private</option>
                                  </select>
                                )}                       
                        </div>                           */}
                        </div>

                        <span style={{ color: "red" }}>{errors.address}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name"> Address Cont.</label>
                        <div className="d-flex align-items-center position-relative" style={{gap:'10px'}}>
                        <input
                          className={`
                          form-control registration_field
                          ${formData.address_contact && 'sucess_active'}`}
                          id="address_contact"
                          type="text"
                          name="address_contact"
                          value={formData?.address_contact}
                          onChange={handleChange}
                          placeholder="Enter Address"
                        />
                        {/* <div className="toggle_globe position-absolute">
                            <i className="fa fa-globe position-relative" aria-hidden="true" onClick={() => handleIconClick('addressContact')}></i>
                                {showSelects.addressContact && (
                                  <select onChange={(e) => handleChanges('addressContact', e)} value={selectedValues.addressContact} className="selection">
                                    <option value=""><i className="fa fa-globe" aria-hidden="true"></i> Set As Public</option>
                                    <option value="addressContact"><i className="fa fa-lock" aria-hidden="true"></i> Set As Private</option>
                                  </select>
                                )}                                        
                        </div> */}
                        </div>
                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Country
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <Select
                          className={`
                         ${(((errors.country) && (formData.country ===''))) && 'error_active'}
                         ${((errors.country === '') && (formData.country)) && 'sucess_active'}`}
                          id="country"
                          name="country"
                          label="country"
                          options={countries}
                          value={address?.country}
                          onChange={handleCountryChange}

                        />
                        <span style={{ color: "red" }}>
                          {errors.country}
                        </span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Province
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <Select
                         className={`
                         ${(((errors.province) && (formData.province === '')) || (errors.province)) && 'error_active'}
                         ${((errors.province === '') && (formData.province)) && 'sucess_active'}`}
                          id="province"
                          name="province"
                          options={province}
                          value={address?.province}
                          onChange={handleStateChange}
                          isDisabled={isdisabled}                        
                        />

                        <span style={{ color: "red" }}>
                          {errors.province}
                        </span>

                      </Col>
                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          City / Town
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <Select
                          className={`
                          ${(((errors.city) && (formData.city === '')) || (errors.city) || formData.city === null) && 'error_active'}
                         ${((errors.city === '') && (formData.city)) && 'sucess_active'}`}
                          id="city"
                          name="city"
                          options={city}
                          value={address?.city}
                          onChange={handleCityChnage}
                          isDisabled={iscitydisabled}
                          placeholder="Select City"
                          isSearchable
                        />
                        <span style={{ color: "red" }}>{errors.city}</span>
                      </Col> 

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Postal Code
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                          className={`form-control registration_field
                          ${(((errors.postal_code) && (formData.postal_code ==='')) || (errors.postal_code)) && 'error_active'}
                          ${((errors.postal_code === '') && (formData.postal_code)) && 'sucess_active'}`}
                          id="postal_code"
                          type="text"
                          maxLength={10}
                          name="postal_code"
                          value={formData?.postal_code}
                          onChange={handleChange}
                          placeholder="Enter Postal Code"
                        />

                        <span style={{ color: "red" }}>
                          {errors.postal_code}
                        </span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Phone
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className="d-flex align-content-center">
                          <div className="select_code">
                            <input
                              className="form-control"
                            id="phone_code"
                            type="text"
                            name="phone_code"
                            value={phoneCodeFormat(phonecode)}
                            onChange={handleChange}
                            disabled
                          />
                          </div>
                          <div className="d-flex align-items-center position-relative" style={{gap:'10px', width:'100%'}}>
                          <input
                            className={`
                             form-control ms-1 registration_field
                               ${((errors.phone) && (formData.phone === '') || (isPhoneValid === 'Already Exists')) || (errors.phone) && 'error_active'}
                               ${((errors.phone === '') && (formData.phone) && (isPhoneValid === '')) && 'sucess_active'}
                             `}
                            
                            id="phone"
                            type="text"
                            name="phone"
                            maxLength={14}
                            value={phoneFormat(formData?.phone)}
                            onKeyPress={handleKeyPress}
                            onChange={handleChange}
                            placeholder="Enter Phone Number "
                          />
                            
                          </div>
                        </div>
                        <span style={{ color: "red" }}> {errors.phone_code}</span>
                        {isPhoneValid === "Already Exists" ? (
                          <span className="text-danger mt-2">
                            Phone Number Already Exists. Please choose a
                            different one.
                          </span>
                        ) : (
                          <span className="ms-3" style={{ color: "red" }}>{errors.phone}</span>
                        )}
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name"> Fax</label>
                        <input
                          className={`
                          form-control registration_field
                            ${((errors.fax) && (formData.fax === '') || (errors.fax)) && 'error_active'}
                            ${((errors.fax === '') && (formData.fax)) && 'sucess_active'}
                          `}
                          id="fax"
                          type="text"
                          name="fax"
                          maxLength={10}
                          value={formData?.fax}
                          onKeyPress={handleKeyPress}
                          onChange={handleChange}
                          placeholder="Enter Fax "
                        />
                        <span style={{ color: "red" }}>{errors.fax}</span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Company Description
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <input
                           className={`
                           form-control registration_field
                             ${((errors.company_description) && (formData.company_description === '') || (errors.company_description)) && 'error_active'}
                             ${((errors.company_description === '') && (formData.company_description)) && 'sucess_active'}
                           `}
                          id="company_description"
                          type="text"
                          name="company_description"
                          value={formData?.company_description}
                          onChange={handleChange}
                          maxLength={50}
                          placeholder="Enter Company Description "
                        />

                        <span style={{ color: "red" }}>
                          {errors.company_description}
                        </span>
                      </Col>

                      <Col xxl={4} md={6} sm={12}>
                        <label htmlFor="name">
                          Industry Preferences
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </label>
                        <div className="w-100">
                          <div  className={`
                          input_checkbox form-control  p-2 rounded Preferences_check
                            ${((errors.Industry_Preferences) && (selectedCheckboxes.length === 0)) && 'error_active'}
                            ${((errors.Industry_Preferences === '') && (selectedCheckboxes.length>0)) && 'sucess_active'}
                          `}
                          >
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault1"
                                value="Select All / None"
                                onChange={handleCheckboxChangeAll}
                                checked={selectedCheckboxes?.length >= 8}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault1"
                              >
                                (Select All / None)
                              </label>
                            </div>
                            {[
                              "Private Placements Mining",
                              "Private Placements Oil and Gas",
                              "Private Placements - Other",
                              "Portfolio Management with Exempt Clients",
                              "Mergers and Acquisitions",
                              "Private Placements - Real Estate",
                              "Distributor of Exempt Investments",
                              "Other"
                            ].map((label, index) => (
                              <div className="form-check mb-2" key={index}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={label}
                                  id={`flexCheckChecked${index + 2}`}
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckboxes?.includes(label)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexCheckChecked${index + 2}`}
                                >
                                  {label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                   
                          <span style={{ color: "red" }}>
                            {errors.Industry_Preferences}
                          </span>
                      </Col>
                    </div>
                    <div className="row ">
                      <Col lg={6} md={6} sm={12} className="d-flex">
                        <Button
                          className="submit_btn mx-2"
                          type="submit"
                          disabled={spinner}
                        >
                          Submit{" "}
                          {spinner ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : null}
                        </Button>
                        <Button
                          onClick={handleCancel}
                          className="mx-2"
                          style={{
                            background: "white",
                            color: "#0F66B5",
                            borderColor: " 2px solid #0F66B5",
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
      {/* <Footer Width='1690px' Padding="0px"/> */}
    </>
  );
};

export default UserAdd;
