import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    organizationName: "",
  });

 useEffect(() => {
    document.title = "PCMA || Get Membership";
  }, []);

  const [showModal, setShowModal] = useState(true);
  const [formErrors, setFormErrors] = useState({
    username: "",
    organizationName: "",
  });
  const [isSumbit, setIsSubmit] = useState(false);





  const [isUsernameAvailable, setIsUsernameAvailable] = useState('');
  const handleSuccess = () => {

    setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
    navigate("/register");
  };
  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{2934}-\u{2935}\u{25AA}-\u{25AB}\u{2B06}\u{2194}-\u{21AA}]/gu;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    const filteredValue = trimmedValue?.replace(emojiRegex, '');
    setFormData({
      ...formData,
      [name]: filteredValue,
    });
    validateField(name, value)
  };

  const preventSpace = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    redirectToLogin();
  });

  const redirectToLogin = () => {
    const registerdata = window.localStorage.getItem("registerOption");
    if (!registerdata) {
      navigate('/register');
      window.localStorage.removeItem("register_data");
    }
  };
  



  let newErrors = { ...formErrors }
  const validateField = (fieldName, value) => {

    if (fieldName === 'username'){
      let userRegex = /^(?![0-9]+$)[a-zA-Z0-9\S]{4,20}$/;
      newErrors.username = value?.trim() === '' ? 'Username is required!' : !userRegex?.test(value?.trim()) ? 'Username is not valid! (Username must contains only letters and/or numbers and 4 to 20 characters in length)' : '';
    }
    if (fieldName === 'organizationName') {
      newErrors.organizationName = value?.trim() === '' ? 'Organization name is required!' : (value?.length < 4 ? 'Organization name must be a minimum of 4 characters long!' : '');
    }

    setFormErrors(newErrors)
  }

  useEffect(() => {
    checkUsernameAvailability()
  }, [formData?.username]);

  const checkUsernameAvailability = async (username) => {
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/users-check`,
        {
          "username": formData?.username,
        }
      );

      if (response.data.message === "Already Exists") {
        setIsUsernameAvailable(response.data.message);
      } else {
        setIsUsernameAvailable("");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };


  const validateForm = () => {
    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        validateField(fieldName, formData[fieldName]);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm()
    let data = formData;
    data = JSON.stringify(data);
    if (!Object.values(newErrors).some((error) => error !== '') && isUsernameAvailable === '') {
      localStorage.setItem('register_data', data);
      navigate('/register-form')
    }
    setIsSubmit(true);


  };

  const membership_type = JSON.parse(window.localStorage.getItem("registerOption"));


  return (
    <>
      <div className="main_login_body "style={{minHeight:'100vh'}}>
        <div className="left_side d-flex flex-column justify-content-between">
          <div className="upper-part">
            <h1 className="first-text ">Unleash the Potential</h1>
            <h4 className="second-text ">Where Innovation Meets Capital</h4>
            <p>
              Discover a Vibrant and Dynamic Network of Exempt Market
              Professionals United by Excellence and Innovation.
            </p>
          </div>
          <div className="dashboard-screen-image d-flex justify-content-end">
            {/* <img
              src="./asset/login-image.png"
              alt="pcm"
              className="d-inline-block img-fluid align-text-top"
            /> */}
          </div>
        </div>

        <div className=" right_side d-flex ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className=" d-flex align-items-center justify-content-center">
                  <div className="form_register">
                    <Link to="https://pcma1dev.wpenginepowered.com/">
                      <img
                        src="./asset/logo.png"
                        className="img-fluid"
                        alt=""
                      // style={{ height: "180px", width: "400px" }}
                      />
                    </Link>

                    <h1 className="member-heading pt-5">Get Membership</h1>
                    <Form
                      onSubmit={handleSubmit}
                      className="pt-4 registaer_form"
                    >
                      <Form.Group className="username d-flex flex-column justify-content-around">
                        <Form.Label htmlFor="username" className="fw-bolder">
                          Username
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="username"
                          id="username"
                          maxLength={20}
                          placeholder="Enter Your Username"
                          value={formData?.username}
                          onKeyDown={preventSpace}
                          onChange={handleChange}
                          className={`
                                     ${((formErrors.username) && (formData.username === '') ||(formErrors.username)||(isUsernameAvailable === 'Already Exists'))  && 'error_active'}
                                     ${((formErrors.username === '') && (formData.username) && (isUsernameAvailable === '')) && 'sucess_active'}`}
                        />
                        {isUsernameAvailable === "Already Exists" && (
                          <p className="text-danger mt-2">
                            Username already exists. Please choose a different
                            one.
                          </p>
                        )}
                        <p className="text-danger">{formErrors.username}</p>
                      </Form.Group>
                      <Form.Group className="password d-flex flex-column justify-content-around py-2">
                        <Form.Label htmlFor="password" className="fw-bolder">
                          Organization Name
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="organizationName"
                          id="organizationName"
                          placeholder="Enter Your Organization Name"
                          value={formData.organizationName}
                          onChange={handleChange}
                          className={`
                                     ${((formErrors.organizationName) && (formData.organizationName === '') || (formErrors.username)) && 'error_active'}
                                     ${((formErrors.organizationName === '') && (formData.organizationName)) && 'sucess_active'}`}
                        />
                        <p className="text-danger">
                          {formErrors.organizationName}
                        </p>
                      </Form.Group>
                      <div className="register_login">
                        <Button type="submit">Continue</Button>
                      </div>
                    </Form>
                    {/* <div className="divider d-flex align-items-center my-4">
                      <p className="text-center fw-bold mx-3 mb-0">Or</p>
                    </div>
                    <div className="register_facebook">
                      <Button style={{cursor: 'not-allowed'}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                        ></svg>
                        <span> Continue with Facebook</span>
                      </Button>
                      <br />
                      <Button style={{cursor: 'not-allowed'}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="27"
                          viewBox="0 0 26 27"
                          fill="none"
                        ></svg>
                        <span>Continue with LinkedIn</span>
                      </Button>
                    </div> */}
                    <div className="not-member mt-5 w-100">
                      <p>
                        Already A Member ?
                        <Link to="/" className="text-decoration-none">
                          <span className="join-pcma ms-1">Login Here!</span>
                        </Link>
                      </p>
                    </div>
                    {/* <div className="divider d-flex align-items-center my-4">
                        <p className="text-center fw-bold mx-3 mb-0">Or</p>
                      </div> */}
                    <div className="note mt-5 w-100">
                      <p>
                        Please note that your member ID is your Username. If you
                        don't remember your member ID, please
                        <span>{' '}
                          <Link style={{color:'#0F67B6', textDecoration:"none"}} to="https://pcma1dev.wpenginepowered.com/contact-us/">
                           CONTACT US.
                          </Link>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={showModal}
              centered
              className="centered-modal modal-lg"
            >
              <div className="modal-size  px-3 px-sm-5 py-3 ">
                <Modal.Header className="w-100">
                  <Modal.Title
                    className="modal-title"
                    style={{ width: "450px" }}
                  >
                    Membership Selection
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h6>
                    {membership_type?.subscription_name} — $ {membership_type?.unit_amount}
                  </h6>
                  <p>
                    This membership is for a firm registered as an{" "}
                    {membership_type?.subscription_name} in one or more jurisdictions in Canada.
                  </p>
                </Modal.Body>
                <Modal.Footer className="modal_footer">
                  <Button className="modal-button1" onClick={handleSuccess}>
                    Accept This Membership & Continue
                  </Button>

                  <Button
                    className="modal-button2"
                    style={{ background: "#fff", color: "#1171C6" }}
                    onClick={handleClose}
                  >
                    <i className="fa-solid fa-angles-left"></i>
                    Start Over
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
