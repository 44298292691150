import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import ResponsivePagination from 'react-responsive-pagination';
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';
import CustomPagination from '../../components/pagination/CustomPagination.jsx';

const PromoCode = () => {

    let loginData = JSON.parse(window.localStorage.getItem("loginAdmin"));
    let token = loginData.Token;
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [isLoading, setIsLoading] = useState([false]);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({
        coupon_id: "",
        discount: "",
        name: "",
        status: "",
        max_redemptions: "",
        times_redeemed: "",
        duration_in_months: "",
    });
    const statusText = modelData?.status ? 'Active' : 'Inactive';
    const handleClose = () => setShow(false);
    const handlePageChange = (page) => { setCurrentPage(page); };
    const navigate = useNavigate();

    const dispatch = useDispatch()
    dispatch(setPageNumber(1));

    useEffect(() => {
        document.title = 'PCMA || Promo Code Management';
    }, []);

    useEffect(() => {
        promoCodeApi()
    }, [currentPage])

    const promoCodeApi = async () => {
        setIsLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/view-promo-code`,
            {
                params: {
                    page: `${currentPage}`,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setIsLoading(false);
                if (response?.data?.data?.data.length === 0) {
                    setCurrentPage(response?.data?.data?.last_page);
                }
                setData(response?.data?.data?.data);
                setTotalPages(response?.data?.data?.last_page);
                if (response?.data?.message === "Unauthozied User") {
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin");
                    toast.error(response?.data?.message);
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log(error);
            });

    };

    const handleDelete = async (id) => {
        try {
            const { isConfirmed } = await Swal.fire({
                title: "Delete Subscription",
                text: "Do you want to delete this Subscription? This action cannot be undone.",
                icon: "warning", showCancelButton: true,
                cancelButtonColor: "#D0D5DD",
                confirmButtonColor: "#D92D20",
                confirmButtonText: "Delete",
                iconHtml: '<i class="fa-solid fa-trash"></i>', iconColor: "red"
            });
            if (!isConfirmed) return;
            const response = await axios.delete(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/delete-promo-code/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            promoCodeApi();
            // setCurrentPage(1);
            if (response?.data?.status === true) {
                toast.success(response?.data?.message);
            }
            if (response?.data?.message === "Unauthozied User") {
                localStorage.removeItem('loginAdmin');
                navigate("/admin");
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error("Something went wrong please try again later.");
        }
    };

    const handleModel = (data) => {
        setShow(true);
        setModelData({
            name: data?.name,
            discount: data?.discount,
            status: data?.status,
            coupon_id: data?.coupon_id,
            max_redemptions: data?.max_redemptions,
            times_redeemed: data?.times_redeemed,
            duration_in_months: data?.duration_in_months,
        });
    };

    return (
        <>

            {isLoading ? (
                <>
                    <Container fluid className="loader">
                        <Row
                            className="g-2 pb-3 d-flex justify-content-center align-content-center "
                            style={{ height: "79vh" }}
                        >
                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="d-flex justify-content-center align-content-center"
                            >
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#0f66b4"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                />
                            </Col>
                        </Row>
                    </Container>

                </>) :
                (
                    <>
                        <div className="row pt-4 mb-4">
                            <div className="col-12 mt-3">
                                <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' }}>
                                    <h4 className="mb-0 py-2 px-3">Promo Code Management</h4>
                                </div>
                            </div>
                            <div className="col-12 mb-2 mt-3">
                                <div className=' d-flex align-items-center justify-content-end  area-wrappingBtn'>
                                     <Link to='/admin/promo-code-add' className="p-2 adminadd_btn text-decoration-none"><i className="fa-solid fa-circle-plus my-1"></i>Add Promo Code</Link>
                                    {/* <Link to='/admin/promo-code-add' className="subcription_add_new" >Add Promo Code <i className="fa-solid fa-plus"></i></Link> */}
                                </div>
                            </div>
                            <div className="col-12 py-2" 
                            // style={{ background: "#fff", border: "2px solid rgba(15, 102, 180, 0.2)", borderRadius: "5px" }}
                            >
                                <div className="subcription_table">
                                    <div className=" table-responsive">
                                        <Table className="w-100">
                                            <thead>
                                                <tr>
                                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px" , borderBottom:"0"}}>Promo Code Name</th>
                                                    <th className="text-start ps-0" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px" , borderBottom:"0"}}>Discount</th>
                                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px" , borderBottom:"0"}}>Status</th>
                                                    <th className="text-center" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px" , borderBottom:"0"}}>Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data?.map((item) => (
                                                    <React.Fragment key={item?._id}>
                                                        <tr>
                                                            {/* <td><input type="checkbox" name="" id="" /></td>   */}
                                                            <td onClick={() => { handleModel(item) }} style={{ cursor: 'pointer' }}><p className='fw-bolder mb-0'>{item?.name}</p></td>
                                                            <td  >{item?.discount}%</td>
                                                            <td ><span style={{ color: item?.status ? 'green' : 'red', }}>{item?.status ? 'Active' : 'Inactive'}</span></td>
                                                            <td>
                                                                <div className=" subcription_table_btn d-flex align-items-center justify-content-center p-0 m-0">
                                                                    <button onClick={() => handleDelete(item?._id)}>
                                                                    <i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: '#FF6969' }} ></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </tbody>

                                        </Table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Container fluid className="py-2">
                            {data?.length === 0 ? (
                                <h3> </h3>
                            ) : (
                                <Row>

                                    <Col
                                        sm={12}
                                        className="d-flex justify-content-start"
                                        style={{ padding: 0 }}
                                    >

                                        <CustomPagination
                                         maxWidth={7}
                                            total={totalPages}
                                            current={currentPage}
                                            onPageChange={(page) => handlePageChange(page)}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Container>
                        {/* view promo code */}
                        <Modal show={show} onHide={handleClose} backdrop="true" keyboard={false} size="lg" centered>
                            <Modal.Header className="border-0">
                                <Modal.Title>View Promo Code</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='contanier-fluid'>
                                    <div className='row'>
                                        <div className='col-12 px-3'>
                                            <form className="row g-3 promocode_form mt-1">
                                                <div className="col-sm-6">
                                                    <label className="form-label mb-3">Promo Code Name</label>
                                                    <input type="text" value={modelData?.name} className="form-control mb-2 py-2" disabled />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label mb-3">Coupon ID</label>
                                                    <input type="text" value={modelData?.coupon_id} className="form-control mb-2 py-2" disabled />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label mb-3">Discount </label>
                                                    <input type="text" value={modelData?.discount + '%'} className="form-control mb-2 py-2" disabled />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label mb-3">Duration In Months</label>
                                                    <input type="text" value={modelData?.duration_in_months} className="form-control mb-2 py-2" disabled />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label mb-3">Maximum Redemptions</label>
                                                    <input type="text" value={modelData?.max_redemptions} className="form-control mb-2 py-2" disabled />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label mb-3">Number of Time Redeemed</label>
                                                    <input type="text" value={modelData?.times_redeemed} className="form-control mb-2 py-2" disabled />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label mb-3">Status</label>
                                                    <input type="text" value={statusText} className="form-control mb-2  py-2" disabled />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="border-0">
                                <button className="subcription_add_new fw-bold px-4" style={{ background: 'transparent', color: '#0F66B5', border: '2px solid #0F66B5' }} onClick={handleClose} >Close</button>
                            </Modal.Footer>
                        </Modal>
                    </>)}
        </>
    )
}

export default PromoCode;
