import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button  } from 'react-bootstrap';
import { BallTriangle } from 'react-loader-spinner';
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';
import { DateRangePicker } from 'react-date-range';
import { FaCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const Dashboard = () => {
  const navigate = useNavigate();
  const [totalFirms, setTotalFirms] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [notice, setNotice] = useState([]);
  const [firms, setFirms] = useState([]);
  const [users, setUser] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection'
  });
  const [showModal, setShowModal] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [userGraph, setUserGraph] = useState([]);
  const [firmGraph, setFirmGraph] = useState([]);
  const specificDate = new Date(); 
  const TodayDate = format(specificDate, "do MMMM yyyy");
  console.log(TodayDate);

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setDateSelected(true);
  };

  const handleApply = () => {
    fetchData();
    fetchGraphsData();
    setShowModal(false);
  };


  const [reset, setReset] = useState(false);

  const handleReset=()=>{
      setSelectionRange({
        startDate: null,
        endDate: null,
        key: 'selection'
      });
    setDateSelected(false);
    setReset(true);
  // }
  }


  const formatDate = (date) => {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const data = firmGraph?.map(item => item.count);
  const userdatas = userGraph?.map(item => item?.count);
  const usermonth = userGraph?.map(item => item?.month);
  const useryear = userGraph?.map(item => item?.year);
  const userDate = userGraph?.map(item => `${item?.month} ${item?.year}`);
  const firmDate = firmGraph?.map(item => `${item?.month} ${item?.year}`);
  

  const combinedDates = [...new Set([...userDate, ...firmDate])].sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });

  const chartOptions = {
    chart: {
      type: 'areaspline',
      style: {
        fontFamily: 'Lato'
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: combinedDates,
      // crosshair: true,
      gridLineWidth: 1
    },
    
    yAxis: {
      labels: {
        enabled: true
      },
      title: {
        text: "No. Firms and PCMA Members"
      },
      gridLineWidth: 0,
      min: 0
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      x: 0,
      y: 0,
      itemStyle: {
        fontFamily: 'Lato'
      }
    },
    series: [
      {
        name: 'Firm',
        data: data,
        color: '#0F66B4',
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(15, 102, 180, 1)'],
            [1, 'rgba(15, 102, 180, 0.1)']
          ]
        }
      },
      {
        name: 'PCMA Members',
        data: userdatas,
        color: '#DE8F00',
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(222, 143, 0, 1)'],
            [1, 'rgba(222, 143, 0, 0.1)']
          ]
        }
      }
    ]
  };




  // useEffect(() => {
  //   document.title = "PCMA || Admin Dashboard";
  // }, []);

  const userData = JSON.parse(window.localStorage.getItem("loginAdmin"));
  let token = '';

  useEffect(() => {
    redirectToLogin();
  }, [userData]);

  const redirectToLogin = () => {
     token = userData?.Token || null;
  
    if (token === null) {
      navigate('/admin/login');
    }
  };
  

  useEffect(() => { 
    fetchData()
  }, []);

  useEffect(() => {
    fetchGraphsData()
  }, []);

  const fetchData = async () => {
    setLoader(true);
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/dashboard`, {
          params: {
            start_date: formatDate(selectionRange?.startDate),
            end_date: formatDate(selectionRange?.endDate),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if(response?.data?.message === "Unauthozied User"){
          localStorage.removeItem('loginAdmin');
          navigate("/admin");
          toast.error(`Unauthozied user !`);
        } 
        setTotalFirms(response?.data?.total_firms);
        setTotalUsers(response?.data?.total_users);
        setNotice(response?.data?.notice);
        setFirms(response?.data?.firms_data);
        setUser(response?.data?.users);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        if (error?.message === 'Request failed with status code 401') {
          localStorage.removeItem('loginAdmin');
          navigate('/admin/login')
        }
      }
    }


  };
 
  const fetchGraphsData = async () => {
    // setLoader(true);
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/dashboard/graph`, {
          params: {
            start_date: formatDate(selectionRange?.startDate),
            end_date: formatDate(selectionRange?.endDate),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response?.data?.message === "Unauthozied User") {
          localStorage.removeItem('loginAdmin');
          navigate("/admin");
          toast.error(`Unauthozied user !`);
        }
        setUserGraph(response?.data?.users);
        setFirmGraph(response?.data?.firms_data);
        // setLoader(false);
      } catch (error) {
        // setLoader(false);
        if (error?.message === 'Request failed with status code 401') {
          localStorage.removeItem('loginAdmin');
          navigate('/admin/login')
        }
      }
    }


  };


  const dispatch = useDispatch()
  

  dispatch(setPageNumber(1));

  const handleFirmStatusToggle = async (firmId, status) => {
    const valueofStatus = !status; 
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firm-status/${firmId}`,
        { status: valueofStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error("Unauthorized user!");
      } else if (response?.data?.message === "Firm updated successfully") {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleSwitchToggle = async (firmId, status) => {
    const valueofStatus = !status; 
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-status/${firmId}`,
        {
          "status": valueofStatus
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if(response?.data?.message === "Unauthozied User"){
        localStorage.removeItem('loginAdmin');
        navigate("/admin");
        toast.error(`Unauthozied user`);
      }
      if (response.data.message === "User updated successfully") {
        toast.success(`${response.data.message}`)
        fetchData();
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {loader ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className='pb-3'>
          <Row className='mb-4'>
            <Col lg="12 ps-0" className='areassss'>
              <div className="adminDashboardTop ">
                <Row className='align-items-center'>
                  <Col xs="auto" className='me-auto'>
                    <h5 className='mb-0'>Welcome to <span>PCMA Admin Dashboard</span></h5>
                  </Col>
                  <Col xs="auto">
                  <Col xs="auto">
                    <Button variant="transparent" className='border-0' onClick={() => setShowModal(true)}>
                      {dateSelected ? <><FaCalendarAlt />  {formatDate(selectionRange?.startDate)} - {formatDate(selectionRange?.endDate)} </> : (
                        <>
                          <FaCalendarAlt /> Select Date
                        </>
                      )}
                    </Button>
                        <Modal show={showModal} centered size="" onHide={() => setShowModal(false)} className='dateRangeModal'>
                          <Modal.Header closeButton>
                            <Modal.Title>Select Date Range</Modal.Title>
                          </Modal.Header>
                          <Modal.Body className='d-flex justify-content-center'>
                            <DateRangePicker
                              ranges={[selectionRange]}
                              onChange={handleSelect}
                              maxDate={new Date()}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="btn_close" onClick={() => setShowModal(false)}>
                              Close
                            </Button>
                            <Button className='btn_apply' onClick={handleApply}>
                              Apply
                            </Button>

                            <div className="tooltip-container">
                              <button
                                className="reset_button btn"
                                onClick={handleReset}
                                style={{ cursor: 'pointer' }}
                              >
                                <i
                                  className="fa-solid fa-arrow-rotate-right"
                                  style={{ color: "#0F66B4" }}
                                ></i>
                              </button>
                              <div className="tooltip-text">Reset</div>
                            </div>

                          </Modal.Footer>
                        </Modal>
                  </Col>
                  </Col>
                  {/* <Col xs="auto" data-bs-theme="dark">
                    <Row className='g-3 align-items-center'>
                        <Col xs="auto">
                          <label htmlFor=""><span className='me-3'>|</span> Sort by:</label>
                        </Col>
                        <Col xs="auto">
                          <Form.Select aria-label="Default" className='border-0 bg-transparent  shadow-none'>
                            <option className='text-dark'>Default</option>
                            <option value="1" className='text-dark'>One</option>
                            <option value="2"  className='text-dark'>Two</option>
                            <option value="3"  className='text-dark'>Three</option>
                          </Form.Select>
                        </Col>
                    </Row>
                  </Col> */}
                </Row>
              </div>
              </Col>
              <Col sm={12} className='mt-3'>
                <div className='today_date d-flex align-items-center justify-content-sm-end justify-content-center p-1'>
                  <h1 className='mb-0'>Today’s Date -</h1>
                  <p className='mb-0'>{TodayDate}</p>
                </div>
              </Col>
            <Col
              xs={12}
              xxl={4}
              xl={6}
              lg={6}
              md={12}
              className="g-2 user-component-1 order-lg-1 order-1 ps-0"
            >
              <div className="">
                <div className="user-component-1-a mb-4">
                  <div className="boxArea"
                    style={{
                      background: "#fff",
                      padding: "1rem",
                      height: "50%"
                    }}
                  >
                    <div className="d-flex justify-content-between ">
                      <h2 className="wrapTitle">Total Registered Firms</h2>
                      <p className="view-all">
                        {" "}
                        <Link to="/admin/firms">View All</Link>
                      </p>
                    </div>
                    <div style={{ color: "#0F66B4", fontSize: "40px", fontWeight: "800" }}>
                      <p className="dash_frem_p">{totalFirms}</p>
                    </div>
                    
                  </div>
                  <div className="boxArea2"
                    style={{
                      background: "#fff",
                      padding: "1rem",
                      height: "50%",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <h2 className="wrapTitle">Total PCMA Members</h2>
                      <p className="view-all">
                        <Link to="/admin/pcmamembers">View All</Link>
                      </p>
                    </div>
                    <div style={{ color: "#0F66B4", fontSize: "40px" , fontWeight: "800"}}>
                      {" "}
                      <p className="dash_frem_p">{totalUsers}</p>{" "}
                    </div>
                  </div>
                </div>
                <div className="user-component-1-a mb-2" style={{maxHeight: '100%'}}>
                  <div className="boxArea"
                    style={{
                      background: "#fff",
                      padding: "1rem",
                      // height: "50%",
                      // marginTop: "20px",
                      borderRadius: '10px',
                      maxHeight: 'auto',
                      height:'501px',
                    }}
                  >
                    <div className="position-relative">
                      {/* <Row className='g-3 align-itmes-center'>
                        <Col xs="auto" className='me-auto'>
                          <h6 className="addedUser mb-0" style={{ fontSize: 20 }}>
                            Overview
                          </h6>
                        </Col>
                        <Col xs="auto">
                          <Button variant="transparent" className='border-0 p-0' onClick={() => setShowModal(true)}>
                            {dateSelected ? <><FaCalendarAlt />  {formatDate(selectionRange.startDate)} - {formatDate(selectionRange.endDate)} </> : (
                              <>
                                <FaCalendarAlt /> Select Date
                              </>
                            )}
                          </Button>
                        </Col>
                      </Row> */}
                      <h6 className="addedUser mb-2" style={{ fontSize: 20 }}>
                        Overview
                      </h6>
                      {/* <Button variant="transparent" className='border-0 p-0' onClick={() => setShowModal(true)} disabled>
                        {dateSelected ? <><FaCalendarAlt />  {formatDate(selectionRange.startDate)} - {formatDate(selectionRange.endDate)} </> : (
                          <>
                            <FaCalendarAlt /> Select Date
                          </>
                        )}
                      </Button> */}
                    </div>

                   
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptions}
                    />
                  </div>
                </div>
              </div>
              </Col>
            <Col md={12} xxl={4} xl={6} lg={6} className='g-2 order-lg-2 order-3' >
              <div className='user-component-2 h-auto' style={{ background: '#fff', borderRadius: '5px', maxHeight: 'auto' }}>
                <div className='d-flex justify-content-between px-2 pt-3' >
                    <h6 className="addedUser" style={{}}>Recently Added Registered Firms</h6>
                  <p className='view-all'><Link to="/admin/firms">View All</Link></p>
                </div>
                <div className='dashboard_firms_card_box ' style={{maxHeight:'758px'}}>
                  <table className='w-100'>
                    <tbody>
                      {firms?.length ==0 ? <p className='px-4'>No Data Found</p> :''}
                      {firms?.map((firm, index) => {
                        return (
                            <tr key={index}>
                              <td> <div className="firms_card  py-1 px-2 px-lg-0">
                                <Container fluid className='my-3'>
                                  <Row>
                                    <Col sm={4}>
                                    {firm?.firm_image ?
                                      <img src={firm?.firm_image} alt="" /> :
                                      <img src="../asset/firmdemoimage.png" alt="" />
                                    }
                                   
                                    
                                    </Col>
                                    <Col sm={8} className="ps-0">
                                      <h3>{firm?.firm}</h3>
                                      <p>
                                        {firm?.other_names &&
                                          firm?.other_names?.length > 0 ? (
                                          <>
                                            Alternative Name:{" "}
                                            {firm?.other_names.join(", ")}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckChecked"
                                          checked={firm.status}
                                          onChange={() =>
                                            handleFirmStatusToggle(
                                              firm?._id,
                                              firm.status
                                            )
                                          }
                                      />
                                      <div >
                                          {firm?.status ? "Enabled" : "Disabled"}
                                       </div>
                                        
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                              </div></td>
                            </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              </Col>
              
            <Col
              xs={12}
              xxl={4}
              xl={12}
              lg={12}
              md={12}
              className="g-2 order-lg-3 order-2"
            >
              <div className="h-100">
                <div
                  className="user-component-3 h-auto mb-3"
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: "5px",
                    // maxHeight:'821px'
                  }}
                >
                  <div className="d-flex justify-content-between ">
                      <h6 className="addedUser" style={{ fontSize: "20px" }}>Recently Added PCMA Members</h6>
                    <p className="view-all">
                      <Link to="/admin/pcmamembers">View All</Link>
                    </p>
                  </div>
                  <div className="firms_card_box mt-2" style={{maxHeight:'100%',height:'300px'}}>
                    <table className="w-100 notice_table">
                      <thead style={{ background: "#DDEBF2" }}>
                        <tr className="d-flex justify-content-between ">
                          {/* <th className="mx-2">NAME</th>
                          <th className="mx-2 status_hide">STATUS</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {users?.length ==0 ? <p className='px-4'>No Data Found</p> :''}
                      {/* {users?.slice(0, 3).map((user, index) => { */}
                      {users?.map((user, index) => {
                        return (
                          <tr key={index} className="d-flex add_user_responsive justify-content-between">
                            <td className="py-3 mx-2">
                              <div className="user_image d-flex align-items-center">
                                {user?.profile_picture ? (
                                  <img src={user?.profile_picture} alt="" />
                                ) : (
                                  <img src="../asset/users.png" alt="" />
                                )}
                                <span>{user?.name}</span>
                              </div>
                            </td>
                            <td className="py-3 mx-2">
                              <div className='h-100 d-flex align-items-center justify-content-center add_responsive_div'>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={user.status}
                                    onChange={() =>
                                      handleSwitchToggle(user?._id, user.status)
                                    }
                                  />
                                  <div className='form-label'>
                                    {user?.status ? "Enabled" : "Disabled"}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="user-component-1-b h-auto"
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: "5px",
                    maxHeight:'419px',
                  }}
                >
                  <div className="d-flex justify-content-between border-bottom">
                    <h3 className="noticeArea">Notice</h3>

                    <p className="view-all">
                      <Link to="/admin/notice">View All</Link>
                    </p>
                  </div>
                  <div className="notice_card_box">
                    {/* <table className="w-100 notice_table">
                      <thead>
                        <tr>
                          <th style={{ background: "#DDEBF2", zIndex:'1' }}>Date</th>
                          <th style={{ background: "#DDEBF2" , zIndex:'1' }}>Notice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notice?.length >0 ? 
                        (notice?.map((data, index) => {
                          const fullDateString = data?.created_at;
                          const datePortion = fullDateString?.slice(0, 10);
                          return (
                            <tr key={index} >
                              <td  className='dashboard-notice dashboard-notice-date'>   <p style={{ whiteSpace: 'nowrap' }} className="mb-0">{datePortion}</p></td>
                              <td  className='dashboard-notice '><p className='dashboard_notice_purpose mb-0'>{data?.name}</p></td>
                            </tr>
                          );
                        })) : 
                        <tr >
                        <td colSpan={2} className='text-center'>No Data</td>
                      </tr>
                      }
                      </tbody>
                    </table> */}

{notice?.length === 0 ? (
        <p className='px-4'>No Data Found</p>
      ) : (
        <Accordion defaultActiveKey="0" className='noticeAccr' flush>
          {notice.map((data, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>
                <span className='me-2'>{index + 1}.</span> {data.name}
              </Accordion.Header>
              <Accordion.Body>
                <p><i><strong>{data.date}</strong></i></p>
                {data.message}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container >
      )}
    </>
  );
}

export default Dashboard;
