import axios from 'axios';
import React from 'react'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom'
// import { Container, Row, Col, Button, NavLink, Modal } from "react-bootstrap";

const RegistrantCard = ({ data, index, fetchData, registrantBookmarkData }) => {
    let token;
    const userData = JSON.parse(window.localStorage.getItem("login"));

    if (userData) { token = userData.token; }

    const navigate = useNavigate();
    const handleNavigate = (data) => {
        if (data?.linkedin_link && data?._id) {
            navigate(`/firm/registrant-details/${data?._id}`)
        }
    }

    const [firstLetterOfFirstName, firstLetterOfLastName] = data?.name?.split(" ")?.map(registrant_name => registrant_name?.charAt(0));
    // useEffect(() => {
    //     let firstNameLatter = registarntData?.name?.charAt(0);
    //     let lasttNameLatter = registarntData?.name?.split(' ')[1]?.charAt(0)?.toUpperCase();
    //     if (lasttNameLatter === undefined) {
    //         lasttNameLatter = ''
    //         setShortName(firstNameLatter + lasttNameLatter)
    //     }
    //     else {
    //         setShortName(firstNameLatter + lasttNameLatter)
    //     }
    // }, [registarntData?.registrant_name]);
    // const [firstLetterOfFirstName, firstLetterOfLastName] = data?.registrant_name
    const handleButtonClick = (phone) => {
        navigator.clipboard.writeText(phone);
        toast.success(`Email copied to clipboard ${phone}`)
    };

    const handleButtonClickPhone = (phone) => {
        navigator.clipboard.writeText(phone);
        toast.success(`Phone number copied to clipboard ${phone}`)
    }
    const handleBookmarkClick = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_PCMA_BACKEND}/api/registrant-bookmark/${data._id}`,
                {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
            if (response?.data?.message === "Unauthozied User") {
                localStorage.removeItem("login");
                navigate("/");
                // toast.error("Unauthozied user !");
            }
            else if (response?.data?.message === "Bookmark removed successfully") {
                toast.success(response?.data?.message);
                await fetchData();

            }
            else if (response?.data?.message === "Bookmark added successfully") {
                toast.success(response?.data?.message);
                await fetchData();

            }

        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>

            <div className="card new_regist align-items-lg-center justify-content-start flex-row    position-relative" style={{ gap: "0" }}>
                {data?.featured ? <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 45 44" fill="none" className='featured_logo'>
                    <path d="M0 7.00001C0 3.13402 3.13401 0 7 0H45V36.7143C45 40.5803 41.866 43.7143 38 43.7143H0V7.00001Z" fill="#FAA815" />
                    <path d="M23.0518 10.111C23.0518 9.49533 22.5565 9 21.9408 9C21.3251 9 20.8298 9.49533 20.8298 10.111V11.5924H19.3484C18.7327 11.5924 18.2374 12.0877 18.2374 12.7034C18.2374 13.3191 18.7327 13.8144 19.3484 13.8144H20.8298V15.6661H14.3303C12.8073 15.6661 11.5713 16.9021 11.5713 18.4251C11.5713 18.8047 11.65 19.1797 11.7981 19.5269L14.3072 25.295H16.7283L13.8396 18.6381C13.8118 18.5687 13.7933 18.4992 13.7933 18.4251C13.7933 18.1289 14.034 17.8882 14.3303 17.8882H29.5513C29.8476 17.8882 30.0883 18.1289 30.0883 18.4251C30.0883 18.4992 30.0744 18.5733 30.042 18.6381L27.1487 25.295H29.5698L32.0789 19.5269C32.2316 19.1797 32.3057 18.8047 32.3057 18.4251C32.3057 16.9021 31.0697 15.6661 29.5467 15.6661H23.0518V13.8144H24.5332C25.1489 13.8144 25.6442 13.3191 25.6442 12.7034C25.6442 12.0877 25.1489 11.5924 24.5332 11.5924H23.0518V10.111ZM16.2561 28.9984H27.6255L28.394 30.4797H15.4923L16.2607 28.9984H16.2561ZM29.3893 27.5772C29.1347 27.0865 28.6254 26.7763 28.0746 26.7763H15.8071C15.2515 26.7763 14.747 27.0865 14.4923 27.5772L13.2656 29.9473C13.1267 30.2158 13.0526 30.5121 13.0526 30.813C13.0526 31.8592 13.8952 32.7017 14.9414 32.7017H28.9402C29.9818 32.7017 30.829 31.8592 30.829 30.813C30.829 30.5121 30.7549 30.2158 30.616 29.9473L29.3893 27.5772Z" fill="white" />
                </svg>
                    : ''}
                <div
                    className='all_registant_btn'
                    style={{
                        cursor: "pointer",
                        color: "#0f66b4",
                    }}
                    onClick={handleBookmarkClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-bookmark-fill bi_bookmark_fill"
                        viewBox="0 0 16 16"
                    >
                        {registrantBookmarkData?.includes(data._id) ?
                            <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2" />
                            :
                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                        }
                    </svg>
                </div>
                <div
                    className="me-3 dashboard_card_imag ">
                    <div className="Registrant_short_name_box" style={(data?.linkedin_link && data?._id) ? { cursor: 'pointer' } : { cursor: 'default' }} onClick={() => handleNavigate(data)}>
                        {data?.profile_image_url !== "" ?
                            <img style={{
                                borderRadius: "100px",
                                minHeight: "100%",
                                width: '100%',
                                cursor: "pointer",
                            }} src={data?.profile_image_url} alt='firm_image_url' /> : <p >{firstLetterOfFirstName}{firstLetterOfLastName} </p>}

                    </div>
                </div>

                <div className=" card_bottom_text ">
                    <div className=" w-100 ">
                        <h5
                            className="card-name  single-line"
                            style={(data?.linkedin_link && data?._id) ? { cursor: 'pointer', color: '#0F67B5' } : { cursor: 'default', color: '#0F67B5' }}
                            onClick={() => handleNavigate(data)}
                        >


                            {data?.name}
                        </h5>
                    </div>

                    <div className=" w-100 ">
                        <div className="righit-desc" style={{ alignItems: 'center' }}>

                            <div className="righit-desc-details">
                                <span className="type">City</span>
                                <span className="role" >{data?.city ? data?.city : "--"}</span></div>



                            <div>
                                <div className="">


                                    <div className="d-none d-lg-block">

                                        {data?.personal_email ?
                                            <Link className='copy_email d-block text-decoration-none' onClick={() => { handleButtonClick(data?.personal_email) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M10.666 2H2.66602V10.6667" stroke="#106BBD" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M5.33301 4.66675H13.333V12.6667C13.333 13.0204 13.1925 13.3595 12.9425 13.6096C12.6924 13.8596 12.3533 14.0001 11.9997 14.0001H6.66634C6.31272 14.0001 5.97358 13.8596 5.72353 13.6096C5.47348 13.3595 5.33301 13.0204 5.33301 12.6667V4.66675Z" stroke="#106BBD" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                Email
                                            </Link> : data?.work_email ?
                                                <Link className='copy_email d-block text-decoration-none' onClick={() => { handleButtonClick(data?.work_email) }}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M10.666 2H2.66602V10.6667" stroke="#106BBD" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M5.33301 4.66675H13.333V12.6667C13.333 13.0204 13.1925 13.3595 12.9425 13.6096C12.6924 13.8596 12.3533 14.0001 11.9997 14.0001H6.66634C6.31272 14.0001 5.97358 13.8596 5.72353 13.6096C5.47348 13.3595 5.33301 13.0204 5.33301 12.6667V4.66675Z" stroke="#106BBD" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>  Email </Link> : ""
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex j_re_btn align-items-center" style={{ gap: '8px' }}>
                        {data?.linkedin_link ?
                            <button
                                className="email-button m-0"
                                onClick={() =>
                                    window.open(data?.linkedin_link, "_blank")
                                }
                            >
                                <i className="fa-brands fa-linkedin"></i>
                                <span>     Connect </span>
                            </button>
                            : <button
                                className="email-button m-0"
                                disabled
                            >
                                <i className="fa-brands fa-linkedin"></i>
                                <span> Connect</span>
                            </button>
                        }

                        {data?.phone_number ?
                            <button
                                className="email-button m-0"
                                onClick={() => handleButtonClickPhone(data?.phone_number)}
                            >
                                <i className="fa-solid fa-phone"></i>
                                <span>   Phone</span>
                            </button>
                            :
                            <button
                                className="email-button m-0"
                                // onClick={() => handleNavigate(data)}
                                disabled
                            >
                                <i className="fa-solid fa-phone"></i>
                                <span> Phone</span>
                            </button>}


                        {data?.personal_email ?
                            <button className="email-button d-block d-lg-none" onClick={() => { handleButtonClick(data?.personal_email) }}><i className="fa-solid fa-envelope"></i></button>
                            : data?.work_email ?
                                <button className="email-button d-block d-lg-none" onClick={() => { handleButtonClick(data?.work_email) }}><i className="fa-solid fa-envelope"></i></button>
                            : "" }
                        

                    </div>
                </div>

            </div>

        </>
    )
}

export default RegistrantCard