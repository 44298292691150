import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, FormControl,Form } from "react-bootstrap";
import axios from 'axios';
import Select from "react-select";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { Link ,useNavigate,} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const UserNetwork = () => {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  let id = '';
  const userData = JSON.parse(window.localStorage.getItem("login"));

  if (userData) { id = userData._id; }
  const [selectedOption, setSelectedOption] = useState('');
  const [networkData, setNetworkData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [sp, setLoading] = useState(true);
  const [url, setUrl] = useState('');

  useEffect(() => {
    document.title = "PCMA || My Networks";
  }, []);


  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const options1 = [
    { value: 'Facebook', label: 'Facebook' },
    { value: 'x', label: 'Twitter' },
    { value: 'LinkedIn', label: 'LinkedIn' },
  ];

  const [data, setData] = useState({
    networkName:'',
    networkUrl:'',
});



  const handleUrlChange = (event) => {
    let value = event.target.value;
    setUrl(value.trim());
  };
  const handleNetwork = async () => {
    setSpinner(true);
    if (selectedOption?.length === 0 && url?.length === 0) {
      toast.error("Select one network atleast");
      setTimeout(() => {
                setSpinner(false);
              }, 2000);
      return false; 
    }
    else if (selectedOption?.length === 0) {
      toast.error("Select network")
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
      return false;
    }
    else if (url?.length === 0) {
      toast.error("Enter network URL");
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
      return false;
  
    }

    else {
      // const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+(?:\/[^\/]+)?\/?$/;
      const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/[a-zA-Z0-9_\/-]+)?\/?$/;
      const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-z0-9_\-]+(?:\/[^\/]+)?\/?$/;
      const linkedinRegex = /^(https?:\/\/)?(www\.)?([a-z]+\.)*linkedin\.com\/[a-zA-Z0-9_-]+(?:\/[^\/]+)?\/?$/;

      const isValidUrl =
      twitterRegex.test(url) ||
      facebookRegex.test(url) ||
      linkedinRegex.test(url);
      if (isValidUrl) {
        const data = url?.toLowerCase()?.includes(selectedOption?.value?.toLowerCase());
        if (data === true) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_PCMA_BACKEND}/api/network/${id}`,
              {
                network: selectedOption.label,
                url: url
              }
            );
            if (response?.data?.message === "Social network saved successfully") {
              toast.success(response?.data?.message);
              handleNetworkData();
              setUrl('');
              setTimeout(() => {
                setSpinner(false);
              }, 2000);
             
              setSelectedOption('')
            } else if (response?.data?.message === "Social profile already exists for this url") {
              toast.error('Social profile already exists for this URL');
              setSpinner(false);
            }
          } catch (err) {
            toast.error(err?.response?.data?.message);
            if (err?.message === "Request failed with status code 401") {
              localStorage.removeItem("login");
              navigate("/");
              setTimeout(() => {
                setSpinner(false);
              }, 2000);
            }
          }
        } else if (data === false) {
          toast.error("Network and URL does not match");
          setTimeout(() => {
            setSpinner(false);
          }, 2000);
        }
      }
      else {
        toast.error('Invalid URL');
        setTimeout(() => {
          setSpinner(false);
        }, 2000);
        return false;
        
      }
    }
  }

  const handleNetworkData = async () => {
    if(id){


    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/social-networks`, {
        params: { id: id, },
      }

      );
      setNetworkData(response?.data?.data?.social_media)

    } catch (err) {
      toast.error("Something went wrong please try again.");
    }    }
  }
  useEffect(() => {
    handleNetworkData()
  }, [])

  const handleClose = () => {
    setShow(false);
    setData('');
    setUrl('');
}


const [editSocialName, setEditSocialName] = useState('');
const [editSocialUrl, setEditSocialUrl] = useState('');

  const handleNetworkEdit = (data) => {
    setUrl(data.url)
    setEditSocialName(data.social_network)
    setEditSocialUrl(data.url)
    setShow(true);
  }
  const handleNetworkDelete = async (handleName) => {
    try {
      const confirmDeletion = await Swal.fire({
        title: "Delete Blog",
        text: "Do you want to delete this Blog? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#D0D5DD",
        confirmButtonColor: "#D92D20",
        confirmButtonText: "Delete",
        iconHtml: '<i class="fas fa-trash-alt"></i>',
        iconColor: "red",
      });
      if (!confirmDeletion.isConfirmed) return;
      const response = await axios.delete(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/social-network-delete/${id}`, {
        params: { "social_network": handleName, },
      }
      );
      toast.success(response?.data?.message);
      setNetworkData(response?.data?.social_media);
    
    } catch (err) {
      toast.error("Something went wrong please try again.");
    }

  }


  const handleChanges = (e) => {
    const { name, value } = e.target;
    setEditSocialUrl(value)
};

  const handleSubmit=async()=>{
    try{
      if(editSocialName?.length === 0 && editSocialUrl.length === 0){
        toast.error("Fields can be empty");
        return false;
      }else if(editSocialUrl.length === 0){
        toast.error("Network name fields can be empty");
        return false;
      }else{
      const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/[a-zA-Z0-9_\/-]+)?\/?$/;
      const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-z0-9_\-]+(?:\/[^\/]+)?\/?$/;
      const linkedinRegex = /^(https?:\/\/)?(www\.)?([a-z]+\.)*linkedin\.com\/[a-zA-Z0-9_-]+(?:\/[^\/]+)?\/?$/;
  
        const isValidUrl =
        twitterRegex.test(editSocialUrl) ||
        facebookRegex.test(editSocialUrl) ||
        linkedinRegex.test(editSocialUrl);

        if (isValidUrl) {
          const data = editSocialUrl?.toLowerCase()?.includes(editSocialName?.toLowerCase());
          if (data === true) {
        const response = await axios.put(`${process.env.REACT_APP_PCMA_BACKEND}/api/social-network-update/${id}`,{
          "old_url":url,
          "new_url":editSocialUrl
        });
        if(response){
          toast.success("URL updated successfully");
        }
      }else{
        toast.error("Network and URL does not match");
      }
        }else{
          toast.error('Invalid URL');
          return false;
        }

      }
      handleNetworkData();
      setUrl('');
    }catch(error){
      // console.log("erorr",error)
      setUrl('');
    }

    setData('');
    setShow(false);
  }

  return (
    <>
   
    <Container fluid className='pt-4'>
      <div className=" j_account_information" style={{ background: "#137CD9" }}>
        <span className="px-3 d-flex align-items-center" style={{ color: "white", fontSize: '20px' }}>
        My Networks
        </span>
      </div>

      <Row className="g-2" style={{ paddingBottom: "10px" }}>
        <Col sm={12} md={12} lg={12} className='py-3 d-flex justify-content-start align-items-start flex-column'>
          <h4 className='network_h4'>Most Popular Networks</h4>

          <div className='d-flex align-items-center  py-2 '>
            <div className='pe-2 text-center text-sm-start'>
            <Link className='d-flex align-items-center text-decoration-none' style={{color:'#000'}} to="https://www.facebook.com/" target='_blank'> 
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path d="M24.5654 0H1.43456C0.642274 0 0 0.642274 0 1.43456V24.5654C0 25.3577 0.642274 26 1.43456 26H24.5654C25.3577 26 26 25.3577 26 24.5654V1.43456C26 0.642274 25.3577 0 24.5654 0Z" fill="#316FF6" />
                <path d="M18.049 25.9996V15.834H21.6077L22.1399 11.8725H18.049V9.34382C18.049 8.19706 18.3822 7.41406 20.0967 7.41406H22.2857V3.86509C21.2257 3.7592 20.1605 3.70885 19.0948 3.71426C15.9434 3.71426 13.773 5.55529 13.773 8.95121V11.8725H10.2142V15.834H13.773V25.9996H18.049Z" fill="white" />
              </svg>
              <span className='px-2'>
                Facebook
              </span>
            </Link> 

            </div>
            <div className='px-2 text-center text-sm-start'>
            <Link className='d-flex align-items-center text-decoration-none' style={{color:'#000'}} to="https://x.com/i/flow/login" target='_blank'> 
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <g clipPath="url(#clip0_3529_1587)">
                  <mask id="mask0_3529_1587" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
                    <path d="M26 0H0V26H26V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_3529_1587)">
                    <path d="M22.9531 0H3.04688C1.36413 0 0 1.36413 0 3.04688V22.9531C0 24.6359 1.36413 26 3.04688 26H22.9531C24.6359 26 26 24.6359 26 22.9531V3.04688C26 1.36413 24.6359 0 22.9531 0Z" fill="black" />
                    <path d="M18.0733 5.07812H20.761L14.8891 11.7894L21.797 20.9219H16.3881L12.1518 15.3831L7.3044 20.9219H4.61505L10.8956 13.7434L4.26892 5.07812H9.81502L13.6443 10.1408L18.0733 5.07812ZM17.13 19.3131H18.6193L9.00577 6.60238H7.40758L17.13 19.3131Z" fill="white" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_3529_1587">
                    <rect width="26" height="26" rx="3" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className='px-2'>
                Twitter
              </span>
              </Link>

            </div>
            <div className='px-2 text-center text-sm-start'>
            <Link className='d-flex align-items-center text-decoration-none' style={{color:'#000'}} to="https://www.linkedin.com/" target='_blank'> 
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                <path d="M24.0775 0.000182877H1.92254C1.67349 -0.00327588 1.42621 0.0423577 1.1948 0.134478C0.9634 0.226597 0.752409 0.363399 0.57388 0.53707C0.395351 0.710741 0.252783 0.917879 0.154315 1.14665C0.0558482 1.37543 0.00341138 1.62136 0 1.8704V24.2869C0.00341138 24.5359 0.0558482 24.7819 0.154315 25.0107C0.252783 25.2394 0.395351 25.4466 0.57388 25.6202C0.752409 25.7939 0.9634 25.9307 1.1948 26.0228C1.42621 26.1149 1.67349 26.1606 1.92254 26.1571H24.0775C24.3265 26.1606 24.5738 26.1149 24.8052 26.0228C25.0366 25.9307 25.2476 25.7939 25.4261 25.6202C25.6046 25.4466 25.7472 25.2394 25.8457 25.0107C25.9442 24.7819 25.9966 24.5359 26 24.2869V1.8704C25.9966 1.62136 25.9442 1.37543 25.8457 1.14665C25.7472 0.917879 25.6046 0.710741 25.4261 0.53707C25.2476 0.363399 25.0366 0.226597 24.8052 0.134478C24.5738 0.0423577 24.3265 -0.00327588 24.0775 0.000182877ZM7.88632 21.8935H3.96278V10.1229H7.88632V21.8935ZM5.92455 8.47503C5.38344 8.47503 4.8645 8.26008 4.48188 7.87746C4.09926 7.49484 3.88431 6.9759 3.88431 6.43479C3.88431 5.89368 4.09926 5.37474 4.48188 4.99212C4.8645 4.6095 5.38344 4.39455 5.92455 4.39455C6.21188 4.36196 6.50285 4.39043 6.77841 4.4781C7.05397 4.56576 7.30791 4.71064 7.5236 4.90325C7.73928 5.09586 7.91185 5.33185 8.03001 5.59578C8.14817 5.85971 8.20925 6.14562 8.20925 6.43479C8.20925 6.72396 8.14817 7.00987 8.03001 7.2738C7.91185 7.53772 7.73928 7.77372 7.5236 7.96633C7.30791 8.15894 7.05397 8.30382 6.77841 8.39148C6.50285 8.47915 6.21188 8.50762 5.92455 8.47503ZM22.0372 21.8935H18.1137V15.5766C18.1137 13.9941 17.5513 12.9609 16.1258 12.9609C15.6846 12.9642 15.255 13.1026 14.8949 13.3574C14.5347 13.6123 14.2614 13.9715 14.1117 14.3865C14.0093 14.6939 13.965 15.0176 13.9809 15.3412V21.8805H10.0573V10.1098H13.9809V11.7708C14.3373 11.1523 14.8557 10.6428 15.4803 10.2971C16.1048 9.95144 16.8118 9.78268 17.5252 9.80904C20.1408 9.80904 22.0372 11.4962 22.0372 15.1189V21.8935Z" fill="#0077B5" />
              </svg>
              <span className='px-2'>
                LinkedIn
              </span>
            </Link>
            </div>
          </div>

        </Col>

        <Col sm={12} md={12} lg={3}>
          <h4 className='network_h4'>Network</h4>
          <Select
            value={selectedOption}
            options={options1}
            onChange={(selectedOption) => handleChange(selectedOption)}
            placeholder="Network"
          />
        </Col>
        <Col sm={12} md={12} lg={3}>
        <h4 className='network_h4'>URL</h4>
          <FormControl
            type="text"
            placeholder="Enter URL"
            value={url}
            onChange={handleUrlChange}
          />

        </Col>
        <Col sm={12} md={12} lg={3} className='d-flex align-items-end' >
          <div >
             
              {spinner ? (<Button className='px-4'  style={{ border: '0px', borderRadius: "4px", background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)" }} disabled={spinner} >Loading <i className="fa-solid fa-spinner fa-spin ms-3"></i></Button>) : (
                <Button className='px-4' onClick={() => handleNetwork()} style={{ border: '0px', borderRadius: "4px", background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)" }} >Add To Profile</Button>)}
          </div>

        </Col>

      </Row>
      <Row>
        <Col sm={12} md={12} lg={12} className='mt-4'>
          <div className="table-responsive" style={{borderRadius:'6px'}}>
            <table className="userinvoice_table" style={{ width: '100%' }}>
              <thead style={{ background: 'blue' }} >
                <tr className="table-heading-row" >

                  <th className='py-2'>Network Name</th>
                  <th className='text-center'>URL</th>
                  <th width="120px" className='text-center'>Actions</th>

                </tr>
              </thead>
              <tbody>

                {
                  networkData?.map((data) =>

                    <tr>
                      <td>{data?.social_network}</td>
                      <td className='text-center'>
                        <Link target='_blank' className='j_setting_link text-decoration-none' to={data?.url}>{data?.url}</Link>
                        
                        </td>
                      <td className='d-flex justify-content-center'>
                        <div style={{ cursor: "pointer"}} onClick={() => handleNetworkEdit(data)}>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M2 16H3.425L13.2 6.225L11.775 4.8L2 14.575V16ZM0 18V13.75L13.2 0.575C13.4 0.391667 13.6208 0.25 13.8625 0.15C14.1042 0.05 14.3583 0 14.625 0C14.8917 0 15.15 0.05 15.4 0.15C15.65 0.25 15.8667 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.7708 2.4 17.8625 2.65C17.9542 2.9 18 3.15 18 3.4C18 3.66667 17.9542 3.92083 17.8625 4.1625C17.7708 4.40417 17.625 4.625 17.425 4.825L4.25 18H0ZM12.475 5.525L11.775 4.8L13.2 6.225L12.475 5.525Z" fill="#11AB0E" />
                          </svg> */}
                          <i className="fa-solid fa-edit  p-2" style={{ cursor: 'pointer', color: '#007FFF' }} ></i>
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => handleNetworkDelete(data?.social_network)}>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_3540_1657" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3540_1657)">
                              <path d="M8.4 17L12 13.4L15.6 17L17 15.6L13.4 12L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4L10.6 12L7 15.6L8.4 17ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#E10E0E" />
                            </g>
                          </svg> */}
                          <i className="fa-solid fa-trash p-2" style={{ cursor: 'pointer', color: '#FF6969' }} ></i>
                        </div>

                      </td>
                    </tr>
                  )
                }



              </tbody>
            </table>
          </div>
        </Col>

        {/* <Col sm={8} md={6} lg={9} className='py-3'>
<Button style={{borderRadius:"4px",border:"0px",background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)"}}>PAY Selected Invoice</Button>
</Col> */}

      </Row>
    </Container>


    <Modal centered   backdrop="true" keyboard={false} size="lg" show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="form p-3" >
                        <div className="row">

                             <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className="fw-bolder">Network Name</Form.Label>
                                    <Form.Control type="text"
                                     value={editSocialName || ''}
                                     disabled
                                      onChange={handleChanges} name="networkName" placeholder="Network Name"/>
                                </Form.Group>
                            </div>
                             <div className="col-12 col-sm-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className="fw-bolder">Network Url</Form.Label>
                                    <Form.Control type="text"
                                     value={editSocialUrl || ''}
                                      onChange={handleChanges} name="networkUrl" placeholder="Network Url"/>
                                </Form.Group>
                            </div>
                        </div>
                    </div> 
                    <div className="d-flex align-items-center">
                        <button className="subcription_add_new"
                         onClick={handleSubmit}
                         >Add</button>
                        <button className="subcription_cancel ms-2" onClick={handleClose}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>      

 </>

  )
}

export default UserNetwork